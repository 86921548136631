import {
  EVENT_FIELD_ERROR,
  EVENT_FIELD_INIT,
  EVENT_FIELD_SUCCESS,
} from '../actions/EventFieldActions';

const initialize = {
  init: false,
  success: false,
  error: false,
  eventField: {},
};

const EventFieldReducers = (state = initialize, action) => {
  switch (action.type) {
    case EVENT_FIELD_INIT:
      return {
        ...state,
        ...action.payload,
      };
    case EVENT_FIELD_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case EVENT_FIELD_ERROR:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export default EventFieldReducers;
