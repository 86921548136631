import { authAxios } from '../../config/requestAxios';

export const GET_HISTORY = 'GET_HISTORY';
export const GET_HISTORY_SUCCESS = 'GET_HISTORY_SUCCESS';
export const GET_HISTORY_FAILURE = 'GET_HISTORY_FAILURE';

const historyFilemanagerInit = () => {
  return {
    type: GET_HISTORY,
    payload: {
      init: true,
    },
  };
};

const historyFilemanagerSuccess = (data) => {
  return {
    type: GET_HISTORY_SUCCESS,
    payload: {
      init: false,
      success: true,
      error: false,
      history: data,
    },
  };
};

const historyFilemanagerError = (error) => {
  return {
    type: GET_HISTORY_FAILURE,
    payload: {
      init: false,
      success: false,
      error: error,
    },
  };
};

export const historyFilemanagerActions = (url) => {
  return function (dispatch) {
    dispatch(historyFilemanagerInit());
    authAxios
      .get(url)
      .then((response) => {
        dispatch(historyFilemanagerSuccess(response.data));
      })
      .catch((err) => {
        dispatch(historyFilemanagerError(err));
      });
  };
};
