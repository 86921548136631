import {
  MODEL_SUCCESS,
  ROWINFO_SUCCESS,
  ONCHANGE_MODEL_SUCCESS,
  ONCHANGE_DELETE_MODEL_SUCCESS,
  ONSET_MODEL_VALIDATION_SUCCESS,
  ADD_MODALID,
  DEL_MODALID,
  ONCHANGE_MODEL_SUCCESS_BY_QUERY_STRING,
  FIELD_VALIDATION,
  FIELD_VALIDATION_VALUE
} from '../actions/ModelActions';
import _ from 'lodash';
import { parserModel, parserDeleteModel } from './utils';

const initializeState = {
  status: false,
  model: {},
  required: {},
  getRowInfo: {},
  MODAL: {},
  validation: false,
  validateField: {},
};

const ModelReducers = (state = initializeState, action) => {
  const STATE = state;
  switch (action.type) {
    case MODEL_SUCCESS:
      const oldModel = !_.isEmpty(STATE.model[action.payload.formid]) ? STATE.model[action.payload.formid] : {};
      const result = {
        [action.payload.formid]: {
          ...parserModel(STATE, action.payload),
          ...{ _model: oldModel },
        },
      };
      return {
        ...state,
        model: { ...state.model, ...result },
      };
    case ONCHANGE_MODEL_SUCCESS:
      const resultO = {
        [action.payload.formid]: parserModel(STATE, action.payload),
      };
      return {
        ...state,
        model: { ...state.model, ...resultO },
      };
    case ONCHANGE_MODEL_SUCCESS_BY_QUERY_STRING:
      const tempResult = {
        [action.payload.formid]: {
          ...state.model[[action.payload.formid]],
          ...action.payload.data,
        },
      };
      return {
        ...state,
        model: { ...state.model, ...tempResult },
      };

    case ONSET_MODEL_VALIDATION_SUCCESS:
      const { formid, name, isVisible } = action.payload;
      const prevRequired = _.get(state.required, [formid], []);
      const updateArr = isVisible ? _.uniq([...prevRequired, name]) : prevRequired.filter((e) => e !== name);
      return {
        ...state,
        required: { ...state.required, [formid]: updateArr },
      };

    case ONCHANGE_DELETE_MODEL_SUCCESS:
      return {
        ...state,
        model: { ...state.model, ...parserDeleteModel(state, action.payload) },
      };
    case ROWINFO_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };

    case ADD_MODALID:
      return { ...state, MODAL: { ...state.MODAL, ...action.payload } };
    case DEL_MODALID:
      const [key] = _.keys(action.payload) || {};
      const filtered = _.keys(state.MODAL).filter((e) => e !== key);
      const resultt = _.pick(state.MODAL, filtered);
      return { ...state, MODAL: resultt };

    case FIELD_VALIDATION:
      return {
        ...state,
        validation: action.payload.data,
      };

    case FIELD_VALIDATION_VALUE:
      const formId = action.payload.formid;
      const nameField = action.payload.name;
      const isValidate = action.payload.isValidate;
      const prevValidation = _.get(state.validateField, [formId], []);
      const updateValues = isValidate ? _.uniq([...prevValidation, nameField]) : prevValidation.filter((e) => e !== nameField);
      return {
        ...state,
        validateField: { ...state.validateField, [formId]: updateValues },
      };

    default:
      return state;
  }
};

export default ModelReducers;
