import React, { Component } from 'react';
import { connect } from 'react-redux';
import { hiddenRoles, SetVisibilityContainer } from '../../utils';
import { dispatchEvents, setModelValidation } from '../events';
import _ from 'lodash';

class Container extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: true,
    };
  }

  componentDidMount() {
    if (
      _.get(this.props, 'data-events') &&
      _.get(this.props, 'data-valores_formulario')
    ) {
      dispatchEvents(this);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const currentModel = prevProps.model;
    const nextModel = this.props.model;

    const modelChanged = !_.isEqual(currentModel, nextModel);
    if (
      modelChanged &&
      _.get(this.props, 'data-events') &&
      _.get(this.props, 'data-valores_formulario')
    ) {
      dispatchEvents(this);
    }
  }

  render() {
    if (this.state.show) {
      const hidden = hiddenRoles(this);
      if (hidden) return null;
      else {
        return <div className={this.props.class}>{this.props.children}</div>;
      }
    } else {
      setModelValidation(this, false);
      SetVisibilityContainer(this.props.children, this.props)
      return null;
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    // roles: _.get(state.user, "logged.roles",[]),
    model: _.get(state.model, ['model', ownProps.formid], {}),
  };
};
export default connect(mapStateToProps)(Container);
