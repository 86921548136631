import * as cache from 'memory-cache';
import * as hash from 'object-hash';
import { requestAxios } from '../config/requestAxios';
import axiosRetry from 'axios-retry';
import { encrypt } from '../components/utils';
import { getLOVS, txResponse } from '../store/actions/TableActions';
import _ from 'lodash';
import { isProductionEnv } from './helper';
const DEBUG = isProductionEnv();

const appCache = new cache.Cache();
const cacheTimeOutSecs =
  (parseInt(_.get(process.env, 'REACT_APP_CACHE_EXP_SECS')) || 60) * 1000;

/**
 *
 * @param {String} url
 * @param {Object} params
 * @returns {Object}
 */
export const fetchDataCache = async (url, params) => {
  const user = localStorage.getItem('cod_user');
  const { ...etc } = params;
  const key = hash({ url, user, etc });
  // const result = cache && appCache.get(key);

  // if (result) {
  //   return result;
  // }
  // Encriptado de los parametros del Divwin cuando el frontend
  // està en modo producciòn.
  if (DEBUG) params = { data: encrypt(JSON.stringify(params)) };
  axiosRetry(requestAxios, { retries: 20 });

  let response = await requestAxios.post(url, params).catch((error) => {
    console.error('Error al Cargar los Datos', error);
  });

  if (url === '/tables') {
    // Validacion para cuando el backend este caido no se rompa la pantalla.
    response = _.get(response, 'data') ? response : {};
    const LOVS = await getLOVS(_.get(response, 'data.columns'));
    const result = { ...txResponse(response), LOVS };
    const data = _.get(result, 'data');
    if (!_.isEmpty(data)) {
      appCache.put(key, result, cacheTimeOutSecs);
    }
    return result;
  } else {
    if (_.get(response, 'data.process_response.app_data')) {
      const _url = url + '/cache';
      const divwinKey = hash({ _url, user, params });
      appCache.put(divwinKey, response, 1);
      appCache.put(key, response, cacheTimeOutSecs);
    }

    return response;
  }
};

/**
 * Cacheando los llamados que se hacen a la base de datos
 * como forma de controlar que no se ejecuten multiples llamados
 * @param {String} alias
 * @param {Object} jsonParams
 * @returns {Boolean}
 */
export const requestExternalDivwin = (alias, jsonParams) => {
  const url = '/lists/' + alias + '/cache';
  const user = localStorage.getItem('cod_user');
  const key = hash({ url, user, jsonParams });
  const resultHash = appCache.get(key);
  if (resultHash !== null) return false;
  else {
    appCache.put(key, jsonParams, cacheTimeOutSecs);
    return true;
  }
};

/**
 * Get Page from appCache using window.location.host
 * Cacheando los llamados que se hacen a la base de datos
 * como forma de controlar que no se ejecuten multiples llamados
 * @returns {Array}
 */
export const getPage = () => {
  const domain = window.location.host;
  const key = domain + '-page';
  const resultHash = appCache.get(key);
  if (resultHash !== null) return resultHash;
  else return [];
};

/**
 * Set Page on appCache
 * @param {Object} page
 */
export const setPage = (page) => {
  const domain = window.location.host;
  const key = domain + '-page';
  appCache.put(key, page);
};
