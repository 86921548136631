import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { onChangeModelByQueryParamsAction } from '../../store/actions/ModelActions';
import { getQueryParams } from '../../utils/utils';

const QueryParams = function (props) {
  useEffect(() => {
    const queryParams = getQueryParams();
    props.dispatch(onChangeModelByQueryParamsAction(props.formid, queryParams));
  }, []);

  return <div></div>;
};

const mapStateToProps = (state, ownProps) => {
  return { model: _.get(state.model, ['model', ownProps.formid], {}) };
};

export default connect(mapStateToProps)(QueryParams);
