import React, { Component } from 'react';
import RichTextEditor from 'react-rte';
import { connect } from 'react-redux';
import postal from 'postal';
import _ from 'lodash';
import { readOnly, initXfAndValue, attrChangeModel } from '../utils';
import { dispatchEvents, hasEventChanged, setModelValidation } from '../events';
import { onChangeModelActions } from '../../../store/actions/ModelActions';

class RichText extends Component {
  constructor(props) {
    super(props);
    this.channel = postal.channel();
  }

  state = {
    value: RichTextEditor.createEmptyValue(),
  };

  componentDidMount() {
    if (this.props.name === '') {
      this.showNotification(
        'El componenente \n' +
          JSON.stringify(this.props) +
          '\n no tiene el atributo name',
      );
    }
    if (this.props.formid) {
      setModelValidation(this);
      this.clearComponent();
      /**
       * Inicialización de datos del componente.
       */
      initXfAndValue(this);

      /**
       * Ejecución de los eventos adicionales del componente String
       */
      if (_.get(this.props, 'data-events')) dispatchEvents(this);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const currentModel = prevProps.model;
    const nextModel = this.props.model;

    // const modelChanged = !_.isEqual(currentModel, nextModel);
    // if (modelChanged && _.get(this.props, "data-events")) {
    //   dispatchEvents(this);
    // }
    if (hasEventChanged(this.props, currentModel, nextModel)) dispatchEvents(this);
    const { value, onChange } = attrChangeModel(
      this.props.name,
      currentModel,
      nextModel,
      this.props['data-valores_formulario'],
    );
    if (onChange) {
      this.setState({ value: value });
    }

    setModelValidation(this, !this.state.readOnly && this.state.show);
  }

  showNotification = (message) => {
    this.channel.publish('notification', [
      { tipo: 'error', titulo: 'Error', data: message },
    ]);
  };

  clearComponent = () => {
    const _name = _.get(this.props, 'old_name')
      ? this.props.old_name.split('__')[0]
      : this.props.name;
    this.channel.subscribe(`${this.props.formid}:${_name}:clearComponent`, () => {
      /**
       * Limpiando el componente y el campo relacionado en el modelo global
       * de la pantalla
       */
      this.setState({ value: '' });
      this.props.dispatch(
        onChangeModelActions(this.props.formid, {
          name: this.props.name,
          value: '',
        }),
      );
    });
  };

  onChange = (value) => {
    this.setState({ value });
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      // if(objectPath.get(this.props,"data-events")) dispatchEvents(this);
      this.setState({ value: value });
      /**
       * Actualización del modelo global desde aqui
       */
      this.props.dispatch(
        onChangeModelActions(this.props.formid, {
          name: this.props.name,
          value: value,
        }),
      );
    }, 500);
  };

  render() {
    return (
      <div className={this.props.class}>
        <RichTextEditor
          value={this.state.value}
          readOnly={readOnly(this.props)}
          name={this.props.name}
          onChange={this.onChange}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    model: _.get(state.model, ['model', ownProps.formid], {}),
    roles: _.get(state.logged, 'logged.roles') ? state.logged.logged.roles : [],
  };
};

export default connect(mapStateToProps)(RichText);
