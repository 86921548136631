import {
  CREATE_FOLDER_ERROR,
  CREATE_FOLDER_INIT,
  CREATE_FOLDER_SUCCESS,
} from '../actions/FilemanagerCreateFolder';

const initializeState = {
  init: false,
  success: false,
  error: false,
  folder: {},
};

const createFolderReducers = (state = initializeState, action) => {
  switch (action.type) {
    case CREATE_FOLDER_INIT:
      return {
        ...state,
        ...action.payload,
      };
    case CREATE_FOLDER_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case CREATE_FOLDER_ERROR:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export default createFolderReducers;
