import { dispatchEvents } from '../Common/events';
import _ from 'lodash';
import { equalRegEx } from '../GridNew/helper';

export const mergeDataTips = (element) => {
  return element ? (Array.isArray(element) ? element.join(' ') : element) : element;
};

export const buildTabsPane = (that) => {
  that.props.children.forEach((item1) => {
    const item = item1 || {};
    const _menuTab = {
      tab: item.props.text,
      key: item.props.key,
      hidden: false,
      submit: equalRegEx('true').test(item.props.submit),
      show: true,
    };
    if (_.get(item, 'props.data-events')) {
      _menuTab['data-events'] = item.props['data-events'];
      _menuTab['data-valores_formulario'] = item.props['data-valores_formulario'];
      _menuTab.formid = that.props.props.formid;
      _menuTab.tabsimple = true;
      dispatchEvents(that, _menuTab);
    }
  });
};
