import { hasId, IdRows } from '../../components/GridNew/helper';
import _ from 'lodash';
import { fetchDataCache } from '../../utils/cache';
import { sayDot } from '../../utils/utils';

const BOOL2CHECK = { true: 'TRUE', false: 'FALSE' };
export const TableTypes = {
  TABLE_INIT: 'TABLE_INIT',
  ADD_TABLEID: 'ADD_TABLEID',
  DEL_TABLEID: 'DEL_TABLEID',
  TABLE_ERROR: 'TABLE_ERROR',
  LOVS_SUCCESS: 'LOVS_SUCCESS',
  UPDATE_ERROR: 'UPDATE_ERROR',
};

const tableAction = (
  type,
  { data = null, init = false, success = false, error = false },
) => {
  return {
    type,
    payload: { ...data, init, success, error },
  };
};

export const addTableId = (formid, rowid) => {
  // Se le envia al reducers el formId (se almacena) asociado al registro del listado que se està editando.
  // Influye para luego refrezcar el listado de registros.
  return function (dispatch) {
    dispatch(
      tableAction('ADD_TABLEID', { data: { alias: formid, rowid }, success: true }),
    );
  };
};

export const removeTableId = (formid, rowid) => {
  // Se le envia al reducers el formId (se elimina del store) asociado al registro del listado que se està editando.
  // Influye para luego refrezcar el listado de registros.
  return function (dispatch) {
    dispatch(
      tableAction('DEL_TABLEID', { data: { alias: formid, rowid }, success: true }),
    );
  };
};

export const getLOVS = async (columns = [], filters = {}) => {
  const lovs = columns.filter((col) => ['LOV', 'DIV_LST'].includes(col.dataType));
  const aliases = lovs.map(({ alias_lov }) => alias_lov);
  if (aliases) {
    const promises = lovs.map(async ({ name, alias_lov }) => {
      const response = await fetchDataCache('/lists/' + alias_lov, {
        cache: true,
      });
      const result = _.get(response.data, 'process_response.app_data', []);
      const options = result.map(({ COD, DES, data, label, __id, ...etc }) => ({
        value: COD,
        label: DES,
        filters: etc,
      }));
      return { name, options };
    });
    return await Promise.all(promises);
  } else {
    return [];
  }
};

const checkToBool = (rowChange, boolColNames) => {
  const result = Object.keys(rowChange).reduce(
    (prev, key) =>
      boolColNames.includes(key)
        ? { ...prev, [key]: rowChange[key] !== null ? rowChange[key] === BOOL2CHECK.true : null }
        : { ...prev, [key]: rowChange[key] },
    {},
  );
  return result;
};

const getDefaults = (columns = []) =>
  columns.reduce(
    (acc, { name, valor_default = 'NULL' }) =>
      ['NULL', 'UNDEFINED'].includes(valor_default.toUpperCase())
        ? name !== 'COLOR'
          ? acc
          : { ...acc, COLOR: 'white' }
        : { ...acc, [name]: valor_default },
    {},
  );

const filterVisualColumns = (columns) =>
  columns.filter(({ type }) => type === 'FIELD' || type === 'LINK');

const renderData = (rows, columsDef = []) => {
  if (columsDef) {
    const dottedCols = columsDef.filter((column) => _.get(column, 'dotted'));
    const sayDotColNames = dottedCols.map((c) => c.dotted);
    const unSayDotColNames = dottedCols.map((c) => c.name);
    const nameMap = _.zipObject(unSayDotColNames, sayDotColNames);

    const txRows = !dottedCols
      ? rows
      : rows.map((row) => {
        const keys = _.keys(row);
        const vals = _.values(row);
        const renamedKeys = keys.map((k) =>
          !_.get(nameMap, [k]) ? k : nameMap[k],
        );
        return _.zipObject(renamedKeys, vals);
      });

    const boolCols = columsDef
      .filter(({ dataType }) => dataType === 'CHECK')
      .map(({ name }) => name);
    return txRows.map((row) => checkToBool(row, boolCols));
  }
  return rows;
};

const parseSummary = (jsonStr) => {
  try {
    const parsed = JSON.parse(jsonStr);
    return _.isArray(parsed) && !_.isEmpty(parsed)
      ? parsed
      : {
          error: true,
          msg: {
            tipo: 'warning',
            titulo: '',
            data: 'No se puede realizar la sumarizacion: ' + jsonStr,
          },
        };
  } catch (e) {
    return {
      error: true,
      msg: {
        tipo: 'warning',
        titulo: '',
        data: 'No se puede realizar la sumarizacion: ' + jsonStr,
      },
    };
  }
};

const addSummaries = (columns, summaries) => {
  if (_.isArray(summaries)) {
    const summaryObj = summaries.reduce(
      (acc, { name, ...etc }) => (name ? { ...acc, [name]: etc } : acc),
      {},
    );

    const summaryKeys = _.keys(summaryObj);
    const summarized = columns.map((col) =>
      summaryKeys.includes(col.name) ? { ...col, ...summaryObj[col.name] } : col,
    );
    return summarized;
  } else return columns;
};

export const txResponse = ({ data }) => {
  const { rows = [], columns = [], nro_campos_sumarizados, ...etc } = data || {};
  const filteredColumns = !_.isEmpty(columns) ? filterVisualColumns(columns) : [];

  const summaries = nro_campos_sumarizados
    ? parseSummary(nro_campos_sumarizados)
    : [];
  const error = _.get(summaries, 'error') ? summaries : false;
  const sumarizedCols = addSummaries(filteredColumns, summaries);

  const txColumns = sumarizedCols.map((column) => {
    const dotted = sayDot(column.name);
    return dotted === column.name ? column : { ...column, dotted };
  });

  const rdata = txColumns ? renderData(rows, txColumns) : [];
  const idData = hasId(columns) ? rdata : IdRows(rdata);
  const defaults = getDefaults(txColumns);
  return {
    ...etc,
    data: idData,
    columns: txColumns,
    summaries,
    defaults,
    error,
  };
};

export const fetchTable = async ({
  skip = 0,
  take,
  IBLE_ALIAS,
  filters = [],
  ...etc
}) => {
  const paramsMap = {
    IBLE_ALIAS,
    skip,
    take,
    filters,
    ...etc,
  };
  return await fetchDataCache('/tables', paramsMap);
};
