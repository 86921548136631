import React, { Component } from 'react';
import { connect } from 'react-redux';
import postal from 'postal';
import WebButton from '../Common/Button/WebButton';
import { onChangeModelActions } from '../../store/actions/ModelActions';
import MountComponent from '../MountComponent/MountComponent';
import { routerAxios } from '../../config/requestAxios';
import { attrChangeModel } from './utils';
import { encrypt } from '../utils';
import './ExtraFields.css';
import { isProductionEnv } from '../../utils/helper';
import _ from 'lodash';
import { getPage } from '../../utils/cache';

const DEBUG = isProductionEnv();

class ExtraFields extends Component {
  constructor(props) {
    super(props);
    this.state = {
      elements: '',
      fields: [],
      props: this.props || '',
      model: this.props.model || {},
      xf_type: null,
      changePage: false,
      icon: '',
      status: false,
      init: true,
    };
    this.channel = postal.channel();
    this.xf_current = '';
  }

  buildEvent = (value) => {
    let table;
    if (!_.isEmpty(this.props.model.TABLA)) {
      table = this.props.model.TABLA;
    }
    let changeElement = false;
    const page = getPage();
    page.map((item) => {
      const domain = window.location.origin;
      const subdomain = domain.split(':')[1].split('.')[0].replace('//', '');
      const path = item.path ? item.path.split('#')[0] : item.path;
      if (path === '/XF/' + subdomain + '/' + table + '/' + value) {
        changeElement = true;
        if (item.pantalla) {
          const _children = !_.isEmpty(item, 'pantalla.children')
            ? item.pantalla.children
            : [];
          this.fnNormalizeModel(value, _children);
          // if(this.xf_current!=='') this.channel.publish(`${this.props.formid}:delete:property:model`,this.xf_current);
        } else this.fnNormalizeModel(value, []);
        this.xf_current = value;
        this.setState({
          elements: item.pantalla,
          fields: item.pantalla ? item.pantalla.children : [],
          props: item.pantalla ? item.pantalla.props : [],
          changePage: !item,
          xf_type: value,
          status: true,
          init: false,
        });
        return null;
      }
      return null;
    });
    if (changeElement) {
      const createCopyType = {
        name: 'XF_TYPE_',
        value: value
      }
      this.props.dispatch(onChangeModelActions(this.props.formid, createCopyType));
    }
    if (!changeElement) {
      this.setState({ changePage: true, xf_type: value, init: false });
    }
  };

  fnEventField = (name) => {
    const keysModel = _.keys(this.props.model);
    if (_.filter(keysModel, (element) => element === name).length > 0) {
      if (this.state.init) {
        const _value = this.props.model[name];
        this.buildEvent(_value);
      }
    }
  };

  componentDidMount() {
    if (this.props['data-eventofield'] && this.state.model.xf_type) {
      this.fnEventField(this.state.model.xf_type);
    } else if (this.state.model.xf_type) {
      this.fnEventField(this.state.model.xf_type);
    } else this.fnEventField(this.props['data-eventofield']);
  }

  componentDidUpdate(prevProps, prevState) {
    const eventofield = !_.isEmpty(this.props['data-eventofield'])
      ? this.props['data-eventofield']
      : '';
    const currentModel = prevProps.model;
    const nextModel = this.props.model;
    let { attrChangeForm, value } = attrChangeModel(
      eventofield,
      currentModel,
      nextModel,
    );
    if (attrChangeForm) {
      value = typeof value === 'object' ? value.value : value;
      this.buildEvent(value);
    }
  }

  fnNormalizeModel = (name, fields) => {
    // const table = this.props.model.TABLA;
    let value = '';
    const _existValueObjetXf = _.get(this.props.model, name);
    fields.map((field) => {
      if (field.props.name) {
        let response = {};
        if (_existValueObjetXf !== undefined) {
          value = _existValueObjetXf[field.props.name];
        }
        response = {
          name: name,
          value: { name: field.props.name, value: value },
        };
        this.props.dispatch(onChangeModelActions(this.props.formid, response));
        // this.channel.publish(`${this.props.formid}:set:model`,response);
      }
      if (field.children) this.fnNormalizeModel(name, field.children);
      return null;
    });
    // }
  };

  fnExistsRoles = () => {
    const roles = this.props.logged.logged ? this.props.logged.logged.roles : [];
    let existeRol = false;
    roles.filter((rol) => {
      if (rol === 'XF_ADMIN') existeRol = true;
      else return null;
      return null;
    });
    return existeRol;
  };

  fnBuildFormPage = (evt) => {
    const table = _.get(this.props, 'model.TABLA') ? this.props.model.TABLA : null;
    evt.preventDefault();
    const domain = window.location.origin;
    const subdomain = domain.split(':')[1].split('.')[0].replace('//', '');
    let createRouteBody = {
      acceso_anonimo: false,
      app_uid: '881a8e2f-245a-473e-8cce-2cbd1b397ba5',
      canonical: '',
      contenido: '<div name="" class="col-12 col-xl-12 templateTest"></div>',
      controller: false,
      descripcion: '',
      estado: 'ONLINE',
      fecha_expiracion: '',
      fecha_publicacion: '',
      indexar_texto: false,
      languaje: null,
      observaciones: '',
      robot: '',
      roles_habilitados: '',
      es_template: false,
      tipo_contenido: 'FORMULARIO',
      title: 'Datos asociados a ' + this.state.xf_type,
      url: 'XF/' + subdomain + '/' + table + '/' + this.state.xf_type,
      url_padre: '_NONE',
      view: false,
    };
    if (DEBUG) {
      createRouteBody = { data: encrypt(JSON.stringify(createRouteBody)) };
    }
    routerAxios.post('/api/routes', createRouteBody).then((result) => {
      const respuesta = result.data;
      if (respuesta && respuesta.status === 'success') {
        let cadena;
        let data = {
          url: respuesta.data.url,
          contenido: '<div class="templateTest col-12 col-xl-12 general"></div>',
          type: 'use_body_name',
        };
        if (DEBUG) {
          data = { data: encrypt(JSON.stringify(data)) };
        }
        routerAxios.put('/api/routes/files/' + cadena, data).then((result) => {
          if (result.data.status === 'success') {
            window.open(
              '/pagebuilder/edit?url=' +
                'XF/' +
                subdomain +
                '/' +
                table +
                '/' +
                this.state.xf_type +
                '&uid=' +
                localStorage.getItem('U_ID'),
            );
          } else {
            this.channel.publish('notification', [
              { tipo: 'error', titulo: '', data: result.data.data.message },
            ]);
          }
        });
      } else {
        window.open(
          '/pagebuilder/edit?url=' +
            'XF/' +
            subdomain +
            '/' +
            table +
            '/' +
            this.state.xf_type +
            '&uid=' +
            localStorage.getItem('U_ID'),
        );
      }
    });
  };

  render() {
    if (this.state.elements && !this.state.changePage) {
      const form = {
        pantalla: {
          props: {
            type: 'container',
          },
          children: this.state.fields,
        },
      };
      return (
        <div>
          <MountComponent externalPantalla={form} formid={this.props.formid} validaciones={this.props.validar} isExtraFieldComponent={true}/>
          <div>
            <div className="btnBuildPage d-none">
              <WebButton
                {...this.state}
                msgpage={'Editar'}
                handleOnClick={this.fnBuildFormPage}
              />
            </div>
          </div>
        </div>
      );
    } else if (this.fnExistsRoles()) {
      return (
        <div>
          <div className="btnBuildPage">
            <WebButton
              {...this.state}
              msgpage={'Crear'}
              handleOnClick={this.fnBuildFormPage}
            />
          </div>
          <div>
            <div className="btnBuildPage">
              <WebButton
                {...this.state}
                msgpage={'Editar'}
                handleOnClick={this.fnBuildFormPage}
              />
            </div>
          </div>
        </div>
      );
    }
    return null;
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    menu: state.menu,
    logged: state.logged,
    model: _.get(state.model, ['model', ownProps.formid], {}),
  };
};

export default connect(mapStateToProps)(ExtraFields);
