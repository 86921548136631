import React, { Component, createRef } from 'react';
import cn from 'classnames';
import './Modal.scss';
import _ from 'lodash';
import postal from 'postal';

class Modal extends Component {
  constructor(props) {
    super(props);
    this.channel = postal.channel();
    this.modalRef = createRef();
    this.state = {
      open: props.open,
      modalCildren: null,
      submit: {},
      submited: false,
      clicked: false
    };
    this.channel = postal.channel();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.open !== this.props.open) {
      this.setState({
        open: this.props.open
      });
    }
  }

  componentDidMount() {
    // window.addEventListener("keydown", this.handleKeyDown, false);
    // Cuando la modal estaba montada en la pantalla el evento keydown al estar
    // asociada a window ejeecutaba varios mètodos indiscriminadamente. Ahora
    // està asociado al contenedor especìfico de la modal.
    const div = this.modalRef.current;
    if (div !== null) div.addEventListener('keydown', this.handleKeyDown, false);
    this.channel.subscribe('dataSubmitted', ({ refresh, keepOpenModal }) => {
      if (refresh && !keepOpenModal) {
        this.handleOnBack();
      }
    });
  }

  shouldComponentUpdate(nextProps, nextState) {
    // Validaciòn para cuando la modal no tengo una pantalla asociada ni sigua ejecutando otros
    // mètodos del ciclo de vida del componente
    if (_.get(nextProps, 'children') !== undefined) return true;
    else return false;
  }

  componentWillUnmount() {
    const div = this.modalRef.current;
    if (div !== null) div.addEventListener('keydown', this.handleKeyDown, false);
  }

  handleKeyDown = e => {
    const { open } = this.state;
    const evt = e || window.event;
    if (open && evt.keyCode === 27) {
      this.channel.publish('close:modal');
      this.handleOnBack();
    }
  };

  handleOnBack = () => {
    this.setState({
      open: false,
      link: ''
    });
    this.props.onBack();
  };

  render() {
    const { className, title, children } = this.props;
    const { open } = this.state;
    if (open) {
      return (
        <div ref={this.modalRef} className={cn(className, 'mdal')}>
          <div className="mdalnav">
            <i className="mdalback ti-arrow-left" onClick={this.handleOnBack}/>
            {title && <label className="mdaltitle">{title}</label>}
          </div>
          <div className="mdalcontainer">{children}</div>
        </div>
      )
    } else return null;
  }
}

Modal.defaultProps = {
  className: '',
  title: '',
  open: true,
  onBack: () => {
  }
};

export default Modal;
