export const CHART_SUCCESS = 'CHART_SUCCESS';
export const CHART_FILTER_DELETED = 'CHART_FILTER_DELETED';
export const CHART_FILTER_ADDED = 'CHART_FILTER_ADDED';
export const LIST_FILTER_ADDED = 'LIST_FILTER_ADDED';

const ChartAction = (type, { data = null, success = false }) => {
  return {
    type,
    payload: data,
  };
};

const ChartActionFilter = (id, data) => {
  return {
    type: CHART_FILTER_ADDED,
    payload: {
      data: data,
      formid: id,
    },
  };
};
const ListActionFilter = (id, data) => {
  return {
    type: LIST_FILTER_ADDED,
    payload: {
      data: data,
      formid: id,
    },
  };
};

export const untrackChartRegion = (colName) => (dispatch) =>
  dispatch(ChartAction(CHART_FILTER_DELETED, { data: colName }));

export const trackChartRegion = (selection = {}) => {
  const { id, label, colName } = selection;
  if (id && label && colName) {
    return (dispatch) => dispatch(ChartAction(CHART_SUCCESS, { data: selection }));
  }
};

export const onChangeDashboardFilters = (formid, params) => {
  return function (dispatch) {
    dispatch(ChartActionFilter(formid, params));
  };
};

export const onChangeListFilters = (formid, params) => {
  return function (dispatch) {
    dispatch(ListActionFilter(formid, params));
  };
};
