import postal from 'postal';
import objectPath from 'object-path';
import { authAxios } from '../../config/requestAxios';

const channel = postal.channel();
export const GET_FILES = 'GET_FILES';
export const GET_FILES_SUCCESS = 'GET_FILES_SUCCESS';
export const GET_FILES_FAILURE = 'GET_FILES_FAILURE';

const dataModelInit = (props) => {
  return {
    requesting: true,
    success: false,
    error: false,
    ...props,
  };
};

const dataModelSuccess = (props) => {
  return {
    requesting: false,
    success: true,
    error: false,
    ...props,
  };
};

const dataModelError = (error, props) => {
  return {
    requesting: false,
    success: false,
    error,
    ...props,
  };
};

export function getFiles(formid, url) {
  return function (dispatch) {
    const id = formid || idByUrl(url);
    dispatch({
      type: GET_FILES,
      update: {
        [id]: {
          ...dataModelInit(),
        },
      },
    });

    return authAxios
      .get(url)
      .then((response) => {
        if (
          objectPath.get(response.data, 'type') !== undefined ||
          !Array.isArray(response.data)
        ) {
          channel.publish('notification', [
            {
              tipo: response.data.type,
              titulo: 'Error',
              data: response.data.message,
            },
          ]);
          const error = {
            tipo: response.data.type,
            titulo: 'Error',
            data: response.data.message,
          };
          throw error;
        }
        dispatch({
          type: GET_FILES_SUCCESS,
          update: {
            [id]: {
              ...dataModelSuccess({ data: response.data }),
            },
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_FILES_FAILURE,
          update: {
            [id]: {
              ...dataModelError({ error }),
            },
          },
        });
      });
  };
}

export const REMOVE_FILE = 'REMOVE_FILE';
export const REMOVE_FILE_SUCCESS = 'REMOVE_FILE_SUCCESS';
export const REMOVE_FILE_FAILURE = 'REMOVE_FILE_FAILURE';

export function removeFile(url) {
  return function (dispatch) {
    const id = idByUrl(url);
    dispatch({
      type: REMOVE_FILE,
      payload: {
        [id]: {
          ...dataModelInit(),
        },
      },
    });

    return authAxios
      .delete(url)
      .then((response) => {
        if (objectPath.get(response.data, 'type') !== undefined) {
          channel.publish('notification', [
            {
              tipo: response.data.type,
              titulo: 'Error',
              data: response.data.message,
            },
          ]);
        }
        dispatch({
          type: REMOVE_FILE_SUCCESS,
          payload: {
            [id]: {
              ...dataModelSuccess({ data: response.data }),
            },
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: REMOVE_FILE_FAILURE,
          payload: {
            [id]: {
              ...dataModelError({ error }),
            },
          },
        });
      });
  };
}

export function idByUrl(url) {
  return url && url.replace(/[:/?&=.]+/g, '-');
}
