import React, { Component } from 'react';
import postal from 'postal';
import { connect } from 'react-redux';
import { FormFeedback } from 'reactstrap';
import MaskedInput from 'react-maskedinput';
import { readOnly, validateFields, initXfAndValue, attrChangeModel } from '../utils';
import { onChangeModelActions } from '../../../store/actions/ModelActions';
import { dispatchEvents, hasEventChanged, setModelValidation } from '../events';
import _ from 'lodash';

class MaskInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.value,
      show: true,
      readOnly: readOnly(this.props),
      pbValue: this.props.value || null,
      messageError: false,
      message: '',
      messageField: '',
      clases: this.props.class,
      errores: 0,
    };
    this.channel = postal.channel();
    this.timer = null;
  }

  componentDidMount() {
    if (this.props.name === '') {
      this.showNotification(
        'El componenente \n' +
          JSON.stringify(this.props) +
          '\n no tiene el atributo name',
      );
    }
    if (this.props.formid) {
      setModelValidation(this);
      this.clearComponent();

      this.channel.subscribe(
        `${this.props.formid}:${this.props.name}:hidden`,
        (data) => {
          this.setState({ readOnly: data.v });
        },
      );
      /**
       * Inicialización de datos del componente.
       */
      initXfAndValue(this);

      /**
       * Ejecución de los eventos adicionales del componente String
       */
      if (_.get(this.props, 'data-events')) dispatchEvents(this);
    }
  }

  showNotification = (message) => {
    this.channel.publish('notification', [
      { tipo: 'error', titulo: 'Error', data: message },
    ]);
  };

  handleChange = (e) => {
    const value = e.target.value;
    validateFields(value, this);
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.setState({ value: value });
      /**
       * Actualización del modelo global desde aqui
       */
      this.props.dispatch(
        onChangeModelActions(this.props.formid, {
          name: this.props.name,
          value: value,
        }),
      );
    }, 500);
  };

  clearComponent = () => {
    const _name = _.get(this.props, 'old_name')
      ? this.props.old_name.split('__')[0]
      : this.props.name;
    this.channel.subscribe(
      `${this.props.formid}:${_name}:clearComponent`,
      (data) => {
        /**
         * Limpiando el componente y el campo relacionado en el modelo global
         * de la pantalla
         */
        this.setState({ value: '' });
        this.props.dispatch(
          onChangeModelActions(this.props.formid, {
            name: this.props.name,
            value: '',
          }),
        );
      },
    );
  };

  handleKeyPress = (e) => {
    if (e.which === 13 || e.keyCode === 13) {
      this.channel.publish(`${this.props.name}:onKeyPress`, e.target.value);
    }
  };

  componentDidUpdate(prevProps, prevState) {
    const currentModel = prevProps.model;
    const nextModel = this.props.model;
    // const modelChanged = !_.isEqual(currentModel,nextModel);
    // if (modelChanged && _.get(this.props, "data-events")) {
    //   dispatchEvents(this);
    // }

    if (hasEventChanged(this.props, currentModel, nextModel)) dispatchEvents(this);
    const { value, onChange } = attrChangeModel(
      this.props.name,
      currentModel,
      nextModel,
      this.props['data-valores_formulario'],
    );
    if (onChange) {
      this.setState({ value: value });
    }
    if (prevProps.validar !== this.props.validar && this.props.validar === 'validar') { validateFields(this.state.value, this); }
    if (prevState.errores !== this.state.errores && this.state.errores === 1) { this.props.updateParent({ sumar: this.props.name }); } else if (prevState.errores !== this.state.errores && this.state.errores === 0) { this.props.updateParent({ restar: this.props.name }); } else if (
      prevProps.validar !== this.props.validar &&
      this.props.validar === 'validar' &&
      prevState.errores === this.state.errores &&
      this.state.errores === 0
    ) { this.props.updateParent({ igual: 1 }); }

    setModelValidation(this, !this.state.readOnly && this.state.show);
  }

  render() {
    // CURRENCY = <MaskedInput isReverse={false} mask={"##0,00"} /><br />
    // dd/MM/YYYY = <MaskedInput isReverse={false} mask={"00/00/0000"} /><br />
    if (this.state.show) {
      return (
        <div>
          <MaskedInput
            isReverse={this.props.isReverse}
            mask={this.props.mask}
            invalid={this.state.invalid}
            valid={this.state.valid}
            className={this.props.class}
            type={this.props.type}
            value={this.state.value}
            onBlur={this.handleBlur}
            readOnly={this.state.readOnly}
            onChange={this.handleChange}
            onKeyPress={this.handleKeyPress}
          />
          {this.state.messageError && (
            <FormFeedback invalid tooltip>
              {this.state.message}
            </FormFeedback>
          )}
        </div>
      );
    } else return null;
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    model: _.get(state.model, ['model', ownProps.formid], {}),
    roles: _.get(state.logged, 'logged.roles') ? state.logged.logged.roles : [],
    form: state.form.form,
  };
};

export default connect(mapStateToProps)(MaskInput);
