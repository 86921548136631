import React, { Component } from 'react';
import { connect } from 'react-redux';
import postal from 'postal';
import _ from 'lodash';
import { onChangeModelActions } from '../../../store/actions/ModelActions';
import { readOnly, validateFields, initXfAndValue, attrChangeModel, validateXfValue } from '../utils';
import { dispatchEvents, hasEventChanged, setModelValidation } from '../events';

class TextAreaDesign extends Component {
  static default = {
    titulo: '',
    name: '',
    size: '',
    maxLength: '',
    id: '',
  };

  constructor(props) {
    super(props);
    this.state = {
      value: this.props.value || '',
      show: true,
      readOnly: readOnly(this.props),
      pbValue: this.props.value || null,
      messageField: '',
      errores: 0,
      clases: this.props.class,
      isValidated: false
    };
    this.channel = postal.channel();
    this.timer = null;
  }

  componentDidMount() {
    if (this.props.name === '') {
      this.showNotification(
        'El componenente \n' +
          JSON.stringify(this.props) +
          '\n no tiene el atributo name',
      );
    }
    if (this.props.formid) {
      setModelValidation(this);
      this.clearComponent();

      this.channel.subscribe(
        `${this.props.formid}:${this.props.name}:hidden`,
        (data) => {
          this.setState({ readOnly: data.v });
        },
      );
      /**
       * Inicialización de datos del componente.
       */
      initXfAndValue(this);

      /**
       * Ejecución de los eventos adicionales del componente String
       */
      if (_.get(this.props, 'data-events')) dispatchEvents(this);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const currentModel = prevProps.model;
    const nextModel = this.props.model;

    // const modelChanged = !_.isEqual(currentModel,nextModel);
    // if (modelChanged && _.get(this.props, "data-events")) {
    //     dispatchEvents(this);
    // }
    if (hasEventChanged(this.props, currentModel, nextModel)) dispatchEvents(this);
    const { value, onChange } = attrChangeModel(
      this,
      this.props.name,
      currentModel,
      nextModel,
    );
    if (onChange && this.state.value !== value) {
      this.setState({ value: value });
    } else validateXfValue(this, nextModel, value);

    if (prevProps.validar !== this.props.validar && this.props.validar === 'validar') { validateFields(this.state.value, this); }
    if (this.props.validate && !this.state.isValidated && this.props.isRepeaterComponent) {
      validateFields(this.state.value, this)
      this.setState({ isValidated: true })
    }
    if (prevState.errores !== this.state.errores && this.state.errores === 1) { this.props.updateParent({ sumar: this.props.name }); } else if (prevState.errores !== this.state.errores && this.state.errores === 0) { this.props.updateParent({ restar: this.props.name }); } else if (
      prevProps.validar !== this.props.validar &&
      this.props.validar === 'validar' &&
      prevState.errores === this.state.errores &&
      this.state.errores === 0
    ) { this.props.updateParent({ igual: 1 }); }

    setModelValidation(this, !this.state.readOnly && this.state.show);
  }

  /** Si el componente es un XF y se desmonta tambien se quita de los requeridos */
  componentWillUnmount() {
    if (_.get(this.props, 'isExtraFieldComponent') && _.get(this.props, 'required', false)) {
      setModelValidation(this, false)
    }
  }

  showNotification = (message) => {
    this.channel.publish('notification', [
      { tipo: 'error', titulo: 'Error', data: message },
    ]);
  };

  clearComponent = () => {
    const _name = _.get(this.props, 'old_name')
      ? this.props.old_name.split('__')[0]
      : this.props.name;
    this.channel.subscribe(
      `${this.props.formid}:${_name}:clearComponent`,
      (data) => {
        /**
         * Limpiando el componente y el campo relacionado en el modelo global
         * de la pantalla
         */
        this.setState({ value: '' });
        this.props.dispatch(
          onChangeModelActions(this.props.formid, {
            name: this.props.name,
            value: '',
          }),
        );
      },
    );
  };

  handleInputChange = (e) => {
    const { value } = e.target;
    this.setState({ value: value });
    validateFields(value, this);
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      // if(objectPath.get(this.props,"data-events")) dispatchEvents(this);
      this.props.dispatch(
        onChangeModelActions(this.props.formid, {
          name: this.props.name,
          value: value,
        }),
      );
    }, 500);
  };

  render() {
    if (this.state.show) {
      const title = this.props.titulo || '';
      let { value } = this.state || '';
      value = value || '';
      return (
        <>
          <textarea
            className={this.state.clases}
            title={title}
            value={value}
            onChange={this.handleInputChange}
            name={this.props.name}
            readOnly={this.state.readOnly}
            id={this.props.id}
            placeholder={this.props.placeholder || ''}
            size={this.props.size}
            style={{ height: `${this.props.height}px` }}
            maxLength={this.props.maxLength}
          ></textarea>
          <span className="alert-form">{this.state.messageField}</span>
        </>
      );
    } else return null;
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    model: _.get(state.model, ['model', ownProps.formid], {}),
    form: state.form.form,
    validate: _.get(state.model, 'validation', false)
  };
};

export default connect(mapStateToProps)(TextAreaDesign);
