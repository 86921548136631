import postal from 'postal';
import * as R from 'ramda';
import { repeat, zipObj } from 'ramda';
import { logger, renderToString, stringToJson } from '../../utils/helper';
import React from 'react';
import MountComponent from '../MountComponent/MountComponent';
import _ from 'lodash';
import { restoreDot } from '../../utils/utils';

/** * PRIVATE DEFINTIONS ***/

const channel = postal.channel();
const BOOL2CHECK = { true: 'TRUE', false: 'FALSE' };
const SUMMARY_TYPES = ['count', 'max', 'min', 'sum'];

/**
 *
 * @param {String} key
 * @param {String} val
 * @returns {Boolean}
 */
const notEmptyValue = (key, val) => val && val !== 0;

/**
 *
 * @param {Array} arr
 * @param {Number} n
 * @returns {Array}
 */
const chunk = (arr, n) => {
  return arr.slice(0, ((arr.length + n - 1) / n) | 0).map(function (c, i) {
    return arr.slice(n * i, n * i + n);
  });
};

/**
 *
 * @param {String} boolValue
 * @returns {Boolean}
 */
const parseBool = (boolValue) => {
  return BOOL2CHECK[boolValue] || BOOL2CHECK.false;
};

/**
 *
 * @param {Object} rowChange
 * @param {Array} boolColNames
 * @returns {Array}
 */
const checkToBool = (rowChange, boolColNames) => {
  const result = Object.keys(rowChange).reduce(
    (prev, key) =>
      boolColNames.includes(key)
        ? { ...prev, [key]: rowChange[key] === BOOL2CHECK.true }
        : { ...prev, [key]: rowChange[key] },
    {},
  );
  return result;
};

/**
 *
 * @param {String} colName
 * @param {Object} element
 * @returns {Boolean}
 */
const isColIncluded = (colName, element) => {
  return (
    (typeof element === 'string' && element === 'all') ||
    (_.get(element, 'cols') &&
      Array.isArray(element.cols) &&
      element.cols.includes(colName))
  );
};

/**
 *
 * @param {Array} arr
 * @returns {String}
 */
const makeInClause = (arr) => {
  const anded = R.groupBy((elmt) => elmt !== 'and', arr);
  if (!anded.false) {
    const grouped = R.groupBy((elmt) => elmt !== 'or', arr).true;
    const chunked = chunk(grouped, 3);
    const value = chunked.map((e) => _.get(e, '2', '').toUpperCase());
    const inClause = { field: _.get(chunked, '0.0'), operator: 'IN', value };
    return inClause;
  }
  const andChunk = chunk(anded.true, 3);
  const result = andChunk.reduce((acc, cur, idx) => {
    return idx > 0 ? [...acc, 'AND', renderItem(cur)] : [...acc, renderItem(cur)];
  }, []);
  return result;
};
/**
 *
 * @param {Array} arr
 * @returns {Object}
 */
export const renderItem = (arr) => {
  switch (arr.length) {
    case 1:
      return arr[0].toUpperCase();
    case 3:
      return filterToSQL({ field: arr[0], operator: arr[1], value: arr[2] });
    default:
      return makeInClause(arr);
  }
};

const renderLink = (formid, link) => {
  return _.isObject(link)
    ? {
        ...link,
        mountedPantalla: mountPantalla(formid, link ? link.pantalla : ''),
      }
    : {};
};

const renderLinks = (formid, links) =>
  Array.isArray(links) ? links.map((e) => renderLink(formid, e)) : links;

const conformCalcColumn = ({
  summarydisplay,
  summaryformat,
  summaryformula,
  summarytitle,
  summarytype,
}) => {
  const title = renderToString(summarytitle);
  const formula = renderToString(summaryformula);

  return title && formula
    ? {
        dataType: 'NUMBER',
        name: title,
        title,
        type: 'FIELD',
        formula,
        format: renderToString(summaryformat) || '0#.00',
        summaryType: renderToString(summarytype) || 'sum',
        summaryDisplay: renderToString(summarydisplay) || 'Total: {0}',
        summaryFormat: renderToString(summaryformat) || '0#.00',
        updateable: 'N',
      }
    : null;
};

const openLink = ({ row = {}, link = {} }) => {
  if (link.alias_campo) {
    const token = localStorage.getItem('enp_id');
    const url = `${link.alias_campo}/?file=${row.UUID}&name=${row.DESCRIP_SOPORTE}&token=${token}`;
    window.open(url);
  }
};

const publishLink = ({
  formid,
  table,
  filters,
  row,
  columns,
  link,
  isnew,
  ischeck,
  valores_formulario,
  isModal = false,
  models,
}) => {
  if (formid) {
    const updRow = !isnew
      ? row
      : columns.reduce((acc, e) => ({ ...acc, [e.name]: null }), { ...row });
    channel.publish('listadoMenuClick', {
      row: updRow,
      link,
      formid,
      filters,
      columns,
      TABLA: table,
      isnew,
      ischeck,
      valores_formulario,
      isModal,
      models,
    });
  }
};

const txArray = (filterArr) => {
  const filters = filterArr || [];
  const filters1 = typeof filters[0] === 'string' ? [filters] : filters;
  const f = filters1.map((e) => e.toString());
  const result = f.map((e) => renderItem(e.split(',')));
  return result;
};

const addFiltersMap = (filterArr, filterMap) => {
  const keys = _.keys(filterMap || {});
  const txfilters = keys.reduce((acc, field) => {
    const { value, separator, csv } = _.get(filterMap, [field]) || {};
    const splitted = !csv ? value : splitCSV(value, separator);
    const result = !csv
      ? !_.isEmpty(splitted)
          ? { field, operator: 'LIKE', value: `${splitted}%` }
          : null
      : !_.isEmpty(_.get(splitted, '0'))
          ? { field, operator: 'IN', value: splitted }
          : null;
    return result ? [...acc, result] : acc;
  }, []);
  const result = R.intersperse('AND', txfilters);
  return _.isEmpty(filterArr)
    ? result
    : _.isEmpty(result)
      ? filterArr
      : [...filterArr, 'AND', ...result];
};

/** * PUBLIC DEFINITIONS ***/

export const splitCSV = (str, separator = ',') => {
  const splitted = (str || '').split(separator);
  return splitted.map((str) => str.trim());
};

export const SpecialColumns = {
  color: 'COLOR',
  icon: 'ICON_NAME',
  link: 'LINK',
};

export const LINK_TYPES = ['TAB', 'URL', 'MODAL'];

export const LINK_FNS = {
  TAB: (data) => publishLink(data),
  URL: (data) => openLink(data),
  MODAL: (data) => publishLink({ ...data, isModal: true }),
};

export const CALC_INITIAL = {
  sum: 0,
  count: 0,
  avg: 0,
  min: Number.POSITIVE_INFINITY,
  max: Number.NEGATIVE_INFINITY,
};

export const getCellColor = (row, columnName) => {
  try {
    const colors = stringToJson(row[SpecialColumns.color]);
    const [color] = colors
      ? Object.keys(colors).filter((key) =>
        isColIncluded(columnName, colors[key]) ? key : null,
      )
      : [];
    return color || 'white';
  } catch (e) {
    logger('color error', e);
  }
};

export const isVisualColumn = ({ type, dataType, name }) =>
  !equalRegEx('link').test(type) &&
  !equalRegEx('hidden').test(dataType) &&
  !Object.values(SpecialColumns).includes(name);

export const enabledColProp = (columns, prop) =>
  columns.some((col) => equalRegEx('S').test(col[prop]));

export const filterToSQL = ({ field, operator, value }) => {
  const upValue = typeof value === 'string' ? value.toUpperCase() : value;

  switch (operator) {
    case '<>':
      return { field, operator: '!=', value: upValue };
    case 'startswith':
      return { field, operator: 'LIKE', value: `${upValue}%` };
    case 'endswith':
      return { field, operator: 'LIKE', value: `%${upValue}` };
    case 'contains':
      return { field, operator: 'LIKE', value: `%${upValue}%` };
    case 'notcontains':
      return { field, operator: 'NOT LIKE', value: `%${upValue}%` };
    default:
      return { field, operator, value: upValue };
  }
};

export const filterLovOptions = (options, data) =>
  !_.isEmpty(options) && !_.isEmpty(data)
    ? options.filter((e) => {
      const filterKeys = R.keys(e.filters);
      const selKeys = R.keys(R.pickBy(notEmptyValue, data));
      const keys = R.intersection(filterKeys, selKeys);
      const filterObj = R.pick(keys, e.filters);
      const condObj = R.pick(keys, data);
      return R.equals(filterObj, condObj);
    })
    : options;

export const IdRows = (rows = []) => rows.map((r, i) => ({ ...r, ID: i }));

export const hasId = (rows) => {
  const first = _.get(rows, [0], {});
  const fields = Object.keys(first) || [];
  return fields.includes('UUID');
};

export const REQUESTED_ALIAS = [];

export const requestAlias = (alia) => {
  if (REQUESTED_ALIAS.indexOf(alia) < 0) REQUESTED_ALIAS.push(alia);
};

export let REQUESTED_ALIAS_DATA = {};

export const requestAliasData = (id, data) => {
  REQUESTED_ALIAS_DATA = Object.assign(REQUESTED_ALIAS_DATA, { [id]: data });
};

export const dedupe = (coll) => Array.from(new Set(coll));

export const equalRegEx = (str) => new RegExp('^' + str + '$', 'i');

export const filterVisualColumns = (columns) =>
  columns.filter(({ type }) => type === 'FIELD' || type === 'LINK');

export const propsToFilters = (stringOfFilters) => {
  if (stringOfFilters) {
    const filterNames = stringOfFilters.split(',');
    const r = zipObj(filterNames, repeat('', filterNames.length));
    return r;
  }
};

export const boolToCheck = (rowChange) => {
  const result = Object.keys(rowChange).reduce(
    (prev, key) =>
      typeof rowChange[key] === 'boolean'
        ? { ...prev, [key]: parseBool(rowChange[key]) }
        : { ...prev, [key]: rowChange[key] },
    {},
  );
  return result;
};

export const renderBoolean = (row, columsDef) => {
  if (columsDef) {
    const boolCols = columsDef
      .filter(({ dataType }) => dataType === 'CHECK')
      .map(({ name }) => name);
    return checkToBool(row, boolCols);
  }
  return row;
};

export const mountPantalla = (formid, pantalla, model = null, ischeck = false) => {
  return pantalla ? (
    model ? (
      <MountComponent
        externalPath={'/' + pantalla}
        formid={formid}
        model={model}
        ischeck={ischeck}
      />
    ) : (
      <MountComponent
        externalPath={'/' + pantalla}
        formid={formid}
        ischeck={ischeck}
      />
    )
  ) : null;
};

export const txData = (props) => {
  const hasAlias = props['data-alias'];
  const txProps = hasAlias ? props : _.get(props, 'data', {});

  const LOVS = _.get(props, ['table', 'LOVS', props['data-alias']]) || [];

  const table = hasAlias
    ? _.get(props, ['table', 'TABLES', props['data-alias'], props.formid]) || {}
    : txProps;

  const data = hasAlias
    ? null
    : hasId(txProps.columns)
      ? txProps.rows
      : IdRows(txProps.rows);

  const { edit_form = {}, create_form = {} } = table;

  const calcColumn = conformCalcColumn(props);

  const columns = !calcColumn
    ? table.columns
    : table.columns
      ? [...table.columns, calcColumn]
      : table.columns;

  const result = {
    LOVS,
    ...{
      ...table,
      columns: columns || [],
      links: renderLinks(txProps.formid, table.links),
      edit_form: renderLink(txProps.formid, edit_form),
      create_form: renderLink(txProps.formid, create_form),
    },
  };
  return hasAlias ? result : { ...result, data };
};

export const parseFormula = (formulaField, row, columnDefs) => {
  let formula = _.get(formulaField, 'formula', '');
  let result = '';
  if (formula && !_.isEmpty(row) && columnDefs.length) {
    columnDefs.forEach((e) => {
      const colname = new RegExp(e.name, 'g');
      formula = formula.replace(colname, _.get(row, [e.name]));
    });
    try {
      result = eval(formula);
    } catch (e) {
      result = `Error: ${e}`;
    }
    return result;
  } else return '';
};

export const getSummaryColumns = (columns) =>
  columns.filter(
    (col) =>
      col.summaryType && SUMMARY_TYPES.includes(col.summaryType.toLowerCase()),
  );

export const addSorting = (defaultMap, { sort }) => {
  const sortby = _.isEmpty(sort) ? sort : sort.filter((e) => e.selector !== 'UUID');
  return _.isEmpty(sortby)
    ? defaultMap
    : {
        ...defaultMap,
        sort: sortby.reduce(
          (acc, { selector, desc }) => ({
            ...acc,
            [selector.replace(/\(dot\)/g, '.')]: desc ? 'DESC' : 'ASC',
          }),
          {},
        ),
      };
};

export const conformRequestParams = (tablename, action, rowData) => ({
  IBLE_P_COD_ACCION: 'SUBMIT_AUTOMATICO',
  IBLE_P_CAMPO: 'SUBMIT_AUTOMATICO',
  IBLE_P_EVENTO: 'CLICK',
  TABLA: tablename || '',
  SUBMIT_ACTION: action,
  ...rowData,
});

export const mapFilters = (filters = [], filtersMap = {}) => {
  const filtersCond = txArray(filters);
  const filterArr = filtersCond.flat(2);
  const result = addFiltersMap(filterArr, filtersMap);
  const txresult = result.map((filter) =>
    _.get(filter, 'field') ? { ...filter, field: restoreDot(filter.field) } : filter,
  );
  return txresult.filter((e) => _.get(e, 'field') !== 'UUID');
};

export const computeAggregate = (acc, curValue, { summaryType }) => {
  switch (summaryType.toLowerCase()) {
    case 'sum':
      return acc + curValue;
    case 'min':
      return curValue < acc ? curValue : acc;
    case 'max':
      return curValue > acc ? curValue : acc;
    case 'count':
      return acc + 1;
    default:
      return acc;
  }
};

/* Devuelve un objeto con las sumarizaciones,
   opcionalmente se puede especificar un funcion de transformacion de llaves keyFn */
export const getSummariesValues = (
  summaries,
  grid,
  keyFn = (identity) => identity,
) => {
  const result = summaries.reduce((acc, summary) => {
    const value = grid.getTotalSummaryValue(summary.name);
    const key = keyFn(summary.name);
    return { ...acc, [key]: value };
  }, {});
  return result;
};
