import objectPath from 'object-path';
import { requestAxios, routerAxios } from '../../config/requestAxios';
import { encrypt } from '../../components/utils';
import { isProductionEnv } from '../../utils/helper';

export const REQUEST_REQUESTING = 'REQUEST_REQUESTING';
export const REQUEST_SUCCESS = 'REQUEST_SUCCESS';
export const REQUEST_ERROR = 'REQUEST_ERROR';

const DEBUG = isProductionEnv();

function requestRequesting(id) {
  return {
    type: REQUEST_REQUESTING,
    update: {
      [id]: {
        requesting: true,
        success: false,
        error: false,
        data: null,
      },
    },
  };
}

function requestSuccess(id, data) {
  return {
    type: REQUEST_SUCCESS,
    update: {
      [id]: {
        requesting: false,
        success: true,
        error: false,
        data: data,
      },
    },
  };
}

function requestFailure(id, err) {
  return {
    type: REQUEST_ERROR,
    update: {
      [id]: {
        requesting: false,
        success: false,
        error: err,
        data: null,
      },
    },
  };
}

export const requestIble = (id, props = {}, isFromRouter = false) => {
  return (dispatch, getState) => {
    const requests = getState().requests;
    const isRequesting = objectPath.get(requests, `${id}.requesting`);
    const _props = { ...props };

    if (isRequesting) {
      return Promise.resolve();
    }

    if (!_props.url) {
      return Promise.reject(new Error('[url] is required'));
    }

    if (!_props.method) {
      return Promise.reject(new Error('[method] is required'));
    }

    if (_props.url.indexOf('http') < 0) {
      _props.url = `${_props.url}`;
    }

    dispatch(requestRequesting(id));

    const axiosConf = !isFromRouter ? requestAxios : routerAxios;

    return axiosConf(_props)
      .then((response) => {
        return dispatch(requestSuccess(id, response.data));
      })
      .catch((err) => {
        dispatch(requestFailure(id, err));
      });
  };
};

export const requestPreference = (user, dashId, method, payload) => {
  let url = '';
  let data = null;
  let id = '';
  if (method.toUpperCase() === 'GET') {
    url = `/api/user_preferences?cod_user=${user}&pref_name=${dashId}`;
    id = 'preference';
  } else if (method.toUpperCase() === 'POST') {
    url = '/api/user_preferences';
    data = { pref_name: dashId, cod_user: user, preference: payload };
    id = 'updated';
  }
  let params = data;
  if (DEBUG) {
    params = { data: encrypt(JSON.stringify(data)) };
  }
  return requestIble(id, { method, url, params }, true);
};
