import React, { Component, Suspense } from 'react';
import { BrowserRouter as Router, Route, Navigate, Routes as Rout } from 'react-router-dom';
import { getUserLogged } from './store/actions/LoggedActions';
import { connect } from 'react-redux';
import objectPath from 'object-path';
import { isProductionEnv } from './utils/helper';

import Login from './pages/Login';

const UrlEvent = React.lazy(() => import('./components/UrlEvent'));
const Layout = React.lazy(() => import('./layout'));
const RecoverPassword = React.lazy(() => import('./pages/RecoverPassword'));
const ChangePassword = React.lazy(() => import('./pages/ChangePassword'));
const Logout = React.lazy(() => import('./pages/Logout'));
const ExcelUploader = React.lazy(() => import('./components/ExcelUploader/ExcelUploader'));
const TestServer = React.lazy(() => import('./components/TestServer/TestServer'));
const Health = React.lazy(() => import('./pages/Health'))
const DEBUG = isProductionEnv();

class Routes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      logged: false,
    };
  }

  componentDidMount() {
    this.props.dispatch(getUserLogged());
  }

  componentDidUpdate() {
    const username = objectPath.get(this.props, 'logged.logged.username');
    if (username !== undefined && this.state.logged === false) {
      this.setState({ logged: true });
    }
  }

  testRoute() {
    if (!DEBUG) {
      return (
        <Route
          path="/test/server"
          element={
              <Suspense fallback={<div></div>}>
                <TestServer />
              </Suspense>
          }
        />
      );
    } else {
      return false;
    }
  }

  isLogged() {
    const username = objectPath.get(this.props, 'logged.logged.username');
    if (username) {
      return (
        <Route
          path="/*"
          element={
              <Suspense fallback={<div></div>}>
                <Layout />
              </Suspense>
          }
        />
      );
    } else if (objectPath.get(this.props, 'logged.success') || objectPath.get(this.props, 'logged.error')) {
      return <Route path='/*' element={ <Navigate to={'/auth/login/*'} replace/> } />;
    }
  }

  render() {
    if (localStorage.getItem('enp_id') && localStorage.getItem('redirect')) {
      if (window.location.search.indexOf('search') > -1) {
        const _domain = window.location.search.split('=')[1];
        const dominio = `${window.location.protocol}//${_domain}?token=${localStorage.getItem(
          'enp_id',
        )}&name=${localStorage.getItem('NOMBRE')}`;
        window.location.href = dominio;
      } else {
        const href = window.location.href;
        const prePath = `${window.location.protocol}//${window.location.host}`;
        let redirectTo = '/';
        const splitedUrl = href.split('?');
        if (splitedUrl[2]) {
          if (href.split('?')[2].indexOf('token=') > -1) {
            redirectTo = `${prePath}${href.split('?')[1]}?${href.split('?')[2]}`;
          } else {
            redirectTo = `${prePath}${href.split('?')[1]}?${href.split('?')[2]}&token=${localStorage.getItem(
              'enp_id',
            )}`;
          }
        } else {
          if (href.split('?')[1].indexOf('token=') > -1) {
            redirectTo = `${prePath}${href.split('?')[1]}`;
          } else {
            redirectTo = `${prePath}${href.split('?')[1]}?token=${localStorage.getItem('enp_id')}`;
          }
        }
        window.location.href = redirectTo;
        localStorage.setItem('redirect', '');
      }
    } else {
      return (
        <Router>
          <Rout>
            <Route
              path="/__MON__"
              element={
                <Suspense fallback={<div></div>}>
                  <Health />
                </Suspense>
              }
            />
            <Route
              path="/auth/login/:UI/event/*"
              element={
                  <Suspense fallback={<div></div>}>
                    <UrlEvent />
                  </Suspense>
              }
            />
            <Route path="/auth/login/*" element={<Login/>} />
            <Route
              path="/auth/logout/*"
              element={
                  <Suspense fallback={<div></div>}>
                    <Logout />
                  </Suspense>
              }
            />
            <Route
              path="/auth/recover/password/*"
              element={
                  <Suspense fallback={<div></div>}>
                    <RecoverPassword />
                  </Suspense>
              }
            />
            <Route
              path="/auth/change/password/*"
              element={
                  <Suspense fallback={<div></div>}>
                    <ChangePassword />
                  </Suspense>
              }
            />
            {this.testRoute()}
            <Route
              path="/uploader/*"
              element={
                  <Suspense fallback={<div></div>}>
                    <ExcelUploader />
                  </Suspense>
              }
            />
            {this.isLogged()}
          </Rout>
        </Router>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    logged: state.logged,
    user: state.user,
  };
};

export default connect(mapStateToProps)(Routes);
