import { LOGOUT_ERROR, LOGOUT_INIT, LOGOUT_SUCCESS } from '../actions/LogoutActions';

const initializeState = {
  init: false,
  success: false,
  error: false,
  logout: {},
};
const LogoutReducers = (state = initializeState, action) => {
  switch (action.type) {
    case LOGOUT_INIT:
      return {
        ...state,
        ...action.payload,
      };
    case LOGOUT_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case LOGOUT_ERROR:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export default LogoutReducers;
