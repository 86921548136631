import { requestAxios } from '../../config/requestAxios';
import _ from 'lodash';

export const GET_ROW_SUCCESS = 'GET_ROW_SUCCESS';
export const GET_ROW_ERROR = 'GET_ROW_ERROR';
export const GET_ROW_INIT = 'GET_ROW_INIT';

const GetRowSuccess = (id, data) => {
  return {
    type: GET_ROW_SUCCESS,
    payload: {
      [id]: { ...{ init: false, success: true }, ...data },
    },
  };
};

const GetRowError = (err) => {
  return {
    type: GET_ROW_ERROR,
    payload: {
      error: err,
    },
  };
};
export const requestGetRowModel = (formid, params) => {
  return function (dispatch) {
    if (!_.isEmpty(params)) {
      requestAxios
        .post('/ible/GET_ROW', params)
        .then((response) => {
          dispatch(GetRowSuccess(formid, response.data));
          // dispatch(GetRowSuccess(formid,{...{success:true,init:false},...response.data}));
        })
        .catch((error) => {
          dispatch(GetRowError(error));
          // dispatch(GetRowError(error));
        });
    } else dispatch(GetRowSuccess(formid, { empty: true }));
    // } else dispatch(GetRowSuccess(formid,{empty:true,success:false,init:false}));
  };
};
