import {
  GET_ROW_INIT,
  GET_ROW_ERROR,
  GET_ROW_SUCCESS,
} from '../actions/GetRowActions';

const initialize = {
  getrow: {
    init: false,
  },
};

const GetRowReducers = (state = initialize, action) => {
  switch (action.type) {
    case GET_ROW_INIT:
      return {
        ...state,
        getrow: { ...state.getrow, ...action.payload },
      };
    case GET_ROW_SUCCESS:
      return {
        ...state,
        getrow: { ...state.getrow, ...action.payload },
      };
    case GET_ROW_ERROR:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export default GetRowReducers;
