import React, { Component, Fragment } from 'react';
import postal from 'postal';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  readOnly,
  validateFields,
  attrChangeModel,
  initXfAndValue,
  validateXfValue,
} from '../utils';
import { onChangeModelActions } from '../../../store/actions/ModelActions';
import { dispatchEvents, hasEventChanged, setModelValidation } from '../events';

class Number extends Component {
  static propTypes = {
    min: PropTypes.string,
    max: PropTypes.string,
    format: PropTypes.string,
  };

  static defaultProps = {
    min: '',
    max: '',
    format: '',
  };

  constructor(props) {
    super(props);
    this.state = {
      value: this.props.value,
      numberValue: this.props.value,
      show: true,
      readOnly: readOnly(this.props),
      pbValue: this.props.value || null,
      min:
        this.props.min !== '' ? parseFloat(this.props.min) : Number.MIN_VALUE,
      max:
        this.props.max !== '' ? parseFloat(this.props.max) : Number.MAX_VALUE,
      format: Array.isArray(this.props.format) ? this.props.format.join(' ') : this.props.format,
      messageError: false,
      message: '',
      messageField: '',
      clases: this.props.class,
      errores: 0,
      isValidated: false
    };
    this.channel = postal.channel();
    this.timer = null;
  }

  componentDidMount() {
    if (this.props.name === '') {
      this.showNotification(
        'El componenente \n' +
        JSON.stringify(this.props) +
        '\n no tiene el atributo name'
      );
    }
    if (this.props.formid) {
      setModelValidation(this);
      this.clearComponent();
      /**
       * Inicialización de datos del componente.
       */
      initXfAndValue(this);

      this.channel.subscribe(
        `${this.props.formid}:${this.props.name}:hidden`,
        (data) => {
          this.setState({ readOnly: data.v });
        }
      );
      /**
       * Ejecución de los eventos adicionales del componente String
       */
      if (_.get(this.props, 'data-events')) dispatchEvents(this);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const currentModel = prevProps.model;
    const nextModel = this.props.model;

    if (hasEventChanged(this.props, currentModel, nextModel)) { dispatchEvents(this); }

    let { value, onChange } = attrChangeModel(
      this,
      this.props.name,
      currentModel,
      nextModel
    );

    value = value || value === 0 || '' ? value : '';

    if (onChange && this.state.value !== value) {
      this.setState({ value: value });
    } else validateXfValue(this, nextModel, value);

    if (
      (prevProps.validar !== this.props.validar &&
      this.props.validar === 'validar')
    ) {
      validateFields(this.state.value, this);
    }

    if (
      this.props.validate && !this.state.isValidated && this.props.isRepeaterComponent
    ) {
      validateFields(this.state.value, this);
      this.setState({ isValidated: true })
    }

    if (prevState.errores !== this.state.errores && this.state.errores === 1) {
      this.props.updateParent({ sumar: this.props.name });
    } else if (
      prevState.errores !== this.state.errores &&
      this.state.errores === 0
    ) {
      this.props.updateParent({ restar: this.props.name });
    } else if (
      prevProps.validar !== this.props.validar &&
      this.props.validar === 'validar' &&
      prevState.errores === this.state.errores &&
      this.state.errores === 0
    ) { this.props.updateParent({ igual: 1 }); }

    // setModelValidation(this, !this.state.readOnly && this.state.show);
  }

  /** Si el componente es un XF y se desmonta tambien se quita de los requeridos */
  componentWillUnmount() {
    if (_.get(this.props, 'isExtraFieldComponent') && _.get(this.props, 'required', false)) {
      setModelValidation(this, false)
    }
  }

  showNotification = (message) => {
    this.channel.publish('notification', [
      { tipo: 'error', titulo: 'Error', data: message },
    ]);
  };

  /**
   * detects repeated characters in a string
   * @param {string} str string to inspect
   * @returns {array}
   */
  howManyRepeated = function (str) {
    const result = [];
    const strArr = str.toLowerCase().split('').sort().join('').match(/(.)\1+/g);

    if (strArr != null) {
      strArr.forEach((elem) => {
        result.push(elem[0]);
      });
    }
    return result;
  }

  /**
   * Format the received string with commas
   * @param {string} str string to format
   * @returns {string}
   */
  formatStringWithComma = function (str) {
    if (str.length < 3) { return str; }
    return str.split('').reverse().join('').match(/.{1,3}/g).map(function (x) {
      return x.split('').reverse().join('')
    }).reverse().join(',');
  }

  /**
   * "###.##"      => 1234.56
   * see https://stackoverflow.com/questions/354044/what-is-the-best-u-s-currency-regex
   *
   * @param {string} value string to format
   * @returns {string}
   */
  formatDecimal = (value) => {
    const allowedCharactersRegex = /^[0-9.-]*$/;
    if (allowedCharactersRegex.test(value)) {
      const startWithSeparathorRegex = /^[.,]*$/;
      const reperterSeparatorsRegex = /[.,]{2,}$/;
      if (startWithSeparathorRegex.test(value) || reperterSeparatorsRegex.test(value)) {
        return false;
      } else {
        // found the separator decimal
        // Should be at the end with two digits or not
        // is the dot . repeated in the string ?
        const dotRepeated = this.howManyRepeated(value).filter(v => v === '.');
        if (value.indexOf('.') !== -1 && dotRepeated.length > 0) {
          return false;
        } else return value;
      }
    }

    return false;
  }

  formatThousandsSeparator = function (value) {
    const allowedCharactersRegex = /^[0-9.,-]*$/;
    if (allowedCharactersRegex.test(value)) {
      // Lets formating the string...
      const startWithSeparathorRegex = /^[.,]*$/;
      const reperterSeparatorsRegex = /[.,]{2,}$/;

      if (startWithSeparathorRegex.test(value) || reperterSeparatorsRegex.test(value)) {
        return false;
      }
      const endsWithTwoDigits = /\.[0-9]{0,2}$/;
      // found the separator decimal
      // Should be at the end with two digits or not
      // is the dot . repeated in the string ?
      const dotRepeated = this.howManyRepeated(value).filter(v => v === '.');
      if (value.indexOf('.') !== -1 && dotRepeated.length > 0 && !endsWithTwoDigits.test(value)) {
        return false;
      } else {
        // Format value with commas
        const splitedValue = value.split('.');
        // remove previous commas from string and re-format it
        const cleanedString = splitedValue[0].replaceAll(',', '');
        splitedValue[0] = this.formatStringWithComma(cleanedString);
        value = splitedValue.join('.');
        // Make the numeric only value
        splitedValue[0] = cleanedString;
        return value;
      }
    }
    return false;
  }

  updateModel = (value) => {
    this.props.dispatch(

      onChangeModelActions(this.props.formid, {
        name: this.props.name,
        value: value,
      })
    );
  }

  // Funcion de redondeo de numeros.
  roundOut = (num) => {
    const decimals = this.state.format.split('.').length > 1 ? this.state.format.split('.')[1].length : 0;
    if (num !== '' && num !== undefined && num !== null && typeof (num) === 'string') {
      if (num.indexOf('.') !== -1) {
        const preNum = num.split('.')[0];
        const sufNum = num.split('.')[1];
        const substr = decimals > 2 ? decimals : 2
        if (_.size(sufNum) > 2) {
          return preNum + '.' + sufNum.substr(0, substr);
        } else if (_.size(num.split('.')) > 2) {
          return preNum + '.' + sufNum.substr(0, substr);
        } else return num;
      } else return num;
    }
    if (typeof (num) === 'number') {
      const pow = decimals > 2 ? decimals : 2
      const base = Math.pow(10, pow);
      const ent = Math.round(num * base);
      return ent / base;
    }
    if (num === null) return ''
    return num;
  }

  /**
   * Compatible formats
   * ""              => 123456
   * "###.##"        => 1234.56
   * "$ ###.##"      => $ 1234.56
   * "#,###.##"      => 1,234.56
   * "$ #,###.##"    => $ 1,234.56
   * @param {string} value
   * @returns
   */
  formatValue = function (value) {
    if (value === '' || value === '0') {
      return value;
    }

    if (this.state.format === '###.##' || this.state.format === '$ ###.##') {
      let formated = this.formatDecimal(value);
      if (formated) {
        if (this.state.format.indexOf('$') !== -1) {
          formated = '$ ' + formated;
        }

        return formated;
      } else {
        return false;
      }
    }

    if (this.state.format === '#,###.##' || this.state.format === '$ #,###.##') {
      // "#,###.##"  => 1,234.56
      let formated = this.formatThousandsSeparator(value);
      if (formated) {
        if (this.state.format.indexOf('$') !== -1) {
          formated = '$ ' + formated;
        }

        return formated;
      }

      return false;
    }

    const decimals = this.state.format.split('.').length > 1 ? this.state.format.split('.')[1].length : 0;

    if (decimals > 2) {
      let formated = this.state.format.indexOf(',') !== -1 ? this.formatThousandsSeparator(value) : this.formatDecimal(value);
      if (formated) {
        if (this.state.format.indexOf('$') !== -1) {
          formated = '$ ' + formated;
        }
        return formated;
      }

      return false;
    }

    // the format is unavailable, by default only numbers => 123456
    const onlyNumbers = /^[0-9]*$/;
    if (onlyNumbers.test(value) || this.formatDecimal(value)) {
      // Obteniendo el nuevo valor formateado
      value = this.formatDecimal(value);
      return value;
      // this.updateModel(value);
    }

    return false;
  }

  handleChange = (event) => {
    let value = event.target.value;
    // Remove format
    value = value.replaceAll('$ ', '');
    value = value.replaceAll(',', '');

    validateFields(value, this);
    this.setState({ value });
    value !== '' ? value = +this.roundOut(value) : value = null;
    this.updateModel(value);
  };

  clearComponent = () => {
    const _name = _.get(this.props, 'old_name')
      ? this.props.old_name.split('__')[0]
      : this.props.name;
    this.channel.subscribe(
      `${this.props.formid}:${_name}:clearComponent`,
      () => {
        /**
         * Limpiando el componente y el campo relacionado en el modelo global
         * de la pantalla
         */
        this.setState({ value: '' });
        this.props.dispatch(
          onChangeModelActions(this.props.formid, {
            name: this.props.name,
            value: '',
          })
        );
      }
    );
  };

  render() {
    let { value } = this.state;
    value = this.formatValue(this.roundOut(value) + '');

    if (this.state.show) {
      return (
        <>
          <input
            className={`form-control ${this.state.clases}`}
            name={this.props.name}
            type="text"
            value={value}
            readOnly={this.state.readOnly}
            onChange={this.handleChange}
            placeholder={this.props.placeholder || ''}
          />
          <span className="alert-form">{this.state.messageField}</span>
        </>
      );
    } else return null;
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    model: _.get(state.model, ['model', ownProps.formid], {}),
    roles: _.get(state.logged, 'logged.roles') ? state.logged.logged.roles : [],
    form: state.form.form,
    validate: _.get(state.model, 'validation', false)
  };
};

export default connect(mapStateToProps)(Number);
