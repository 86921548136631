import thunk from 'redux-thunk';
import { applyMiddleware, compose, createStore } from 'redux';
import { routerMiddleware } from 'react-router-redux';
import rootReducer from './reducers';
import { createLogger } from 'redux-logger';
import { createBrowserHistory } from 'history';
import { isProductionEnv } from '../utils/helper';

export const history = createBrowserHistory();

const initialState = {};
const enhancers = [];
const middlewares = [thunk, routerMiddleware(history)];

if (!isProductionEnv()) middlewares.push(createLogger());

const composedEnhancers = compose(applyMiddleware(...middlewares), ...enhancers);

const configureStore = () => {
  const store = createStore(rootReducer, initialState, composedEnhancers);
  return store;
};

export default configureStore;
