import { routerAxios } from '../../config/requestAxios';

export const EXTRA_FIELDS_INIT = 'EXTRA_FIELDS_INIT';
export const EXTRA_FIELDS_SUCCESS = 'EXTRA_FIELDS_SUCCESS';
export const EXTRA_FIELDS_ERROR = 'EXTRA_FIELDS_ERROR';

const eFieldsInit = () => {
  return {
    type: EXTRA_FIELDS_INIT,
    payload: {
      init: true,
    },
  };
};

const eFieldsSuccess = (data) => {
  return {
    type: EXTRA_FIELDS_SUCCESS,
    payload: {
      init: false,
      success: true,
      error: false,
      eFieldsRoute: data,
    },
  };
};

const eFieldsFileSuccess = (data) => {
  return {
    type: EXTRA_FIELDS_SUCCESS,
    payload: {
      init: false,
      success: true,
      error: false,
      eFieldsRouteFile: data,
    },
  };
};

const eFieldsError = (err) => {
  return {
    type: EXTRA_FIELDS_ERROR,
    payload: {
      init: false,
      success: false,
      error: err,
    },
  };
};

export const eFieldsRute = (url, body) => {
  return function (dispatch) {
    dispatch(eFieldsInit());
    routerAxios
      .post(url, body)
      .then((response) => dispatch(eFieldsSuccess(response.data.data)))
      .catch((err) => dispatch(eFieldsError(err)));
  };
};

export const eFieldsRouteFile = (url, body) => {
  return function (dispatch) {
    dispatch(eFieldsInit());
    routerAxios
      .post(url, body)
      .then((response) => dispatch(eFieldsFileSuccess(response.data.data)))
      .catch((err) => dispatch(eFieldsError(err)));
  };
};
