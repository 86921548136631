import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { onChangeModelActions } from '../../../store/actions/ModelActions';
import { dispatchEvents } from '../events';
import postal from 'postal';
import { attrChangeModel, initXfAndValue } from '../utils';

class Hidden extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.value || '',
      pbValue: this.props.value || null,
    };
    this.channel = postal.channel();
  }

  componentDidMount() {
    if (this.props.formid) {
      this.clearComponent();
      /**
       * Inicialización de datos del componente.
       */
      initXfAndValue(this);

      /**
       * Ejecución de los eventos adicionales del componente String
       */
      if (_.get(this.props, 'data-events')) dispatchEvents(this);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const currentModel = prevProps.model;
    const nextModel = this.props.model;
    const modelChanged = !_.isEqual(currentModel, nextModel);
    if (modelChanged && _.get(this.props, 'data-events')) {
      dispatchEvents(this);
    }
    dispatchEvents(this);
    const { value, onChange } = attrChangeModel(
      this.props.name,
      currentModel,
      nextModel,
    );
    if (onChange && this.state.value !== value) {
      this.setState({ value: value });
    }
  }

  handleChange = (e) => {
    this.setState({ value: e.target.value });
    // if(objectPath.get(this.props,"data-events")) dispatchEvents(this);
    this.props.dispatch(
      onChangeModelActions(this.props.formid, {
        name: this.props.name,
        value: e.target.value,
      }),
    );
  };

  clearComponent = () => {
    const _name = !_.isEmpty(this.props.old_name)
      ? this.props.old_name.split('__')[0]
      : this.props.name;
    this.channel.subscribe(
      `${this.props.formid}:${_name}:clearComponent`,
      (data) => {
        this.setState({ value: 'FALSE' });
      },
    );
  };

  render() {
    return (
      <input
        type="hidden"
        className={this.props.class}
        value={this.props.value}
        name={this.props.name}
        id={this.props.id}
        onChange={this.handleChange}
        disabled="disabled"
        size={this.props.size}
        maxLength={this.props.maxLength}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return { model: _.get(state.model, ['model', ownProps.formid], {}) };
};

export default connect(mapStateToProps)(Hidden);
