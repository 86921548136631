import { routerAxios } from '../../config/requestAxios';
import objectPath from 'object-path';
import { encrypt } from '../../components/utils';
import { isProductionEnv } from '../../utils/helper';

export const preferenceTypes = {
  PREF_INIT: 'PREF_INIT',
  PREF_SUCCESS: 'PREF_SUCCESS',
  PREF_TABLE_SUCCESS: 'PREF_TABLE_SUCCESS',
  PREF_ERROR: 'PREF_ERROR',
};

const DEBUG = isProductionEnv();

export const preferenceAction = (
  type,
  { data = null, init = false, success = false, error = false },
) => {
  return {
    type,
    payload: { data, init, success, error },
  };
};

export const fetchUserPreferences = (user, prefId) => {
  if (user && prefId) {
    return (dispatch) => {
      routerAxios
        .get(`/api/user_preferences?cod_user=${user}&pref_name=${prefId}`)
        .then((response) => {
          dispatch(
            preferenceAction(preferenceTypes.PREF_SUCCESS, {
              success: true,
              data: {
                [prefId]:
                  objectPath.get(response, 'data.data.0.preference', {}) || {},
              },
            }),
          );
        })
        .catch((error) => {
          dispatch(preferenceAction(preferenceTypes.PREF_ERROR, { error }));
        });
    };
  }
};

export const updateUserPreferences = (user, prefId, data) => {
  if (user && prefId) {
    let payload = { pref_name: prefId, cod_user: user, preference: data };
    return (dispatch) => {
      dispatch(preferenceAction(preferenceTypes.PREF_INIT, { init: true }));
      if (DEBUG) {
        payload = { data: encrypt(JSON.stringify(payload)) };
      }
      routerAxios
        .post('/api/user_preferences', payload)
        // .then((response) => {
        //   dispatch(
        //     preferenceAction(preferenceTypes.PREF_SUCCESS, {
        //       success: true,
        //       data: { [prefId]: data || {} },
        //     }),
        //   );
        // })
        .catch((error) => {
          dispatch(preferenceAction(preferenceTypes.PREF_ERROR, { error }));
        });
    };
  }
};

export const fetchUserPreferencesTable = (user, tableId) => {
  if (user && tableId) {
    return (dispatch) => {
      dispatch(preferenceAction(preferenceTypes.PREF_INIT, { init: true }));
      routerAxios
        .get(`/api/user_preferences?cod_user=${user}&pref_name=${tableId}`)
        .then((response) => {
          dispatch(
            preferenceAction(preferenceTypes.PREF_TABLE_SUCCESS, {
              success: true,
              data: objectPath.get(response, 'data.data.0.preference'),
            }),
          );
        })
        .catch((error) => {
          dispatch(preferenceAction(preferenceTypes.PREF_ERROR, { error }));
        });
    };
  }
};

export const updateUserPreferencesTable = (user, tableId, data) => {
  if (user && tableId) {
    let payload = { pref_name: tableId, cod_user: user, preference: data };
    return (dispatch) => {
      // dispatch(preferenceAction(preferenceTypes.PREF_INIT, { init: true }));
      if (DEBUG) {
        payload = { data: encrypt(JSON.stringify(payload)) };
      }
      routerAxios
        .post('/api/user_preferences', payload)
        .then((response) => {
          dispatch(
            preferenceAction(preferenceTypes.PREF_TABLE_SUCCESS, {
              success: true,
              data, // response.data
            }),
          );
        })
        .catch((error) => {
          dispatch(preferenceAction(preferenceTypes.PREF_ERROR, { error }));
        });
    };
  }
};

export const updateUserTheme = (user, prefId, data) => {
  if (user && prefId) {
    let payload = { pref_name: prefId, cod_user: user, preference: data };
    return (dispatch) => {
      dispatch(preferenceAction(preferenceTypes.PREF_INIT, { init: true }));
      if (DEBUG) {
        payload = { data: encrypt(JSON.stringify(payload)) };
      }
      routerAxios
        .post('/api/user_preferences/theme', payload)
        .then((response) => {
          dispatch(
            preferenceAction(preferenceTypes.PREF_SUCCESS, {
              success: true,
              data,
            }),
          );
        })
        .catch((error) => {
          dispatch(preferenceAction(preferenceTypes.PREF_ERROR, { error }));
        });
    };
  }
};
