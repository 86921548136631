import { TREE_REQUEST_ERROR, TREE_REQUEST_SUCCESS } from '../actions/TreeActions';

const initializeState = {
  init: true,
  success: false,
  error: false,
  tree: {},
};

const TreeReducers = (state = initializeState, action) => {
  switch (action.type) {
    case TREE_REQUEST_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case TREE_REQUEST_ERROR:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export default TreeReducers;
