import {
  DASHBOARD_ERROR,
  DASHBOARD_INIT,
  DASHBOARD_SUCCESS,
} from '../actions/DashboardActions';

const initializeState = {
  init: false,
  success: false,
  error: false,
  tab1: {},
  tab2: {},
  tab3: {},
  tab4: {},
};

export default function DashboardReducers(state = initializeState, action) {
  switch (action.type) {
    case DASHBOARD_INIT:
      return {
        ...state,
        ...action.payload,
      };
    case DASHBOARD_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case DASHBOARD_ERROR:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
