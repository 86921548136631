import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

class Span extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.value ? this.props.value : '',
    };
  }

  searchValue = () => {
    let value = '';
    if (Object.keys(this.props.model).length > 0) {
      Object.keys(this.props.model).forEach((item) => {
        if (item === this.props.name) {
          value = this.props.model[item];
        }
      });
    }
    return value;
  };

  render() {
    const value = this.searchValue();
    return (
      <span className={this.props.class} name={this.props.name}>
        {value}
      </span>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    model: _.get(state.model, ['model', ownProps.formid], {}),
  };
};

export default connect(mapStateToProps)(Span);
