export const APP_DESCONNECT_SUCCESS = 'APP_DESCONNECT_SUCCESS';

const appDesconnectSuccess = (data) => {
  return {
    type: APP_DESCONNECT_SUCCESS,
    payload: {
      desconnect: data,
    },
  };
};

export const AppDesconnect = (isDesconnect) => {
  return function (dispatch) {
    dispatch(appDesconnectSuccess(isDesconnect));
  };
};
