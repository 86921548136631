import _ from 'lodash';

export const parserDeleteModel = (model, { formid, data }) => {
  const oldModel = (!_.isEmpty(model.model[formid]) ? model.model[formid] : {});
  let newModel = {};
  const { name } = data;
  for (const item in oldModel) {
    if (item !== name) newModel = { ...newModel, ...{ [item]: oldModel[item] } }
  }
  return { [formid]: newModel };
}

export const parserModel = (model, { formid, data }) => {
  const oldModel = (!_.isEmpty(model.model[formid]) ? model.model[formid] : {});
  const newModel = { [formid]: {} };
  let { name, value, ...assign } = data;
  if (value === undefined) value = '';
  if (_.get(data.value, 'name')) {
    const _name = [data.name];
    let current = {};
    if (oldModel[_name] === undefined) {
      newModel[formid][_name] = {};
    } else current = oldModel[_name];
    newModel[formid][_name] = { ...current, ...{ [data.value.name]: data.value.value } }
  } else if (!_.isEmpty(name)) {
    let update = false;
    for (const i in oldModel) {
      if (!_.isEmpty(oldModel[i])) {
        if (typeof (oldModel[i]) === 'object' && i !== '_model') {
          _.keys(oldModel[i]).forEach(item => {
            if (item === data.name) {
              update = true;
              const current = oldModel[i];
              const _value = { ...current, ...{ [data.name]: data.value } }
              newModel[formid][i] = _value;
            }
          });
        }
      }
    }
    if (update === false) newModel[formid][name] = value;
  }
  return { ...oldModel, ...newModel[formid], ...assign };
};

export const parserFilters = (state, { formid, data }) => {
  let { name, value } = data;
  value = value.length ? `%${value[0].toUpperCase()}%` : ''
  const oldModel = (!_.isEmpty(state.filters[formid]) ? state.filters[formid] : {});
  const newModel = { [formid]: {} };
  if (value === undefined) value = '';
  newModel[formid][name] = value;
  return { ...oldModel, ...newModel[formid] };
}

export const parserListFilters = (state, { formid, data }) => {
  const newModel = { [formid]: {} };
  const { name, value } = data;
  newModel[formid] = { [name]: value };
  return { ...newModel[formid] };
}
