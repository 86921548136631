import axios from 'axios';

const REACT_APP_API_URL = process.env.REACT_APP_API_URL || '';

const server = REACT_APP_API_URL + '/ible';
const requestAxios = axios.create({
  baseURL: server,
  responseType: 'json',
});

const pdfRequest = axios.create({
  baseURL: REACT_APP_API_URL + '/',
  responseType: 'blob',
});

const router = '/router';
const routerAxios = axios.create({
  baseURL: REACT_APP_API_URL + router,
  responseType: 'json',
});

const filemanager = '/arch';
const authAxios = axios.create({
  baseURL: REACT_APP_API_URL + filemanager,
  responseType: 'json',
});

requestAxios.defaults.headers.post['Content-Type'] =
  'application/json';
routerAxios.defaults.headers.post['Content-Type'] =
  'application/json';
authAxios.defaults.headers.post['Content-Type'] =
  'application/x-www-form-urlencoded';
pdfRequest.defaults.headers.post['Content-Type'] =
  'application/json';

const updateAxios = (callback) => {
  requestAxios.defaults.headers.Authorization = localStorage.getItem('enp_id');
  routerAxios.defaults.headers.Authorization = localStorage.getItem('enp_id');
  authAxios.defaults.headers.Authorization = localStorage.getItem('enp_id');
  pdfRequest.defaults.headers.Authorization = localStorage.getItem('enp_id');
  callback();
};

const deleteAuthorizationAxios = (callback) => {
  authAxios.defaults.headers.Authorization = '';
  callback();
};

export {
  requestAxios,
  pdfRequest,
  routerAxios,
  updateAxios,
  server,
  router,
  authAxios,
  deleteAuthorizationAxios,
};
