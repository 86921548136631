import { requestAxios } from '../../config/requestAxios';

export const EVENT_FIELD_INIT = 'EVENT_FIELD_INIT';
export const EVENT_FIELD_SUCCESS = 'EVENT_FIELD_SUCCESS';
export const EVENT_FIELD_ERROR = 'EVENT_FIELD_ERROR';

const eventFieldInit = () => {
  return {
    type: EVENT_FIELD_INIT,
    payload: {
      init: true,
      error: false,
      succes: false,
    },
  };
};

const eventFieldSuccess = (alias, data) => {
  return {
    type: EVENT_FIELD_SUCCESS,
    payload: {
      init: false,
      success: true,
      error: false,
      eventField: {
        [alias]: data,
      },
    },
  };
};

const eventFieldError = (err) => {
  return {
    type: EVENT_FIELD_ERROR,
    payload: {
      init: false,
      success: false,
      error: err,
    },
  };
};

export const requestEventField = (alias, params) => {
  return function (dispatch) {
    dispatch(eventFieldInit());
    requestAxios
      .post('/lists/' + alias, params)
      .then((response) => {
        dispatch(eventFieldSuccess(alias, response.data));
      })
      .catch((error) => {
        dispatch(eventFieldError(error));
      });
  };
};
