import {
  RECOVER_PASS_ERROR,
  RECOVER_PASS_INIT,
  RECOVER_PASS_SUCCESS,
} from '../actions/RecoverPasswordActions';

const initializeState = {
  init: false,
  success: false,
  error: false,
  recoverpass: {},
};

const recoverPasswordReducers = (state = initializeState, action) => {
  switch (action.type) {
    case RECOVER_PASS_INIT:
      return {
        ...state,
        ...action.payload,
      };
    case RECOVER_PASS_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case RECOVER_PASS_ERROR:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export default recoverPasswordReducers;
