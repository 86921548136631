import React, { Component, Suspense } from 'react';
import { connect } from 'react-redux';

import { v1 as uuid } from 'uuid';
import postal from 'postal';
import _ from 'lodash';
import $ from 'jquery';

import { isEmptyObject, logger, renderClass } from '../../utils/helper';
import { getPage } from '../../utils/cache';
import { equalRegEx } from '../GridNew/helper';
import { makeid } from '../../utils/utils';

// import SimpleTable from '../SimpleTable/SimpleTable';
import Container from '../Common/Container/Container';

import String from '../Common/String/String';
import FieldSet from '../Common/FieldSet/FieldSet';
import Number from '../Common/Number/Number';
import Email from '../Common/Email/Email';
import Password from '../Common/Password/Password';
import Range from '../Common/Range/Range';
import CheckBox from '../Common/Checkbox/Checkbox';
import TextAreaDesign from '../Common/TextArea/TextArea';
import DateTime from '../Common/DateTime/DateTime';
import Time from '../Common/Time/Time';
import Date from '../Common/Date/Date';
import RichText from '../Common/RichText/RichText';
import WebButton from '../Common/Button/WebButton';
import Span from '../Common/Span/Span';
import MaskedInput from '../Common/MaskInput/MaskInput';
import ExtraFields from '../ExtraFields/ExtraFields';
import TabSimple from '../TabSimple/TabSimple';
import Hidden from '../Common/Hidden/Hidden';
import Repeater from '../Common/Repeater/Repeater';
import Label from '../Common/Label/Label';
import QueryParams from '../QueryParams';

const MultiTab = React.lazy(() => import('../MultiTab/MultiTab'));
const ExcelUploader = React.lazy(() => import('../ExcelUploader/ExcelUploader'));
const PDFgenerator = React.lazy(() => import('../PDFgenerator/PDFgenerator'));
const DivWin = React.lazy(() => import('../DivWin/DivWin'));
const FileManager = React.lazy(() => import('../FileManager'));
const Dashboard = React.lazy(() => import('../../pages/Dashboard'));
const DashboardTable = React.lazy(() => import('../../pages/DashboardTable'));
const DataWrapper = React.lazy(() => import('../DataWrapper/DataWrapper'));
const Mapa = React.lazy(() => import('../Mapa/NewMap'));
const CardList = React.lazy(() => import('../CardList/CardList'));
const SimpleForm = React.lazy(() => import('../Form/Form'));
const KanBan = React.lazy(() => import('../KanBan/KanBan'));
const GanttComponent = React.lazy(() => import('../Gantt/Gant'));

const notEmptyObject = (obj) => obj && Object.keys(obj).length > 0;

const undefinedComponent = (type) => {
  logger(`!- No se pudo renderizar el componente: ${type}.`);
  return null;
};

const containers = ['container'];

const isContainer = (type) => containers.includes(type);

const prueba = [];
const igual = [];

class MountComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pantalla: {},
      web724Components: {},
      formid: null,
      erroresTotales: [],
      isRepeaterComponent: _.get(this.props, 'isRepeaterComponent', false),
      isExtraFieldComponent: _.get(this.props, 'isExtraFieldComponent', false),
    };
    this.tituloPantalla = '';
    this.channel = postal.channel();
    this.dashboardExists = false;
    this.dashboardTableExists = false;
  }

  fnBuildMenu = async (props) => {
    if (props.externalPantalla) {
      const model = props.model ? props.model : null;
      this.fnScreen(props.externalPantalla, model, props.ischeck);
    } else {
      let menu = props.menu.menu;
      const page = await getPage();
      menu = menu.concat(page);
      const exPath = props.externalPath || _.get(props, 'location.pathname');
      let menuItem = null;
      const model = props.model ? props.model : null;
      menu.forEach((element) => {
        if (element) {
          let { path, pantalla, children, page } = element;
          path = path ? path.split('#')[0] : path;
          page = page ? page.split('#')[0] : page;
          if (path === exPath || (page === exPath && !isEmptyObject(pantalla))) {
            menuItem = element;
          } else if (children.length > 0) {
            children.forEach((item) => {
              const path = item.path ? item.path.split('#')[0] : item.path;
              if (path === exPath && !isEmptyObject(item.pantalla)) {
                menuItem = item;
              }
            });
          }
        }
      });
      return this.fnScreen(menuItem, model, props.ischeck);
    }
  };

  componentDidMount() {
    $('#container').hide();
    this.fnBuildMenu(this.props);
    $('#container').show();

    if ($('.ant-tabs-tabpane').length) {
      setTimeout(function () {
        if ($('#gridContainer').length && $('.ant-tabs-tabpane-active').length) {
          const active = $('.ant-tabs-tabpane-active').height() + 65;
          $('#gridContainer').removeClass('complex').addClass('tabla-tab');
          $('.tabla-tab').css('height', active);
        }
        if ($('.ant-tabs-tabpane-active').find('.cards').length > 0) {
          $('.contenedorPb > .ant-tabs').addClass('tab-card');
        } else $('.contenedorPb > .ant-tabs').removeClass('tab-card');
      }, 1000);
    }

    // DELETE EMPTY DIVS
    $('.divcontainer > div, .detalle-tab > div, .div-repeat div').each(function () {
      const self = $(this);
      if (self.html().trim().length === 0) {
        self.remove();
      }
    });
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    this.fnBuildMenu(newProps);
  }

  fnScreen = (initItem, model, ischeck = false) => {
    if (initItem) {
      const item = this.props.formid
        ? {
            ...initItem,
            props: {
              ...initItem.props,
              formid: this.props.formid,
              model: model,
              ischeck,
            },
          }
        : initItem;
      this.tituloPantalla = item.text;
      this.setState({
        pantalla: item.pantalla,
        web724Components: {
          form: (item) => this.fnSimpleForm(item),
          table: (item) => {
            const detectClass = item.props.class.find(function (element) {
              return element === 'simple' || element === 'calendar';
            });
            return detectClass !== undefined
              ? detectClass !== 'calendar' ? (
              <Suspense key={makeid(12)} fallback={<div></div>}>
                <KanBan {...renderClass(item.props)} item={item} />
              </Suspense>
              ) : (
              <Suspense key={makeid(12)} fallback={<div></div>}>
                <GanttComponent {...renderClass(item.props)} item={item} />
              </Suspense>
              )
              : (
              <Suspense key={makeid(12)} fallback={<div></div>}>
                <DataWrapper {...renderClass(item.props)} />
              </Suspense>
                )
            ;
          },
          card: (item) => (
            <Suspense fallback={<div></div>}>
              <CardList {...renderClass(item.props)} />
            </Suspense>
          ),
          dashboard: (item) => {
            const detectClass = item.props.class.find(function (element) {
              return element === 'dashboardtable';
            });
            this.dashboardExists = true;
            return detectClass !== undefined ? (
              <Suspense fallback={<div></div>}>
                <DashboardTable key={this.props.formid} item={item} {...renderClass(item.props)} id={'dsboard'} />
              </Suspense>
            ) : (
              <Suspense fallback={<div></div>}>
                <Dashboard key={this.props.formid} item={item} {...renderClass(item.props)} id={'dsboard'} />
              </Suspense>
            );
          },
          dashboardtable: (item) => {
            this.dashboardTableExists = true;
            return (
              <Suspense fallback={<div></div>}>
                <DashboardTable key={this.props.formid} {...renderClass(item.props)} id={'dsboard'} />
              </Suspense>
            );
          },
          multitab: (item) =>
            item.props['data-subscribe'] ? null : (
              <Suspense fallback={<div></div>}>
                <MultiTab {...renderClass(item.props)} />
              </Suspense>
            ),
          textarea: (item) => (
            <TextAreaDesign
              {...renderClass(item.props)}
              validar={this.props.validaciones}
              errores={this.props.errores}
              updateParent={this.updateParent.bind(this)}
              isRepeaterComponent={this.state.isRepeaterComponent}
              isExtraFieldComponent={this.state.isExtraFieldComponent}
            />
          ),
          html: (item) => this.fnHtml(item),
          divwin: (item) => <Suspense fallback={<div></div>}>{this.fnDivWin(item)}</Suspense>,
          text: (item) => (
            <String
              {...renderClass(item.props)}
              errores={this.props.errores}
              updateParent={this.updateParent.bind(this)}
              validar={this.props.validaciones}
              isRepeaterComponent={this.state.isRepeaterComponent}
              isExtraFieldComponent={this.state.isExtraFieldComponent}
            />
          ),
          label: (item) => <Label {...{ ...item, ...{ key: uuid() } }} />,
          number: (item) => (
            <Number
              {...renderClass(item.props)}
              validar={this.props.validaciones}
              errores={this.props.errores}
              updateParent={this.updateParent.bind(this)}
              isRepeaterComponent={this.state.isRepeaterComponent}
              isExtraFieldComponent={this.state.isExtraFieldComponent}
            />
          ),
          email: (item) => (
            <Email
              {...renderClass(item.props)}
              validar={this.props.validaciones}
              errores={this.props.errores}
              updateParent={this.updateParent.bind(this)}
              isRepeaterComponent={this.state.isRepeaterComponent}
              isExtraFieldComponent={this.state.isExtraFieldComponent}
            />
          ),
          range: (item) => (
            <Range
              {...renderClass(item.props)}
              validar={this.props.validaciones}
              errores={this.props.errores}
              updateParent={this.updateParent.bind(this)}
              isRepeaterComponent={this.state.isRepeaterComponent}
              isExtraFieldComponent={this.state.isExtraFieldComponent}
            />
          ),
          password: (item) => (
            <Password
              {...renderClass(item.props)}
              validar={this.props.validaciones}
              errores={this.props.errores}
              updateParent={this.updateParent.bind(this)}
              isRepeaterComponent={this.state.isRepeaterComponent}
              isExtraFieldComponent={this.state.isExtraFieldComponent}
            />
          ),
          maskinput: (item) => <MaskedInput {...renderClass(item.props)} />,
          datetime: (item) => (
            <DateTime
              {...renderClass(item.props)}
              errores={this.props.errores}
              updateParent={this.updateParent.bind(this)}
              validar={this.props.validaciones}
              isRepeaterComponent={this.state.isRepeaterComponent}
              isExtraFieldComponent={this.state.isExtraFieldComponent}
            />
          ),
          time: (item) => (
            <Time
              {...renderClass(item.props)}
              validar={this.props.validaciones}
              errores={this.props.errores}
              updateParent={this.updateParent.bind(this)}
              isRepeaterComponent={this.state.isRepeaterComponent}
              isExtraFieldComponent={this.state.isExtraFieldComponent}
            />
          ),
          date: (item) => (
            <Date
              {...renderClass(item.props)}
              validar={this.props.validaciones}
              errores={this.props.errores}
              updateParent={this.updateParent.bind(this)}
              isRepeaterComponent={this.state.isRepeaterComponent}
              isExtraFieldComponent={this.state.isExtraFieldComponent}
            />
          ),
          checkbox: (item) => (
            <CheckBox
              {...renderClass(item.props)}
              validar={this.props.validaciones}
              errores={this.props.errores}
              updateParent={this.updateParent.bind(this)}
              isRepeaterComponent={this.state.isRepeaterComponent}
              isExtraFieldComponent={this.state.isExtraFieldComponent}
            />
          ),
          richtext: (item) => <RichText {...renderClass(item.props)} />,
          button: (item) => <WebButton {...renderClass(item.props)} handleOnClick={this.props.handleOnClick} />,
          span: (item) => <Span {...renderClass(item.props)} />,
          extrafields: (item) => <ExtraFields {...renderClass(item.props)} validar={this.props.validaciones} />,
          tabsimple: (item) => <TabSimple {...Object.assign(item, { key: uuid() })} />,
          fieldset: (item) => <FieldSet {...item} />,
          uploader: (item) => (
            <Suspense fallback={<div></div>}>
              <ExcelUploader {...item.props} />
            </Suspense>
          ),
          filemanager: (item) => (
            <Suspense fallback={<React.Fragment />}>
              <FileManager {...{ ...item }} />
            </Suspense>
          ),
          hidden: (item) => {
            switch (item.props.variant) {
              case 'getQueryStringsData':
                return <QueryParams {...item.props} />;
              default:
                return <Hidden {...item.props} />;
            }
          },
          mapa: (item) => (
            <Suspense fallback={<div></div>}>
              <Mapa {...item.props} key={'map'} />
            </Suspense>
          ),
          pdfgen: (item) => (
            <Suspense fallback={<div></div>}>
              <PDFgenerator {...item.props} />
            </Suspense>
          ),
        },
      });
    }
  };

  traverse = (obj, formid = this.props.formid) => {
    if (notEmptyObject(obj) && obj.props && obj.props.type) {
      const newObj = formid ? { ...obj, props: { ...obj.props, formid } } : obj;
      const RenderComponent = this.state.web724Components[newObj.props.type];
      if (RenderComponent) {
        return RenderComponent(newObj);
      } else if (isContainer(newObj.props.type)) {
        if (equalRegEx('true').test(newObj.props.repeat)) {
          return (
            <Repeater
              {...renderClass(newObj.props)}
              pantalla={newObj}
              validar={this.props.validaciones}
              errores={this.props.errores}
            />
          );
        } else {
          return newObj.children && newObj.children.length > 0 ? (
            <Container {...renderClass(newObj.props)}>
              {newObj.children ? newObj.children.map((child) => this.traverse(child, newObj.props.formid)) : null}
            </Container>
          ) : null;
        }
      } else return undefinedComponent(obj.props.type);
    } else return null;
  };

  fnSimpleForm = ({ props, children }) => {
    let formid = '';
    if (this.props.formid !== undefined) formid = props.formid;
    else formid = uuid() + '.' + props.key;
    const form = (
      <SimpleForm
        {...renderClass(props)}
        model={this.props.model}
        formid={formid}
        fields={children}
        ischeck={_.get(this.props, 'ischeck', false)}
      />
    );
    return equalRegEx('true').test(props['data-comp_agrupados']) ? (
      <MultiTab
        key={formid}
        formid={formid}
        // initTab={{ title: props.title || "Listado", content: form }}
        initTab={{
          title: 'Listado',
          content: {
            ...renderClass(props),
            model: this.props.model,
            formid,
            fields: children,
          },
        }}
        subscription={true}
      />
    ) : (
      form
    );
  };

  fnHtml = ({ props }) => {
    const _html = props.html.trim();
    if (_html !== '') {
      return <div key={uuid()} dangerouslySetInnerHTML={{ __html: props.html }} />;
    } else return null;
  };

  fnDivWin = ({ props }) => (
    <DivWin
      {...renderClass(props)}
      handleChange={''}
      validar={this.props.validaciones}
      errores={this.props.errores}
      isRepeaterComponent={this.state.isRepeaterComponent}
      isExtraFieldComponent={this.state.isExtraFieldComponent}
      updateParent={this.updateParent.bind(this)}
    />
  );

  updateParent = (value) => {
    if (Object.keys(value)[0] === 'sumar') prueba.push(Object.values(value)[0]);
    else if (Object.keys(value)[0] === 'restar') {
      prueba.splice(prueba.indexOf(Object.values(value)[0]), 1);
    } else if (Object.keys(value)[0] === 'igual') {
      igual.push(Object.values(value)[0]);
    }

    if (this.props.updateGrandparent) {
      if (Object.keys(prueba).length === 0) {
        if (Object.keys(igual).length === 0) {
          this.props.updateGrandparent(0);
        } else {
          this.props.updateGrandparent(2);
        }
      } else {
        this.props.updateGrandparent(1);
      }
    }
  };

  render() {
    this.dashboardExists = false;
    const result = this.traverse(this.state.pantalla);
    return result;
  }
}

const mapStateToPropsState = (state) => {
  return {
    menu: state.menu,
    logged: state.logged,
  };
};

export default connect(mapStateToPropsState)(MountComponent);
