import _ from 'lodash';

export const attrChangeModel = (eventofield, currentModel, nextModel) => {
  let attrChangeForm = false;
  let value = '';
  for (const i in nextModel) {
    if (typeof nextModel[i] === 'object') {
      _.keys(nextModel[i]).forEach((item) => {
        if (
          eventofield === item &&
          !_.isEmpty(currentModel[i]) &&
          !_.isEmpty(nextModel[i])
        ) {
          if (nextModel[i][item] !== currentModel[i][item]) {
            attrChangeForm = true;
            value = nextModel[i][item];
          }
        }
      });
    } else if (eventofield === i) {
      if (nextModel[i] !== currentModel[i]) {
        attrChangeForm = true;
        value = nextModel[i];
      }
    }
  }
  return { attrChangeForm: attrChangeForm, value: value };
};
