import React, { Component } from 'react';
import { Checkbox } from 'antd';
import postal from 'postal';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { requestEventField } from '../../../store/actions/EventFieldActions';
import _ from 'lodash';
import {
  initXfAndValue,
  attrChangeModel,
  validateFields,
  validateXfValue,
  readOnly,
} from '../utils';
import { onChangeModelActions } from '../../../store/actions/ModelActions';
import { dispatchEvents, hasEventChanged, setModelValidation } from '../events';
import { v1 as uuid } from 'uuid'

export const equalRegEx = (str) => new RegExp('^' + str + '$', 'i');
class CheckBox extends Component {
  static propTypes = {
    name: PropTypes.string,
    title: PropTypes.string,
    value: PropTypes.string,
  };

  static defaultProps = {
    value: 'FALSE',
  };

  constructor(props) {
    super(props);
    this.state = {
      value: props.value,
      key: uuid(),
      readOnly: readOnly(this.props),
      errores: 0,
      validateXf: false,
    };
    this.channel = postal.channel();
    this.timer = null;
  }

  publishChange = (value) => {
    /**
     * Actualización del modelo global desde aqui
     */
    this.props.dispatch(
      onChangeModelActions(this.props.formid, {
        name: this.props.name,
        value: value,
      }),
    );
    if (_.get(this.props, 'data-fieldaction')) {
      const params = { IBLE_VALOR_ACTUAL: value, [this.props.name]: value };
      this.props.dispatch(
        requestEventField(this.props['data-fieldaction'], {
          ...this.props.model,
          ...params,
        }),
      );
    }
  };

  onChange = (e) => {
    const value = e.target.checked ? 'TRUE' : 'FALSE';
    this.setState({ value });
    this.publishChange(value);
  };

  clearComponent = () => {
    const _name = _.get(this.props, 'old_name')
      ? this.props.old_name.split('__')[0]
      : this.props.name;
    this.channel.subscribe(`${this.props.formid}:${_name}:clearComponent`, () => {
      /**
       * Limpiando el componente y el campo relacionado en el modelo global
       * de la pantalla
       */
      this.setState({ value: 'FALSE' });
      this.props.dispatch(
        onChangeModelActions(this.props.formid, {
          name: this.props.name,
          value: 'FALSE',
        }),
      );
    });
  };

  componentDidMount() {
    if (this.props.name === '') {
      this.showNotification(
        'El componenente \n' +
          JSON.stringify(this.props) +
          '\n no tiene el campo name',
      );
    }
    if (this.props.formid) {
      setModelValidation(this);
      /**
       * Función que limpia el componente. Dejará de existir
       */
      this.clearComponent();

      this.channel.subscribe(
        `${this.props.formid}:${this.props.name}:hidden`,
        (data) => {
          this.setState({ readOnly: data.v });
        },
      );

      /**
       * Inicialización de datos del componente.
       */
      initXfAndValue(this);

      /**
       * Ejecución de los eventos adicionales del componente String
       */
      if (_.get(this.props, 'data-events')) dispatchEvents(this);
    }
  }

  showNotification = (message) => {
    this.channel.publish('notification', [
      { tipo: 'error', titulo: 'Error', data: message },
    ]);
  };

  componentDidUpdate(prevProps, prevState) {
    const currentModel = prevProps.model;
    const nextModel = this.props.model;

    if (hasEventChanged(this.props, currentModel, nextModel)) dispatchEvents(this);

    const { value, onChange } = attrChangeModel(
      this,
      this.props.name,
      currentModel,
      nextModel,
    );
    if (onChange && this.state.value !== value) {
      this.setState({ value: value });
    } else validateXfValue(this, nextModel, value);

    if (prevProps.validar !== this.props.validar && this.props.validar === 'validar') { validateFields(this.state.value, this); }
    if (prevState.errores !== this.state.errores && this.state.errores === 1) { this.props.updateParent({ sumar: this.props.name }); } else if (prevState.errores !== this.state.errores && this.state.errores === 0) { this.props.updateParent({ restar: this.props.name }); } else if (
      prevProps.validar !== this.props.validar &&
      this.props.validar === 'validar' &&
      prevState.errores === this.state.errores &&
      this.state.errores === 0
    ) {
      this.props.updateParent({ igual: 1 });
    }

    // if(this.props.modelRequired.includes(this.props.name)){
    //  setModelValidation(this, !this.state.readOnly);
    // }
  }

  /** Si el componente es un XF y se desmonta tambien se quita de los requeridos */
  componentWillUnmount() {
    if (_.get(this.props, 'isExtraFieldComponent') && _.get(this.props, 'required', false)) {
      setModelValidation(this, false)
    }
  }

  handleBlur = () => {
    this.channel.publish(`${this.props.name}:onBlur`, this.state.value);
  };

  render() {
    return (
      <>
        <Checkbox
          key={this.state.key}
          className={this.props.class}
          checked={equalRegEx('true').test(this.state.value)}
          indeterminate={equalRegEx(null).test(this.state.value)}
          disabled={this.state.readOnly}
          onChange={this.onChange}
          id={this.props.name}
        >
          {this.props.title}
        </Checkbox>
        <span className="alert-form"></span>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    eventField: state.eventField,
    model: _.get(state.model, ['model', ownProps.formid], {}),
    form: state.form,
  };
};

export default connect(mapStateToProps)(CheckBox);
