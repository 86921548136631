import postal from 'postal';
import { authAxios } from '../../config/requestAxios';

export const CREATE_FOLDER_INIT = 'CREATE_FOLDER_INIT';
export const CREATE_FOLDER_SUCCESS = 'CREATE_FOLDER_SUCCESS';
export const CREATE_FOLDER_ERROR = 'CREATE_FOLDER_ERROR';

const channel = postal.channel();
const createFolderInit = () => {
  return {
    type: CREATE_FOLDER_INIT,
    payload: {
      init: true,
    },
  };
};

const createFolderSuccess = (data) => {
  return {
    type: CREATE_FOLDER_SUCCESS,
    payload: {
      init: false,
      success: true,
      error: false,
      folder: data,
    },
  };
};

const createFolderError = (err) => {
  return {
    type: CREATE_FOLDER_ERROR,
    payload: {
      init: false,
      success: false,
      error: err,
    },
  };
};

export const createFolder = (data) => {
  return function (dispatch) {
    dispatch(createFolderInit());
    authAxios
      .post('/folder', data)
      .then((response) => {
        const result = response.data;
        channel.publish('createFolder');
        dispatch(createFolderSuccess(result));
      })
      .catch((err) => {
        dispatch(createFolderError(err));
      });
  };
};
