import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import objectPath from 'object-path';
import postal from 'postal';
import _ from 'lodash';
import Modal from '../../Modal/Modal';
import { onChangeModelActions } from '../../../store/actions/ModelActions';
import { mountPantalla } from '../../GridNew/helper';

class WebButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message:
        this.props.msgpage && typeof this.props.msgpage === 'string'
          ? this.props.msgpage
          : this.props.msgpage && typeof this.props.msgpage === 'object'
            ? this.props.msgpage.join(' ')
            : 'Confirmar',
      icon: this.props.icon || 'fa fa-check',
      disabled: props.disabled || false,
      showModal: false,
      formInit: true,
    };
    this.channel = postal.channel();
  }

  hideOrDisplayComponent = (newProps) => {
    if (
      objectPath.get(newProps.form, 'form.acciones.acciones_alert') !== undefined
    ) {
      this.setState({ disabled: false, formInit: true });
    }
  };

  componentDidUpdate(newProps) {
    if (this._ismounted) this.hideOrDisplayComponent(newProps);
  }

  handleOnClick = (evt) => {
    if (!_.get(this.props, 'button_link') === false) {
      const link = { pantalla: this.props.button_link };
      const formid = this.props.formid;
      const isnew = true;
      let model = this.props.model;
      if (isnew) {
        this.props.dispatch(
          onChangeModelActions(this.props.formid, {
            name: 'SUBMIT_ACTION',
            value: 'INSERT',
          }),
        );
      } else {
        model = { ...model, ...{ SUBMIT_ACTION: 'UPDATE' } };
      }
      const _modalChildren = mountPantalla(formid, link.pantalla, model);
      this.setState({
        showModal: true,
        modelModal: model,
        modalChildren: _modalChildren,
      });
    } else {
      this.channel.publish(`${this.props.formid}:OnClick`, {
        props: this.props,
      });
      if (this.props.handleOnClick !== undefined) {
        /**
         * Poniendo por defecto grisado el botón
         */
        this.setState({ disabled: true }, () => {
          this.props.handleOnClick(evt);
        });
        /**
         * Ejecutando la funcion para el refresh del listado del dashboard.
         */
        this.channel.publish('refreshDashList')
      }
    }
  };

  componentDidMount() {
    /**
     * Si existe algún valor requerido sin llenar en la pantalla
     * se rechaza y desbloquea el botón.
     */
    this.channel.subscribe('disabledButton', () => {
      if (this.state.formInit) this.setState({ disabled: false });
    });
    this.channel.subscribe('FORM:INIT', () => {
      this.setState({ formInit: false, disabled: true });
    });
    this.channel.subscribe('REQUEST:END', () => {
      this.setState({ disabled: false, formInit: true });
    });
  }

  render() {
    return (
      <>
        <Modal
          className="modal-providers"
          open={this.state.showModal}
          onBack={() => {
            this.setState({ showModal: false });
          }}
        >
          {this.state.modalChildren}
        </Modal>
        <Button
          className={this.props.class}
          disabled={this.state.disabled}
          bsstyle="primary"
          type="submit"
          onClick={this.handleOnClick}
        >
          {this.state.message}
        </Button>
      </>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    form: state.form,
    model: _.get(state.model, ['model', ownProps.formid], {}),
  };
};

export default connect(mapStateToProps)(WebButton);
