import React, { Component, Fragment } from 'react';
import { Navigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { Steps, Tabs } from 'antd';
import 'antd/dist/antd.css';
import MountComponent from '../MountComponent/MountComponent';
import postal from 'postal';
import { dispatchEvents } from '../Common/events';
import ButtonDesignSubmit from '../Common/Button/ButtonSubmit';
import { equalRegEx } from '../GridNew/helper';
import _ from 'lodash';
import { mergeDataTips, buildTabsPane } from './utils';
import { v1 as uuid } from 'uuid'
const { Step } = Steps;

class TabSimple extends Component {
  constructor(props) {
    super(props);
    this.channel = postal.channel();
    this.state = {
      params: null,
      mode: props.props['data-tab_position'] || 'left',
      tabPane: false,
      componentTabs: [],
      listTabs: [],
      searchData: false,
      components: [],
      currentTab: 0,
      submit: {},
      submited: false,
      clicked: false,
      isStep: equalRegEx('true').test(_.get(props, 'props.data-step')),
    };
    this.keyTabs = [];
  }

  next = (states = {}) => {
    this.channel.publish('simpleTabChange');
    this.setState((prevState) => ({
      ...prevState,
      currentTab: prevState.currentTab + 1,
      ...states,
    }));
  };

  prev = () =>
    this.setState((prevState) => ({
      ...prevState,
      currentTab: prevState.currentTab - 1,
    }));

  componentDidMount() {
    const buildTabPanel = this.buildTasPanel();
    this.setState({
      componentTabs: buildTabPanel.componentTabs,
      listTabs: buildTabPanel.listTabs,
      components: buildTabPanel,
    });

    /*
     * Este evento no es de modelo, se emplea en el paso a paso.
     */
    this.channel.subscribe(`${this.props.props.formid}:submit`, (data) => {
      this.setState({ submit: data, clicked: false, submited: false });
    });

    /*
     * Este evento no es de modelo, se mantiene.
     */
    if (this.state.isStep) {
      this.channel.subscribe('notification', (data) => {
        const curTabContent = _.get(this.state, [
          'components',
          'listTabs',
          this.state.currentTab,
        ]);
        const key = _.get(curTabContent, 'key');
        const filteredData = (_.isArray(data) ? data : []).filter(
          (e) => _.get(e, 'UUID') === key,
        );
        const [ToNextStep] = (_.isArray(data) ? data : []).map((e) => _.get(e, 'submit', false))
        /*
          * Cuando se ejecuta un proceso la uuid nos llega como null entonces se hace un filtrado
          * en base a las alertas que devolvio el proceso que se ejecuto
          * y si no se encuentra un warning se avanza al siguiente paso
          * Ademas se agrego una propiedad al boton del formulario que cuando es un proceso,
          * nos devuelve una propiedad para que valide en base a el boton
           */
        const AlertsInData = data
          .map((e) => _.get(e, 'tipo'))
          .filter((e) => e !== 'alert');
        const Alerts = !_.isEmpty(AlertsInData)
        if (!_.isEmpty(filteredData)) {
          const errors = filteredData
            .map((e) => _.get(e, 'tipo'))
            .filter((e) => e !== 'alert');
          const submitError = !_.isEmpty(errors);

          if (!submitError) {
            this.next({ submited: true });
            // this.setState({ submited: false });
          }
        } else if (!Alerts && ToNextStep) this.next({ submited: true });
        this.channel.publish('disabledButton');
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const currentModel = prevProps.model;
    const nextModel = this.props.model;
    const modelChanged = !_.isEqual(currentModel, nextModel);
    if (modelChanged) {
      buildTabsPane(this);
    }
  }

  _events = (valores_formulario, key, tabList) => {
    const _valores_form = valores_formulario.split(',');
    const arrayTabsClear = [];
    if (_valores_form.length > 0) {
      _valores_form.map((item) => {
        /*
         * Evento que se emite en el componente de los eventos comunes
         */
        this.channel.subscribe(`${item}:${key}:showWhen`, (data) => {
          if (!_.isEqual(this.props.props.formid, data.formid)) return
          const arrayTabs = tabList || _.get(this.state, 'listTabs', []);
          const stateTabs = [];
          arrayTabs.map((cmp) => {
            const _val_form =
              _.get(cmp, 'data-valores_formulario') !== undefined
                ? cmp['data-valores_formulario'].split(',')
                : [];
            if (_val_form.indexOf(item) < 0 && arrayTabsClear.indexOf(cmp.key) < 0) {
              stateTabs.push(cmp);
            } else {
              if (data.show === true && _val_form.length > 0) {
                const _cmp = cmp;
                _cmp.show = true;
                stateTabs.push(cmp);
                arrayTabsClear.push(cmp.key);
              } else if (data.show === false && _val_form.length > 0) {
                const _cmp = cmp;
                _cmp.show = false;
                stateTabs.push(cmp);
                arrayTabsClear.push(cmp.key);
              }
            }
            return null;
          });
          this.setState({ listTabs: stateTabs });
        });

        return null;
      });
    }
  };

  buildTasPanel = () => {
    let totalComponents = {};
    if (this.props.children) {
      const cmpTabs = [];
      const cmplistTabs = [];
      this.props.children.map((item) => {
        if (item.props.type === 'newtabpane') {
          const _menuTab = {
            tab: item.props.text,
            key: item.props.key,
            hidden: false,
            submit: equalRegEx('true').test(item.props.submit),
            show: true,
          };
          cmpTabs.push(_menuTab);
          if (_.get(item, 'props.' + ['data-events'])) {
            _menuTab['data-events'] = item.props['data-events'];
            _menuTab['data-valores_formulario'] =
              item.props['data-valores_formulario'];
            _menuTab.formid = this.props.props.formid;
            this._events(
              item.props['data-valores_formulario'],
              _menuTab.key,
              cmpTabs,
            );
            if (_.get(item, 'props.data-events')) {
              _menuTab.tabsimple = true;
              dispatchEvents(this, _menuTab);
            }
          }

          // that.keyTabs.push(_menuTab.key);
          const _key = uuid();
          const tabspane = {
            pantalla: {
              props: {
                type: 'container',
                key: _key,
              },
              children: item.children.children,
            },
          };
          const component = (
            <div className="templateTest col-12 col-xl-12">
              <MountComponent
                externalPantalla={tabspane}
                key={uuid()}
                formid={this.props.props.formid}
              />
            </div>
          );
          cmplistTabs.push(component);
          totalComponents = {
            listTabs: cmpTabs,
            componentTabs: cmplistTabs,
          };
        } else if (item.props.type === 'tabpane') {
          this.props.children.map((pane) => {
            if (
              item.props.key !== pane.props.key &&
              pane.props.type === 'tabpane' &&
              item.props.text !== pane.props.text &&
              mergeDataTips(item.props['data-tips']) ===
              mergeDataTips(pane.props['data-tips']) &&
              item.props.text
            ) {
              const _menuTab = {
                tab: item.props.text,
                key: item.props.key,
                hidden: false,
                show: true,
              };
              cmpTabs.push(_menuTab);
              if (_.get(item, 'props.data-events')) {
                _menuTab['data-events'] = item.props['data-events'];
                _menuTab['data-valores_formulario'] =
                  item.props['data-valores_formulario'];
                _menuTab.formid = this.props.props.formid;
                this._events(
                  item.props['data-valores_formulario'],
                  _menuTab.key,
                  cmpTabs,
                );
                if (_.get(item, 'props.data-events')) {
                  _menuTab.tabsimple = true;
                  dispatchEvents(this, _menuTab);
                }
              }

              // that.keyTabs.push(_menuTab.key);
              const tabspane = {
                pantalla: {
                  props: {
                    type: 'container',
                  },
                  children: pane.children,
                },
              };
              const component = (
                <div className="templateTest col-12 col-xl-12">
                  <MountComponent
                    externalPantalla={tabspane}
                    formid={this.props.props.formid}
                  />
                </div>
              );
              cmplistTabs.push(component);
              return cmplistTabs;
            }
            return null;
          });
          totalComponents = {
            listTabs: cmpTabs,
            componentTabs: cmplistTabs,
          };
          return null;
        }
        return null;
      });
    }
    return totalComponents;
  };

  getDirection = () =>
    ['right', 'left'].includes(_.get(this.props, 'props.data-tab_position'))
      ? 'vertical'
      : 'horizontal';

  onChange = (currentTab) => {
    this.channel.publish('simpleTabChange', currentTab); // para refrescar el listado si es necesario
  };

  submitButton = (text) => {
    return (
      <ButtonDesignSubmit
        type="primary"
        onClick={async () => {
          const curTabContent = _.get(this.state, [
            'components',
            'listTabs',
            this.state.currentTab,
          ]);
          const enabled = _.get(curTabContent, 'submit');
          const { onClick } = this.state.submit;
          if (!enabled) {
            this.next({ clicked: true, submited: true });
          } else if (_.isFunction(onClick)) {
            this.channel.publish('disabledButton', true);
            this.channel.publish('stepID', _.get(curTabContent, 'key'));
            await onClick();
          } else {
            this.setState({ clicked: true });
            this.next();
          }
        }}
        texto={text}
      />
    );
  };

  render() {
    const redirectTo = _.get(this.state, 'submit.redirectTo');

    const TabPane = Tabs.TabPane;
    if (this.state.listTabs.length > 0) {
      return redirectTo &&
        this.state.isStep &&
        this.state.submited &&
        this.state.currentTab === this.state.listTabs.length ? (
        <Navigate push to={redirectTo} />
          ) : (
        <div>
          {this.state.isStep ? (
            <>
              <Steps current={this.state.currentTab}>
                {this.state.listTabs.map((cmp, idx) =>
                  cmp.show ? <Step key={cmp.key} title={cmp.tab} /> : null,
                )}
              </Steps>
              <div className="steps-content">
                {this.state.componentTabs[this.state.currentTab]}
              </div>
              <div className="steps-action">
                {this.state.currentTab <
                  this.state.components.componentTabs.length - 1 &&
                  this.submitButton('Siguiente')}
                {this.state.currentTab ===
                  this.state.components.componentTabs.length - 1 &&
                  this.submitButton('Finalizado')}
              </div>
            </>
          ) : (
            <Tabs
              tabPosition={this.state.mode}
              defaultActiveKey={this.state.listTabs[0].key}
              onChange={this.onChange(this.state.currentTab)}
            >
              {this.state.listTabs.map((cmp, index) => {
                if (cmp.show) {
                  return (
                    <TabPane tab={cmp.tab} key={cmp.key}>
                      {this.state.componentTabs[index]}
                    </TabPane>
                  );
                }
                return null;
              })}
            </Tabs>
          )}
        </div>
          );
    }
    return null;
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    form: state.form,
    model: _.get(state.model, ['model', ownProps.props.formid], {}),
  };
};

export default connect(mapStateToProps)(TabSimple);
