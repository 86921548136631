import CryptoJS from 'crypto-js';
import _ from 'lodash';
import { onSetModelValidation } from '../store/actions/ModelActions';

export const strToObject = (str, keyValueSep = '=', tupleSep = ',') => {
  const tuples = str.split(tupleSep);
  const elmts = tuples.map((tuple) => tuple.split(keyValueSep));
  return elmts.reduce(
    (acc, [key, val]) => (key && val ? { ...acc, [key.trim()]: val.trim() } : acc),
    {},
  );
};

/* Añade aliases a las llaves de un objeto */
export const addAliases = (mappingStr, params = {}) => {
  const mapping = strToObject(mappingStr);
  const aliases = _.keys(mapping);
  const values = _.values(_.pick(params, _.values(mapping)));
  const undottedkeys = _.keys(params).map((k) => k.replace(/\(dot\)/g, '.'));
  const undottedParams = _.zipObject(undottedkeys, _.values(params));
  return { ...undottedParams, ..._.zipObject(aliases, values) };
};

export const encrypt = (text) => {
  let ciphertext;
  const id = localStorage.getItem('id');
  if (text && id) {
    try {
      ciphertext = CryptoJS.AES.encrypt(text, id).toString();
      ciphertext = ciphertext + '-x' + id;
    } catch (e) {
      console.error(e);
    }
  }
  return ciphertext;
};

export const decrypt = (text, encoding = CryptoJS.enc.Utf8) => {
  const encryptKey = localStorage.getItem('id');
  let plaintext;
  if (text) {
    try {
      const bytes = CryptoJS.AES.decrypt(text.toString(), encryptKey);
      plaintext = bytes.toString(encoding);
    } catch (e) {
      console.error(e);
    }
  }
  return plaintext;
};

export const isHidden = (rollStr = '') => {
  const currentRoles = (localStorage.getItem('roles') || '').split(',');
  const rolls = rollStr.split(',').filter((e) => e);
  const group = _.groupBy(rolls, (e) => e.charAt(0) === '!');
  // const toHide = _.get(group,"false",[]);
  const toShow = _.get(group, 'false', []);
  // const toShow = _.get(group,"true",[]).map(e => e.slice(1));
  const toHide = _.get(group, 'true', []).map((e) => e.slice(1));
  // const hide = toHide.some(e => currentRoles.includes(e))
  const show = toHide.some((e) => currentRoles.includes(e));
  // const show = _.isEmpty(toShow) || toShow.some(e => currentRoles.includes(e));
  const hide = toShow.some((e) => currentRoles.includes(e));
  return hide || !show;
};

export const isContainer = (rollStr = '') => {
  const currentRoles = (localStorage.getItem('roles') || '').split(',');
  const rolls = rollStr.split(',').filter((e) => e);
  const group = _.groupBy(rolls, (e) => e.charAt(0) === '!');
  // const toHide = _.get(group,"false",[]);
  // Si uno de los roles configurado en el pagebuilder està dentro de la lista
  // de roles del usuario entonces se oculta el contenedor
  const toOneHide = _.get(group, 'false', []);
  // const toShow = _.get(group,"true",[]).map(e => e.slice(1));
  // Si al menos uno de los roles configurados en el pagebuilder con ! no està en la lista de
  // roles del usuario logeado entonces se muestra el contenedor.
  const toOneShow = _.get(group, 'true', []).map((e) => e.slice(1));
  // const hide = toHide.some(e => currentRoles.includes(e))
  // Buscando si existe al menos un rol configurado en la lista de roles del usuario logueado
  const showOrHide = toOneHide.some((e) => currentRoles.includes(e));
  // const show = _.isEmpty(toShow) || toShow.some(e => currentRoles.includes(e));
  // Buscando si existe al menos un rol configurado en la lista de roles del usuario logueado.
  // Sino existe entonces se muestra el contenedor
  const hideOrShow = toOneShow.some((e) => currentRoles.includes(e));
  if (_.size(toOneHide) === 0 && _.size(toOneShow) === 0) return false;
  else if (!showOrHide && _.size(toOneShow) === 0) return false;
  else if (_.size(toOneHide) === 0 && hideOrShow) return false;
  else return true;
  // return showOrHide || hideOrShow;
};

export const hiddenRoles = (that) => {
  if (that.props.roles_hidden) {
    return isContainer(that.props.roles_hidden);
  } else return false;
};

export function SetVisibilityContainer(children, props) {
  children = children.map((child) => {
    if (child === null) return null
    if (child.props.children && Array.isArray(child.props.children)) {
      SetVisibilityContainer(child.props.children, props)
      return child
    } else if (child.props.children && typeof child.props.children === 'object') {
      const { name, formid } = child.props.children.props
      if (props && props.dispatch) props.dispatch(onSetModelValidation(formid, name, false))
      return child
    } else return child
  })
  return children;
}

export const hiddenRolesBtns = (that) => {
  let HRBtns = {};
  HRBtns = that.props.roles_hidden_insert
    ? { ...HRBtns, ...{ insert: isHidden(that.props.roles_hidden_insert) } }
    : { ...HRBtns, ...{ insert: false } };
  HRBtns = that.props.roles_hidden_update
    ? { ...HRBtns, ...{ update: isHidden(that.props.roles_hidden_update) } }
    : { ...HRBtns, ...{ update: false } };
  HRBtns = that.props.roles_hidden_delete
    ? { ...HRBtns, ...{ delete: isHidden(that.props.roles_hidden_delete) } }
    : { ...HRBtns, ...{ delete: false } };
  return HRBtns;
};

export function dateFormatter (date, zone) {
  const options = { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: false };
  options.timeZone = zone
  const dateFormat = new Intl.DateTimeFormat('en-US', options).format(new Date(date))
  return dateFormat
}
