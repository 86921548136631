import {
  GET_FILES,
  GET_FILES_FAILURE,
  GET_FILES_SUCCESS,
  REMOVE_FILE,
  REMOVE_FILE_FAILURE,
  REMOVE_FILE_SUCCESS,
} from '../actions/FileManager';

const initialState = {};

function update(state, action) {
  const _state = { ...state };
  if (action.update && Object.keys(action.update).length) {
    Object.keys(action.update).forEach((key) => {
      _state[key] = { ..._state[key], ...action.update[key] };
    });
  }
  return { ...{}, ..._state };
}

const fileManager = (state = initialState, action) => {
  switch (action.type) {
    case GET_FILES:
    case GET_FILES_SUCCESS:
    case GET_FILES_FAILURE:
    case REMOVE_FILE:
    case REMOVE_FILE_SUCCESS:
    case REMOVE_FILE_FAILURE:
      return update(state, action);
    default:
      return state;
  }
};

export default fileManager;
