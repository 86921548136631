import React, { Component } from 'react';
import postal from 'postal';
import { connect } from 'react-redux';
import _ from 'lodash';
import { requestEventField } from '../../../store/actions/EventFieldActions';
import { onChangeModelActions } from '../../../store/actions/ModelActions';
import { Input } from 'reactstrap';
import { readOnly, validateFields, initXfAndValue, attrChangeModel, validateXfValue } from '../utils';
import { dispatchEvents, hasEventChanged, setModelValidation } from '../events';
import { equalRegEx } from '../../../utils/utils';

class String extends Component {
  static defaultProps = {
    value: '',
    type: 'text',
    allow_multi_insert: 'false', // es true si se pueden especificar valores separados por separador
    separator: ',', // valor del separdor si allow_multi_insert es "true", por defecto se utiliza la coma
  };

  constructor(props) {
    super(props);
    this.state = {
      value: this.props.value || '',
      type: this.props.type,
      clases: this.props.class,
      show: true,
      readOnly: readOnly(this.props),
      messageError: false,
      pbValue: this.props.value || null,
      message: '',
      messageField: '',
      errores: 0,
      isValidated: false
    };
    this.channel = postal.channel();
    this.timer = null;
    this.handleKeyPress = this.handleKeyPress.bind(this)
  }

  componentDidMount() {
    if (this.props.name === '') {
      this.showNotification(
        'El componenente \n' +
          JSON.stringify(this.props) +
          '\n no tiene el atributo name',
      );
    }
    if (this.props.formid) {
      /**
       * Función que limpia el componente. Dejará de existir
       */
      setModelValidation(this);
      this.clearComponent();
      this.channel.subscribe(
        `${this.props.formid}:${this.props.name}:hidden`,
        (data) => {
          this.setState({ readOnly: data.v });
        },
      );

      /**
       * Inicialización de datos del componente.
       */
      initXfAndValue(this);

      /**
       * Ejecución de los eventos adicionales del componente String
       */
      if (_.get(this.props, 'data-events')) dispatchEvents(this);
    }
  }

  // shouldComponentUpdate(nextProps, nextState) {
  //   const currentModel = this.props.model;
  //   const nextModel = nextProps.model;
  //   const {value, onChange} = attrChangeModel(this.props.name,currentModel,nextModel,this.props["data-valores_formulario"]);
  //   if(onChange || nextState.value!==this.state.value) return true;
  //   else return false;
  // }

  showNotification = (message) => {
    this.channel.publish('notification', [
      { tipo: 'error', titulo: 'Error', data: message },
    ]);
  };

  /**
   *
   * @param {*} e
   * @description Evento onchange del componente string. Publica en el modelo global de la pantalla su valor
   */
  handleChange = (e) => {
    const value = e.target.value;

    validateFields(value, this);
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      // if(objectPath.get(this.props,"data-events")) dispatchEvents(this);
      // this.setState({value:value});
      /**
       * Actualización del modelo global desde aqui
       */
      this.props.dispatch(
        onChangeModelActions(this.props.formid, {
          name: this.props.name,
          value: value,
        }),
      );
    }, 500);
    if (this.props['data-fieldaction']) {
      const params = {
        IBLE_VALOR_ACTUAL: value,
        [this.props.name]: value,
      };
      this.props.dispatch(
        requestEventField(this.props['data-fieldaction'], {
          ...this.props.model,
          ...params,
        }),
      );
    }
  };

  clearComponent = () => {
    const _name = _.get(this.props, 'old_name')
      ? this.props.old_name.split('__')[0]
      : this.props.name;
    this.channel.subscribe(
      `${this.props.formid}:${_name}:clearComponent`,
      (data) => {
        /**
         * Limpiando el componente y el campo relacionado en el modelo global
         * de la pantalla
         */
        this.setState({ value: '' });
        this.props.dispatch(
          onChangeModelActions(this.props.formid, {
            name: this.props.name,
            value: '',
          }),
        );
      },
    );
  };

 handleKeyPress = (e) => {
   if (e.which === 13 || e.charCode === 13) {
     this.channel.publish(`${this.props.name}:onBlur`, e.target.value);
     this.channel.publish(`${this.props.formid}:onBlur`, {
       name: this.props.name,
       value: e.target.value,
       type: 'string',
       formid: this.props.formid,
       csv: equalRegEx('true').test(this.props.allow_multi_insert),
       separator: this.props.separator,
     });
   }
 };

 componentDidUpdate(prevProps, prevState) {
   const currentModel = prevProps.model;
   const nextModel = this.props.model;
   if (hasEventChanged(this.props, currentModel, nextModel)) dispatchEvents(this);

   const { value, onChange } = attrChangeModel(
     this,
     this.props.name,
     currentModel,
     nextModel,
   );
   if (onChange && this.state.value !== value) {
     this.setState({ value: value });
   } else validateXfValue(this, nextModel, value);

   if (prevProps.validar !== this.props.validar && this.props.validar === 'validar') { validateFields(this.state.value, this); }
   if (this.props.validate && !this.state.isValidated && this.props.isRepeaterComponent) {
     validateFields(this.state.value, this)
     this.setState({ isValidated: true })
   }
   if (prevState.errores !== this.state.errores && this.state.errores === 1) { this.props.updateParent({ sumar: this.props.name }); } else if (prevState.errores !== this.state.errores && this.state.errores === 0) { this.props.updateParent({ restar: this.props.name }); } else if (
     prevProps.validar !== this.props.validar &&
      this.props.validar === 'validar' &&
      prevState.errores === this.state.errores &&
      this.state.errores === 0
   ) {
     this.props.updateParent({ igual: 1 });
   }

   // if(this.props.modelRequired.includes(this.props.name)){
   //  setModelValidation(this, !this.state.readOnly && this.state.show);
   // }
 }

 /** Si el componente es un XF y se desmonta tambien se quita de los requeridos */
 componentWillUnmount() {
   if (_.get(this.props, 'isExtraFieldComponent') && _.get(this.props, 'required', false)) {
     setModelValidation(this, false)
   }
 }

 render() {
   if (this.state.show) {
     return (
        <>
          <Input
            className={this.state.clases}
           name={this.props.name}
            placeholder={this.props.placeholder || ''}
            type={this.props.type}
            value={this.state.value}
            readOnly={this.state.readOnly}
            onChange={this.handleChange}
            onKeyPress={this.handleKeyPress}
          />
          <span className="alert-form">{this.state.messageField}</span>
        </>
     );
   } else return null;
 }
}

const mapStateToProps = (state, ownProps) => {
  return {
    eventField: state.eventField,
    model: _.get(state.model, ['model', ownProps.formid], {}),
    roles: _.get(state.logged, 'logged.roles') ? state.logged.logged.roles : [],
    form: state.form.form,
    validate: _.get(state.model, 'validation', false)
  };
};
export default connect(mapStateToProps)(String);
