import React from 'react';
import ReactDOM from 'react-dom';
import Routes from './routes';
import { Provider } from 'react-redux';
import configureStore from './store/configureStore';
import { CookiesProvider } from 'react-cookie';
import { isProductionEnv } from './utils/helper';
import config from 'devextreme/core/config';
import 'antd/dist/antd.css';
import './assets/App.scss';

// Config para que el componente DateBox tome la hora local
config({
  rtlEnabled: false,
  forceIsoDateParsing: true,
})

const appName = process.env.REACT_APP_APPNAME || 'Evolutia';
document.title = appName;

const METHODS = ['log', 'debug', 'warn', 'info'];

const DEBUG = isProductionEnv();
if (DEBUG) {
  if (!window.console) window.console = {};

  for (let i = 0; i < METHODS.length; i++) {
    console[METHODS[i]] = function () {};
  }
}
const store = configureStore();

ReactDOM.render(
  <CookiesProvider>
    <Provider store={store}>
      <Routes />
    </Provider>
  </CookiesProvider>,
  document.getElementById('root'),
);
