import {
  GANTT_REQUEST_ERROR,
  GANTT_REQUEST_INIT,
  GANTT_REQUEST_SUCCESS,
} from '../actions/GanttActions';

const initialize = {
  init: false,
  success: false,
  error: false,
  gantt: {},
};
const GanttReducers = (state = initialize, action) => {
  switch (action.type) {
    case GANTT_REQUEST_INIT:
      return {
        ...state,
        ...action.payload,
      };
    case GANTT_REQUEST_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case GANTT_REQUEST_ERROR:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export default GanttReducers;
