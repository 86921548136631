import {
  EXTRA_FIELDS_ERROR,
  EXTRA_FIELDS_INIT,
  EXTRA_FIELDS_SUCCESS,
} from '../actions/ExtraFieldsActions';

const initializeState = {
  init: false,
  success: false,
  error: false,
  eFieldsRoute: {},
  eFieldsRouteFile: {},
};

const eFieldsReducers = (state = initializeState, action) => {
  switch (action.type) {
    case EXTRA_FIELDS_INIT:
      return {
        ...state,
        ...action.payload,
      };
    case EXTRA_FIELDS_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case EXTRA_FIELDS_ERROR:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export default eFieldsReducers;
