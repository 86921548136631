import { CHART_FILTER_DELETED, CHART_SUCCESS, CHART_FILTER_ADDED, LIST_FILTER_ADDED } from '../actions/ChartActions';
import * as R from 'ramda';
import { parserFilters, parserListFilters } from './utils';

const initializeState = {
  selection: [],
  filters: {},
  listFilters: {}
};

const reduceBy = (key, filters = []) =>
  R.map(R.mergeAll, R.values(R.groupBy(R.prop(key), filters)));

const filterOut = (colName, filters) => {
  if (colName && Array.isArray(filters) && !R.isEmpty(filters)) {
    return filters.filter((f) => f.colName !== colName);
  }
  return [];
};

const ChartReducers = (state = initializeState, action) => {
  switch (action.type) {
    case CHART_SUCCESS:
      return {
        ...state,
        selection: reduceBy('colName', [...state.selection, action.payload]),
      }
    case CHART_FILTER_DELETED:
      return {
        ...state,
        selection: filterOut(action.payload, state.selection)
      }
    case CHART_FILTER_ADDED:
      const resultO = {
        [action.payload.formid]: parserFilters(state, action.payload)
      };
      return {
        ...state,
        filters: { ...state.filters, ...resultO },
      };
    case LIST_FILTER_ADDED:
      const result = {
        [action.payload.formid]: parserListFilters(state, action.payload)
      };
      return {
        ...state,
        listFilters: { ...state.listFilters, ...result },
      };
    default:
      return state;
  };
}

export default ChartReducers;
