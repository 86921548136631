/*
// campo del pagebuilder: data-events
0) poner un id de  evento en el campo (o botón)
1) pasar todas las variables al proxy de eventos (incluso el ID de evento)
2) pasar ruta-campo-evento como parámetro a un proxy de eventos
 y que allí se determine que hacer
3) que responda con asignaciones como lo hace divwin
4) que soporte manejo de errores que impidan cambiar el camp
*/
import { v1 as uuid } from 'uuid'
import axios from 'axios';
import postal from 'postal';
import moment from 'moment';
import {
  onChangeModelActions,
  onSetModelValidation,
} from '../../store/actions/ModelActions';
import _ from 'lodash';
import { equalRegEx } from './Checkbox/Checkbox';

const fnNormalize = (fn) => {
  if (Array.isArray(fn)) return fn.join(' ');
  else return fn;
};

const auxDispatchEvents = (that, props) => {
  if (!_.isEmpty(props)) {
    const _idEvent = uuid();
    const _formid = _.get(that, 'props.formid', _.get(that, 'props.props.formid'));
    const model = _.get(props, 'model', {});
    const _data = { id: _idEvent, ...model };
    const _fnNormalize = props['data-events']
      ? fnNormalize(props['data-events'])
      : fnNormalize(props.props['data-events']);
    if (_fnNormalize.indexOf('(') > -1) {
      const valores_formulario = _.get(props, 'data-valores_formulario', null)
        ? _.get(props, 'data-valores_formulario', '')
        : _.get(props, [props, 'data-valores_formulario'], '');
      if (valores_formulario) {
        const _event = _fnNormalize;
        const fnEvent = _event.substr(0, _event.indexOf('(')).trim();
        let valueEvent = _event.substr(_event.indexOf('('), _event.lastIndexOf(')'));
        valueEvent = valueEvent.substr(0, valueEvent.lastIndexOf(')')).replace('(', '').trim()
        getEventFn(fnEvent)(that, _formid, valueEvent, valores_formulario, props);
      } else {
        showNotification(
          'Error',
          `El componente ${JSON.stringify(
            that.props,
          )} no tiene la propiedad data-valores_formulario configurada`,
        );
      }
    } else getEventFn(_fnNormalize)(that, _data, _formid, props);
  }
};

const channel = postal.channel();
axios.defaults.headers.get['Access-Control-Allow-Methods'] =
  'GET,PUT,POST,DELETE,PATCH,OPTIONS';
export const dispatchEvents = (that, newProps) => {
  if (_.get(that.props, 'data-events') || _.get(that.props, 'props.data-events')) {
    auxDispatchEvents(that, that.props);
  } else auxDispatchEvents(that, newProps);
};

const showNotification = (titulo, data) => {
  channel.publish('notification', [{ tipo: 'warning', titulo: titulo, data: data }]);
};

const futureDate = (that) => {
  const model = _.get(that.props, ['model'], {});
  const value = _.get(model, [that.props.name]);
  if (value) {
    const currentDate = moment();
    const futureDate = moment(value);
    if (currentDate > futureDate) {
      that.setState({ value: '' });
      that.props.dispatch(
        onChangeModelActions(that.props.formid, {
          name: that.props.name,
          value: '',
        }),
      );
      showNotification('Error', 'La fecha debe ser futura.');
    }
  }
};

const pastDate = (that) => {
  const model = _.get(that.props, ['model'], {});
  const value = _.get(model, [that.props.name]);
  if (value) {
    const currentDate = moment();
    const futureDate = moment(value);
    if (currentDate < futureDate) {
      that.setState({ value: '' });
      that.props.dispatch(
        onChangeModelActions(that.props.formid, {
          name: that.props.name,
          value: '',
        }),
      );
      showNotification('Error', 'La fecha debe ser pasada.');
    }
  }
};

const currentDate = (that) => {
  const model = _.get(that.props, ['model'], {});
  const value = _.get(model, [that.props.name]);
  const valueModalGlobal = moment(value).local().format('YYYY-MM-DD');
  const currentModelGlobal = moment().local().format('YYYY-MM-DD');
  // Validando que el valor del evento sea diferente de su valor en el modelo global
  // de la pantalla
  if (valueModalGlobal !== currentModelGlobal || value === undefined) {
    that.setState({ value: moment() });
    that.props.dispatch(
      onChangeModelActions(that.props.formid, {
        name: that.props.name,
        value: moment().format('YYYY-MM-DD'),
      }),
    );
  }
};

const hiddenRecursive = (that, value) => {
  if (that) {
    if (that.props.children && Array.isArray(that.props.children)) {
      that.props.children.forEach((item) => {
        hiddenRecursive(item, value);
      });
    } else {
      if (that.props.name !== undefined) {
        channel.publish(`${that.props.formid}:${that.props.name}:hidden`, {
          v: value,
        });
      }
    }
  }
};

const disableWhen = (that, _formid, valueAttr, valores_formulario, props = {}) => {
  const _valForms = valores_formulario.split(',');
  const _valueAttr = valueAttr.split(',');
  const model = _.get(that.props, ['model'], {});
  _valForms.forEach((item) => {
    const keysModel = _.keys(model);
    if (!_.isEmpty(keysModel)) {
      let equalsValue = false;
      let _value = model[item];
      if (!_.isEmpty(_value)) {
        _valueAttr.forEach((pt) => {
          const _pt = pt.replace('*', '');
          if (typeof _value === 'number') _value = _value.toString();
          if (_value === _pt) equalsValue = true;
        });
        if (equalsValue) {
          if (that.props.type === 'container') hiddenRecursive(that, true);
          else that.setState({ readOnly: true });
        } else that.setState({ readOnly: false });
      } else that.setState({ readOnly: equalsValue });
    }
  });
};

const enableWhen = (that, _formid, valueAttr, valores_formulario) => {
  const _valForms = valores_formulario.split(',');
  const _valueAttr = valueAttr.split(',');
  const model = _.get(that.props, ['model'], {});
  _valForms.forEach((item) => {
    const keysModel = _.keys(model);
    if (!_.isEmpty(keysModel)) {
      let equalsValue = false;
      let _value = model[item];
      if (!_.isEmpty(_value)) {
        _valueAttr.forEach((pt) => {
          const _pt = pt.replace('*', '');
          if (typeof _value === 'number') _value = _value.toString();
          // if(_value.indexOf(_pt)>-1) equalsValue = true;
          if (_value === _pt) equalsValue = true;
        });
        if (equalsValue) {
          if (that.props.type === 'container') hiddenRecursive(that, false);
          that.setState({ readOnly: false });
          setModelValidation(that, equalsValue)
        } else {
          that.setState({ readOnly: true })
          setModelValidation(that, equalsValue)
        };
      } else {
        if (that.props.type === 'container') hiddenRecursive(that, true);
        that.setState({ readOnly: true });
        setModelValidation(that, equalsValue)
      }
      setModelValidation(that, equalsValue)
    }
  });
};

const getValue = (model, name) => {
  let value = null;
  _.keys(model).forEach((item) => {
    if (
      typeof model[item] === 'object' &&
      model[item] !== null &&
      item !== '_model'
    ) {
      const xf = model[item];
      if (_.keys(xf).indexOf(name) > -1) {
        value = model[item][name];
      }
    } else if (item === name) value = model[name];
  });
  return value;
};

const showWhen = (that, _formid, valueAttr, valores_formulario, props = {}) => {
  const _valForms = valores_formulario.split(',');
  const _valueAttr = valueAttr.split(',');
  const model = _.get(that.props, ['model'], {});
  _valForms.forEach((item) => {
    let _value = getValue(model, item);
    let equalsValue = false;
    _valueAttr.forEach((pt) => {
      const _pt = pt.replace('*', '');
      if (typeof _value === 'number') _value = _value.toString();
      if (_value === _pt) equalsValue = true;
    });
    const tabsimple = _.get(props, 'tabsimple');
    if (tabsimple) {
      channel.publish(`${item}:${props.key}:showWhen`, { show: equalsValue, formid: props.formid });
      that.setState({ show: equalsValue });
    } else that.setState({ show: equalsValue });
    setModelValidation(that, equalsValue);
  });
};

const hideWhen = (that, _formid, valueAttr, valores_formulario, props = {}) => {
  const _valForms = valores_formulario.split(',');
  const _valueAttr = valueAttr.split(',');
  const model = _.get(that.props, ['model'], {});
  _valForms.forEach((item) => {
    const keysModel = _.keys(model);
    if (!_.isEmpty(keysModel)) {
      let equalsValue = false;
      let _value = model[item];
      if (!_.isEmpty(_value)) {
        _valueAttr.forEach((pt) => {
          const _pt = pt.replace('*', '');
          if (typeof _value === 'number') _value = _value.toString();
          if (_value === _pt) equalsValue = true;
        });
        const tabsimple = _.get(props, 'tabsimple');
        if (tabsimple) {
          channel.publish(`${item}:${props.key}:showWhen`, {
            show: !equalsValue,
            formid: props.formid
          });
          that.setState({ show: false });
        } else if (equalsValue) {
          that.setState({ show: false });
          setModelValidation(that, false);
        } else {
          that.setState({ show: true });
          setModelValidation(that, true);
        }
      } else {
        that.setState({ show: true });
        setModelValidation(that, true);
      }
    }
  });
};

const parseNum = (value, defaultNum = 0, decimals = 2) => {
  const parsed = parseFloat(value);
  const result = !_.isNaN(parsed) ? parsed : 0;
  return result;
};

const getValForms = (name = '', strNames = '') => {
  const valForms = strNames.split(',');
  const [...etc] = name.split('.');
  const idx = etc.pop();
  const num = parseFloat(idx);
  return _.isNaN(num) ? valForms : valForms.map((v) => `${v}.${idx}`);
};

const compute_prod = (that, _formid, valueAttr, valores_formulario, props = {}) => {
  const _valForms = getValForms(that.props.name, valores_formulario);
  const model = _.get(that.props, ['model'], {});
  const result = _valForms.reduce((acc, key) => {
    const number = parseNum(_.get(model, [key], 0));
    return number * acc;
  }, 1);
  const fmtResult = _.isEmpty(valueAttr) ? _.round(result, 2).toFixed(2) : _.round(result, valueAttr).toFixed(valueAttr)
  that.setState({ value: fmtResult });
  that.props.dispatch(
    onChangeModelActions(that.props.formid, {
      name: that.props.name,
      value: fmtResult,
    }),
  );
};

const selectFromModel = (keys, model = {}) =>
  keys.reduce((acc, key) => {
    const selected = _.pickBy(model, (v, k) => isBaseOf(key, k));
    return { ...acc, ...selected };
  }, {});

const isBaseOf = (strBase, strValue) => {
  const splittedValue = strValue.split('.');
  const diff = _.difference(splittedValue, [strBase]);
  const num = diff.length === 1 ? parseFloat(diff[0]) : null;
  return _.isEmpty(diff) || (_.isNumber(num) && !_.isNaN(num));
};

export const hasEventChanged = (props, curModel, nextModel) => {
  const event = _.get(props, 'data-events');
  const valforms = _.get(props, 'data-valores_formulario');
  const keys = valforms ? valforms.split(',') : [];
  if (event) {
    if (!_.isEmpty(keys)) {
      if (event.includes('prod')) {
        const selectedKeys = getValForms(
          props.name,
          props['data-valores_formulario'],
        );
        const cur = _.pick(curModel, selectedKeys);
        const next = _.pick(nextModel, selectedKeys);
        const result = !_.isEqual(cur, next);
        return result;
      } else if (event.includes('sum')) {
        const cur = selectFromModel(keys, curModel);
        const next = selectFromModel(keys, nextModel);
        const result = !_.isEqual(cur, next);
        return result;
      } else {
        const cur = _.pick(curModel, keys);
        const next = _.pick(nextModel, keys);
        return !_.isEqual(cur, next);
      }
    }
    return true;
  }
  return false;
};

const compute_sum = (that, _formid, valueAttr, valores_formulario, props = {}) => {
  const _valForms = valores_formulario.split(',');
  const model = _.get(that.props, ['model'], {});
  const reducedModel = _valForms.reduce((acc, key) => {
    const selected = _.pickBy(model, (v, k) => isBaseOf(key, k));
    return { ...acc, ...selected };
  }, {});
  const result = _.values(reducedModel).reduce(
    (acc, value) => acc + parseNum(value),
    0,
  );
  const fmtResult = _.isEmpty(valueAttr) ? _.round(result, 2).toFixed(2) : _.round(result, valueAttr).toFixed(valueAttr)
  that.setState({ value: fmtResult });
  that.props.dispatch(
    onChangeModelActions(that.props.formid, {
      name: that.props.name,
      value: fmtResult,
    }),
  );
};

const searchLatLong = (
  that,
  _formid,
  valueAttr,
  valores_formulario = [],
  props = {},
) => {
  const data = props.model;
  const _valForms = valores_formulario.split(',');
  let queryString = '';

  const codes = {
    COD_LOCALIDAD: '_D_COD_LOCALIDAD',
    COD_PROVINCIA: '_D_COD_PROVINCIA',
    COD_PAIS: '_D_COD_PAIS',
  };

  let form_filled = true;

  _valForms.forEach((key) => {
    if (data[key] === undefined) {
      form_filled = false;
    }
  });

  if (!form_filled) return;

  _valForms.forEach((key) => {
    if (codes[key] && data[codes[key]]) {
      if (key === 'COD_LOCALIDAD' && data.COD_PROVINCIA === '1') {
        queryString += '';
      } else {
        queryString += data[codes[key]] + ', ';
      }
    } else {
      if (data[key] !== null) queryString += data[key] + ' ';
    }
  });

  const path =
    'https://nominatim.openstreetmap.org/search?q=' +
    queryString.toLowerCase() +
    '&countrycodes=AR&format=json';
  channel.publish(`${_formid}:isSearching`, true)
  axiosRequest(path, form_filled, _formid);
};

export const searchDirectionByLatLon = async (_formid, lat, lon) => {
  const path = `https://nominatim.openstreetmap.org/reverse.php?lat=${lat}&lon=${lon}&zoom=18&format=json`;
  axios
    .get(path)
    .then((response) => {
      channel.publish(`${_formid}:REVERSEDIRECTION`, response.data);
    })
    .catch(() => {
      channel.publish('notification', [
        {
          tipo: 'warning',
          titulo: 'NOMINATIM',
          data: 'Ocurrio un error interno',
        },
      ]);
    });
};

const axiosRequest = (path, request, _formid) => {
  axios
    .get(path)
    .then((response) => {
      channel.publish(`${_formid}:DATAMAPA`, response.data);
      channel.publish(`${_formid}:isSearching`, false)
    })
    .catch(() => {
      request = false;
      channel.publish(`${_formid}:isSearching`, false)
      channel.publish('notification', [
        {
          tipo: 'warning',
          titulo: 'NOMINATIM',
          data: 'Ocurrio un error interno',
        },
      ]);
    });
};

const EVENTS = {
  searchLatLong,
  readOnlyWhen: disableWhen,
  writeOnlyWhen: enableWhen,
  enabledWhen: showWhen,
  disabledWhen: hideWhen,
  futureDate,
  lastDate: pastDate,
  currentDate,
  compute_prod,
  compute_sum,
};

const getEventFn = (eventName) => {
  return _.get(EVENTS, [eventName], () => {
    throw new Error(`Nombre de Evento no válido: ${eventName}`);
  });
};

const isRequired = ({ required }) =>
  equalRegEx(required).test('required') || equalRegEx(required).test('true');

/** Metodo recursivo para setear los campos requeridos en el modelo
 *
 * @param props
 * @param isVisible
 *
 */
const isContainer = (props) =>
  _.get(props, 'type', '').toUpperCase() === 'CONTAINER';

export const setModelValidation = ({ props }, isVisible = true) => {
  if (isContainer(props)) {
    (props.children || [])
      .filter(
        (e) =>
          isContainer(_.get(e, 'props', {})) || isRequired(_.get(e, 'props', {})),
      )
      .map((e) =>
        setModelValidation(
          { ...e, props: { ...e.props, dispatch: props.dispatch } },
          isVisible,
        ),
      );
  } else if (isRequired(props)) {
    props.dispatch(onSetModelValidation(props.formid, props.name, isVisible));
  }
};

export const dispatchEventsFN = (props) => {
  if (_.get(props, 'data-events')) auxDispatchEventsFN(props);
};

const auxDispatchEventsFN = (props) => {
  if (!_.isEmpty(props)) {
    const _idEvent = uuid();
    const _formid = _.get(props, 'formid');
    const model = _.get(props, 'model', {});
    const _data = { id: _idEvent, ...model };
    const _fnNormalize = fnNormalize(props['data-events'])
    if (_fnNormalize.indexOf('(') > -1) {
      const valores_formulario = _.get(props, 'data-valores_formulario', '')
      if (valores_formulario) {
        const _event = _fnNormalize;
        const fnEvent = _event.substr(0, _event.indexOf('(')).trim();
        let valueEvent = _event.substr(_event.indexOf('('), _event.indexOf(')'));
        valueEvent = valueEvent.replace('(', '').replace(')', '').trim();
        getEventFn(fnEvent)(props, _formid, valueEvent, valores_formulario, props);
      } else {
        showNotification(
          'Error',
          `El componente ${JSON.stringify(
            that.props,
          )} no tiene la propiedad data-valores_formulario configurada`,
        );
      }
    } else getEventFn(_fnNormalize)(props, _data, _formid, props);
  }
};
