import React, { Component } from 'react';
import postal from 'postal';
import _ from 'lodash';
import { connect } from 'react-redux';
import { requestEventField } from '../../../store/actions/EventFieldActions';
import { onChangeModelActions } from '../../../store/actions/ModelActions';
import { Input } from 'reactstrap';
import { readOnly, validateFields, initXfAndValue, attrChangeModel } from '../utils';
import { dispatchEvents, hasEventChanged, setModelValidation } from '../events';

class Range extends Component {
  static defaultProps = {
    value: '',
    type: 'range',
  };

  constructor(props) {
    super(props);
    this.state = {
      value: this.props.value || '',
      type: this.props.type,
      clases: this.props.class,
      show: true,
      readOnly: readOnly(this.props),
      messageError: false,
      pbValue: this.props.value || null,
      message: '',
      messageField: '',
      errores: 0,
      isValidated: false
    };
    this.channel = postal.channel();
    this.timer = null;
  }

  componentDidMount() {
    if (this.props.name === '') {
      this.showNotification(
        'El componenente \n' +
          JSON.stringify(this.props) +
          '\n no tiene el atributo name',
      );
    }
    if (this.props.formid) {
      this.clearComponent();
      setModelValidation(this);
      this.channel.subscribe(
        `${this.props.formid}:${this.props.name}:hidden`,
        (data) => {
          this.setState({ readOnly: true });
        },
      );
      /**
       * Inicialización de datos del componente.
       */
      initXfAndValue(this);

      /**
       * Ejecución de los eventos adicionales del componente String
       */
      if (_.get(this.props, 'data-events')) dispatchEvents(this);
    }
  }

  showNotification = (message) => {
    this.channel.publish('notification', [
      { tipo: 'error', titulo: 'Error', data: message },
    ]);
  };

  handleChange = (e) => {
    const value = e.target.value;

    validateFields(value, this);
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.setState({ value: value });
      /**
       * Actualización del modelo global desde aqui
       */
      this.props.dispatch(
        onChangeModelActions(this.props.formid, {
          name: this.props.name,
          value: value,
        }),
      );
    }, 500);
    if (this.props['data-fieldaction']) {
      const params = {
        IBLE_VALOR_ACTUAL: value,
        [this.props.name]: value,
      };
      this.props.dispatch(
        requestEventField(this.props['data-fieldaction'], {
          ...this.props.model,
          ...params,
        }),
      );
    }
  };

  clearComponent = () => {
    const _name = _.get(this.props, 'old_name')
      ? this.props.old_name.split('__')[0]
      : this.props.name;
    this.channel.subscribe(
      `${this.props.formid}:${_name}:clearComponent`,
      (data) => {
        /**
         * Limpiando el componente y el campo relacionado en el modelo global
         * de la pantalla
         */
        this.setState({ value: '' });
        this.props.dispatch(
          onChangeModelActions(this.props.formid, {
            name: this.props.name,
            value: '',
          }),
        );
      },
    );
  };

  handleKeyPress = (e) => {
    if (e.which === 13 || e.keyCode === 13) {
      this.channel.publish(`${this.props.name}:onBlur`, e.target.value);
    }
  };

  componentDidUpdate(prevProps, prevState) {
    const currentModel = prevProps.model;
    const nextModel = this.props.model;
    // const modelChanged = !_.isEqual(currentModel, nextModel);
    // if (modelChanged && _.get(this.props, "data-events")) {
    //   dispatchEvents(this);
    // }
    if (hasEventChanged(this.props, currentModel, nextModel)) dispatchEvents(this);
    const { value, onChange } = attrChangeModel(
      this.props.name,
      currentModel,
      nextModel,
      this.props['data-valores_formulario'],
    );
    if (onChange) {
      this.setState({ value: value });
    }
    if (prevProps.validar !== this.props.validar && this.props.validar === 'validar') { validateFields(this.state.value, this); }
    if (this.props.validate && !this.state.isValidated && this.props.isRepeaterComponent) {
      validateFields(this.state.value, this)
      this.setState({ isValidated: true })
    }
    if (prevState.errores !== this.state.errores && this.state.errores === 1) { this.props.updateParent({ sumar: this.props.name }); } else if (prevState.errores !== this.state.errores && this.state.errores === 0) { this.props.updateParent({ restar: this.props.name }); } else if (
      prevProps.validar !== this.props.validar &&
      this.props.validar === 'validar' &&
      prevState.errores === this.state.errores &&
      this.state.errores === 0
    ) { this.props.updateParent({ igual: 1 }); }

    setModelValidation(this, !this.state.readOnly && this.state.show);
  }

  /** Si el componente es un XF y se desmonta tambien se quita de los requeridos */
  componentWillUnmount() {
    if (_.get(this.props, 'isExtraFieldComponent') && _.get(this.props, 'required', false)) {
      setModelValidation(this, false)
    }
  }

  render() {
    if (this.state.show) {
      return (
        <>
          <Input
            className={this.state.clases}
            type="range"
            value={this.state.value}
            readOnly={this.state.readOnly}
            onChange={this.handleChange}
            onKeyPress={this.handleKeyPress}
          />
          <span className="alert-form">{this.state.messageField}</span>
        </>
      );
    } else return null;
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    eventField: state.eventField,
    model: _.get(state.model, ['model', ownProps.formid], {}),
    roles: _.get(state.logged, 'logged.roles') ? state.logged.logged.roles : [],
    form: state.form.form,
    validate: _.get(state.model, 'validation', false)
  };
};

export default connect(mapStateToProps)(Range);
