import { preferenceTypes } from '../actions/UserPreferencesActions';

const initializeState = {};

const UserReducers = (state = initializeState, action) => {
  switch (action.type) {
    case preferenceTypes.PREF_INIT:
      return { ...state, ...action.payload };

    case preferenceTypes.PREF_SUCCESS:
      const prevData = state.data;
      const newData = action.payload.data;
      return { ...state, ...{ ...prevData, ...newData } };

    case preferenceTypes.PREF_TABLE_SUCCESS:
      return { ...state, preferenceTable: action.payload };

    case preferenceTypes.PREF_ERROR:
      return { ...state, ...action.payload };

    default:
      return state;
  }
};

export default UserReducers;
