import {
  GET_HISTORY,
  GET_HISTORY_FAILURE,
  GET_HISTORY_SUCCESS,
} from '../actions/HistoryFileManagerActions';

const initializeState = {
  init: false,
  success: false,
  error: false,
  history: {},
};

export default function historyFilemanagerReducers(state = initializeState, action) {
  switch (action.type) {
    case GET_HISTORY:
      return {
        ...state,
        ...action.payload,
      };
    case GET_HISTORY_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case GET_HISTORY_FAILURE:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
