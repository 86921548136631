const ENVS = {
  prod: ['PRODUCTION', 'PROD'],
  dev: ['DEVELOPMENT', 'DEV'],
  test: ['TEST'],
};

/**
 *
 * @returns {Boolean}
 */
export const isProductionEnv = () =>
  ENVS.prod.includes(process.env.REACT_APP_ENV.toUpperCase()) || ENVS.test.includes(process.env.REACT_APP_ENV.toUpperCase());

/**
 *
 * @param {Number} n
 */
export const formatMoney = (n) => {
  n.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,');
};

/**
 * receives a string and return a Json
 * @param {String} jsonString
 * @returns {Object}
 */
export const stringToJson = (jsonString) => {
  if (jsonString && typeof jsonString === 'string') {
    const str = jsonString
      .replace(/\n/g, '\\n')
      .replace(/\r/g, '\\r')
      .replace(/\t/g, '\\t')
      .replace(/\f/g, '\\f')
      .replace(/\\/g, '');
    try {
      return JSON.parse(str);
    } catch (e) {
      return { [jsonString]: 'all' };
    }
  }
};

/**
 * receives a Object and check if it is empty or not
 * @param {Object} obj
 * @returns {Boolean}
 */
export const isEmptyObject = (obj) => obj && Object.keys(obj).length === 0;

/**
 *
 * @param {Array | String} data
 * @returns {String}
 */
export const renderToString = (data) =>
  Array.isArray(data) ? data.join(' ') : data;

/**
 *
 * @param {Object} props
 * @returns {Object}
 */
export const renderClass = (props) => {
  const result =
    props && props.class
      ? {
          ...props,
          class: renderToString(props.class),
        }
      : props;
  return result;
};

/**
 * Toggle className in element
 * @param {Object} element
 * @param {String} className
 */
export const toggleClass = (element, className) => {
  if (element.classList.contains(className)) {
    element.classList.remove(className);
  } else {
    element.classList.add(className);
  }
};

/**
 * llamar esta funcion en lugar de console.log
 * @param  {...any} args
 */
export const logger = (...args) => {
  if (!isProductionEnv()) console.log(...args);
};

/**
 * Generar un random key
 * @returns {String}
 */
export const getRandomKey = () => Math.random().toString(36).slice(2);
