import * as cache from 'memory-cache';
import * as hash from 'object-hash';
import { requestAxios } from '../config/requestAxios';
import { getLOVS, txResponse } from '../store/actions/TableActions';

const appCache = new cache.Cache();

/**
 *
 * @param {String} url
 * @param {Object} params
 * @returns {Object}
 */
export const fetchDataCache = async (url, params) => {
  const key = hash({ url, params });
  const result = appCache.get(key);
  if (result) return result;
  try {
    const response = await requestAxios.post(url, params);
    if (url === '/tables') {
      const LOVS = await getLOVS(response.data.columns);
      const result = { ...txResponse(response), LOVS };
      appCache.put(key, result);
      return result;
    } else {
      appCache.put(key, result);
      return response;
    }
  } catch (e) {
    return { error: true, text: e };
  }
};

/**
 *
 * @param {String} str
 * @returns {RegExp}
 */
export const equalRegEx = (str) => new RegExp('^' + str + '$', 'i');

/**
 *
 * @param {String} str
 * @returns {String}
 */
export const sayDot = (str = '') => str.replace(/\./g, '(dot)');

/**
 *
 * @param {String} str
 * @returns {String}
 */
export const restoreDot = (str = '') => str.replace(/\(dot\)/g, '.');

/**
 *
 * @param {String} action
 * @returns {Object}
 */
export const parserFormAndFieldAction = (action) => {
  if (action) {
    const actions = action.split(',');
    switch (actions.length) {
      case 3:
        return {
          COD_ACCION: actions[0],
          ALIAS_CAMPO: actions[1],
          EVENTO: actions[2],
        };
      case 0:
        return { COD_ACCION: action, ALIAS_CAMPO: action, EVENTO: 'CLICK' };
      default:
        break;
    }
  } else {
    return {
      COD_ACCION: 'SUBMIT_AUTOMATICO',
      ALIAS_CAMPO: 'SUBMIT_AUTOMATICO',
      EVENTO: 'CLICK',
    };
  }
};

/**
 *
 * @param {Array} myArr
 * @param {Object} prop
 * @returns {Array}
 */
export const removeDuplicates = (myArr, prop) => {
  return myArr.filter((obj, pos, arr) => {
    return arr.map((mapObj) => mapObj[prop]).indexOf(obj[prop]) === pos;
  });
};

/**
 *
 * @param {Object} attrs
 * @returns {Promise}
 */
export const addScriptPromise = (attrs) => {
  return new Promise((resolve, reject) => {
    if (typeof document !== 'undefined') {
      const s = document.createElement('script');
      if (attrs) {
        Object.keys(attrs).forEach((attr) => {
          s.setAttribute(attr, attrs[attr]);
        });
      }

      s.onload = () => resolve();
      s.onerror = () => reject(new Error());

      document.body.appendChild(s);
    } else {
      resolve();
    }
  });
};

/**
 *
 * @param {Object} attrs
 * @param {Function} _callback
 * @returns {Promise}
 */
export const addLinkToHeadPromise = (attrs, _callback) => {
  return new Promise((resolve, reject) => {
    if (typeof document !== 'undefined') {
      const s = document.createElement('link');
      if (attrs) {
        Object.keys(attrs).forEach((attr) => {
          s.setAttribute(attr, attrs[attr]);
        });
      }

      s.onload = () => resolve();
      s.onerror = () => reject(new Error());

      document.head.appendChild(s);
    } else {
      resolve();
    }
  });
};

/**
 *
 * @param {String} type
 * @returns {Number}
 */
export const componentsWidthName = (type) => {
  const components = ['textarea', 'divwin', 'text', 'number', 'datetime', 'time', 'date', 'richtext'];
  return components.indexOf(type) > -1;
};

/**
 * @param {String} name
 * @return {Array}
 */
export const themes = (name) => {
  const themes = [
    {
      name: 'evolutia',
      label: 'Evolutia',
      colors: {
        primary: {
          base: '#292b64',
          light: '#6878d6',
          dark: '#292b64',
        },
        secondary: {
          base: '#6878d6',
          light: '#FFF6E5',
          dark: '#ef002c',
        },
        accent: {
          base: '#ffffff',
          light: '#ffffff',
          dark: '#dddddd',
        },
        foreground: {
          base: '#191919',
          light: '#333333',
          dark: '#111111',
          darker: '#000000',
        },
        background: {
          base: '#e9e9e9',
          light: '#ffffff',
          dark: '#dddddd',
        },
        chart: { scheme: 'nivo' },
      },
    },
    {
      name: 'blue',
      label: 'Blue',
      colors: {
        primary: {
          base: '#54B9E7',
          light: '#b2e0f6',
          dark: '#1c9cdf',
        },
        secondary: {
          base: '#E35D6A',
          light: '#FFF6E5',
          dark: '#ef002c',
        },
        accent: {
          base: '#ffffff',
          light: '#ffffff',
          dark: '#dddddd',
        },
        foreground: {
          base: '#191919',
          light: '#333333',
          dark: '#111111',
          darker: '#000000',
        },
        background: {
          base: '#e9e9e9',
          light: '#ffffff',
          dark: '#dddddd',
        },
        chart: { scheme: 'nivo' },
      },
    },
    {
      name: 'red',
      label: 'Red',
      colors: {
        primary: {
          base: '#873428',
          light: '#df9e9c',
          dark: '#9c382b',
        },
        secondary: {
          base: '#cc4e3f',
          light: '#df9e9c',
          dark: '#9c382b',
        },
        accent: {
          base: '#ffffff',
          light: '#ffffff',
          dark: '#dddddd',
        },
        foreground: {
          base: '#191919',
          light: '#333333',
          dark: '#111111',
          darker: '#000000',
        },
        background: {
          base: '#e9e9e9',
          light: '#ffffff',
          dark: '#dddddd',
        },
        chart: { scheme: 'nivo' },
      },
    },
    {
      name: 'teal',
      label: 'Teal',
      colors: {
        primary: {
          base: '#009687',
          light: '#52c7b7',
          dark: '#00675a',
        },
        secondary: {
          base: '#009687',
          light: '#52c7b7',
          dark: '#00675a',
        },
        accent: {
          base: '#212121',
          light: '#484848',
          dark: '#000000',
        },
        foreground: {
          base: '#191919',
          light: '#333333',
          dark: '#111111',
          darker: '#000000',
        },
        background: {
          base: '#e9e9e9',
          light: '#ffffff',
          dark: '#dddddd',
        },
        chart: { scheme: 'nivo' },
      },
    },
    {
      name: 'yellowgrey',
      label: 'Yellow Grey',
      colors: {
        primary: {
          base: '#37474f',
          light: '#62727b',
          dark: '#102027',
        },
        secondary: {
          base: '#ffa726',
          light: '#ffd95b',
          dark: '#c77800',
        },
        accent: {
          base: '#4f3f37',
          light: '#7e6d64',
          dark: '#312622',
        },
        foreground: {
          base: '#191919',
          light: '#333333',
          dark: '#111111',
          darker: '#000000',
        },
        background: {
          base: '#e9e9e9',
          light: '#ffffff',
          dark: '#dddddd',
        },
        chart: { scheme: 'nivo' },
      },
    },
    {
      name: 'evolutia-dark',
      label: 'Evolutia',
      colors: {
        primary: {
          base: '#6878d6',
          light: '#6878d6',
          dark: '#6878d6',
        },
        secondary: {
          base: '#A61B9C',
          light: '#D90479',
          dark: '#7224A6',
        },
        accent: {
          base: '#212121',
          light: '#484848',
          dark: '#000000',
        },
        foreground: {
          base: '#191919',
          light: '#333333',
          dark: '#111111',
          darker: '#000000',
        },
        background: {
          base: '#e9e9e9',
          light: '#ffffff',
          dark: '#dddddd',
        },
        chart: { scheme: 'nivo' },
      },
    },
    {
      name: 'blue-dark',
      label: 'Blue Dark',
      colors: {
        primary: {
          base: '#1c617f',
          light: '#b2e0f6',
          dark: '#1c9cdf',
        },
        secondary: {
          base: '#791a22',
          light: '#FFF6E5',
          dark: '#ef002c',
        },
        accent: {
          base: '#ffffff',
          light: '#ffffff',
          dark: '#dddddd',
        },
        foreground: {
          base: '#191919',
          light: '#333333',
          dark: '#111111',
          darker: '#000000',
        },
        background: {
          base: '#e9e9e9',
          light: '#ffffff',
          dark: '#dddddd',
        },
        chart: { scheme: 'nivo' },
      },
    },
    {
      name: 'red-dark',
      label: 'Red Dark',
      colors: {
        primary: {
          base: '#873428',
          light: '#df9e9c',
          dark: '#9c382b',
        },
        secondary: {
          base: '#cc4e3f',
          light: '#df9e9c',
          dark: '#9c382b',
        },
        accent: {
          base: '#ffffff',
          light: '#ffffff',
          dark: '#dddddd',
        },
        foreground: {
          base: '#191919',
          light: '#333333',
          dark: '#111111',
          darker: '#000000',
        },
        background: {
          base: '#e9e9e9',
          light: '#ffffff',
          dark: '#dddddd',
        },
        chart: { scheme: 'nivo' },
      },
    },
    {
      name: 'teal-dark',
      label: 'Teal Dark',
      colors: {
        primary: {
          base: '#009687',
          light: '#52c7b7',
          dark: '#00675a',
        },
        secondary: {
          base: '#009687',
          light: '#52c7b7',
          dark: '#00675a',
        },
        accent: {
          base: '#212121',
          light: '#484848',
          dark: '#000000',
        },
        foreground: {
          base: '#191919',
          light: '#333333',
          dark: '#111111',
          darker: '#000000',
        },
        background: {
          base: '#e9e9e9',
          light: '#ffffff',
          dark: '#dddddd',
        },
        chart: { scheme: 'nivo' },
      },
    },
    {
      name: 'yellowgrey-dark',
      label: 'Yellow Grey',
      colors: {
        primary: {
          base: '#37474f',
          light: '#62727b',
          dark: '#102027',
        },
        secondary: {
          base: '#ffa726',
          light: '#ffd95b',
          dark: '#c77800',
        },
        accent: {
          base: '#212121',
          light: '#484848',
          dark: '#000000',
        },
        foreground: {
          base: '#191919',
          light: '#333333',
          dark: '#111111',
          darker: '#000000',
        },
        background: {
          base: '#e9e9e9',
          light: '#ffffff',
          dark: '#dddddd',
        },
        chart: { scheme: 'nivo' },
      },
    },
  ];

  if (name) {
    return themes.filter((theme) => theme.name === name)[0] || themes[0];
  }

  return themes;
};

/**
 * @param {String} name
 * @return {Array}
 */
export const zones = (name) => {
  const zones = [
    {
      name: 'America/Buenos_Aires',
      label: 'Buenos Aires',
    },
    {
      name: 'America/Fortaleza',
      label: 'Brasil',
    },
    {
      name: 'America/Santiago',
      label: 'Chile',
    },
    {
      name: 'America/Asuncion',
      label: 'Paraguay',

    },
    {
      name: 'America/Montevideo',
      label: 'Uruguay',
    },
    {
      name: 'America/Lima',
      label: 'Peru',
    },
    {
      name: 'America/Bogota',
      label: 'Colombia',
    },
    {
      name: 'America/Guayaquil',
      label: 'Ecuador',
    },
    {
      name: 'America/La_Paz',
      label: 'Bolivia',
    },
  ];

  if (name) {
    return zones.filter((zones) => zones.name === name)[0] || zones[0];
  }

  return zones;
};

/**
 * @param {String} name
 * @return {Array}
 */
export const contacts = (uId) => {
  const cod_user = localStorage.getItem('cod_user')
  const queryString = `Bienvenido al Bot ${cod_user}:${uId}  
  Envíe este mensaje para registrarse.!`
  const contacts = [
    {
      name: 'WhatsApp',
      label: 'WhatsApp',
      value: `https://wa.me/+5491120401270?text=${queryString}`
    },
    {
      name: 'Telegram',
      label: 'Telegram',
      value: `https://t.me/EvolutiaBot?start=${uId}`
    }
  ];

  return contacts;
};

/**
 * Generate a random id
 * @param {Number} length
 * @returns {String}
 */
export const makeid = function (length) {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz_';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

/**
 * Returns Object with all params in the url
 * @returns {Object}
 */
export const getQueryParams = function () {
  let query = window.location.search;
  query = query.split('+').join(' ');
  const params = {};
  let tokens;
  const re = /[?&]?([^=]+)=([^&]*)/g;
  tokens = re.exec(query);
  while (tokens) {
    params[decodeURIComponent(tokens[1])] = decodeURIComponent(tokens[2]);
    tokens = re.exec(query);
  }
  return params;
};

/**
 * The code is used to convert an object into a query string.
 * Example of return foo=hi%20there&bar=100%25
 * @param {Object} obj
 * @returns {String}
 * @see https://stackoverflow.com/questions/1714786/query-string-encoding-of-a-javascript-object
 */
export const objectToQueryString = function (obj) {
  return new URLSearchParams(obj).toString();
};
