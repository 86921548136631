import React, { Component } from 'react';
import { Button } from 'antd';
import postal from 'postal';

export default class ButtonDesignSubmit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disabled: this.props.disabled || false, //
      ghost: this.props.ghost || false, //
      href: this.props.href || false, //
      htmlType: this.props.htmlType,
      icon: this.props.icon, //
      loading: this.props.loading || false, //
      shape: this.props.shape, //
      target: this.props.target || '', //
      type: this.props.type, //
      onClick: this.props.onClick || '', //
      block: this.props.block || false,
      texto: this.props.texto || 'Submit', //
      size: this.props.size, //
    };
    this.channel = postal.channel();
  }

  componentDidMount() {
    this.channel.subscribe('disabledButton', (disabled = false) => {
      this.setState({ disabled });
    });
  }

  render() {
    return (
      <Button
        disabled={this.state.disabled}
        size={this.state.size}
        ghost={this.state.ghost}
        htmlType={this.state.htmlType}
        icon={this.state.icon}
        loading={this.state.loading}
        shape={this.state.shape}
        target={this.state.target}
        type="submit"
        onClick={this.state.onClick}
        block={this.state.block}
      >
        {this.state.texto}
      </Button>
    );
  }
}
