import React, { Component } from 'react';
import MountComponent from '../../MountComponent/MountComponent';
import { connect } from 'react-redux';
import _ from 'lodash';
import { dispatchEvents, setModelValidation } from '../events';

const getLegend = (children = []) => {
  const [legend] = children.filter(({ props }) => _.get(props, 'type') === 'legend');
  return _.get(legend, 'children.0.props.html', '');
};

class FieldSet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: true,
    };
  }

  checkRoles = (_roles) => {
    let response = false;
    const pageHidden = _roles.split(',');
    pageHidden.map((item) => {
      this.props.roles.map((rol) => {
        if (item === rol) response = true;
        else {
          if (item.charAt(0) === '!') {
            const _rol = item.substr(1, item.length - 1);
            if (this.props.roles.indexOf(_rol) <= 0) response = true;
          }
        }
        return null;
      });
      return null;
    });
    return response;
  };

  hiddenRoles = () => {
    if (this.props.roles_hidden) {
      return this.checkRoles(this.props.roles_hidden);
    } else return false;
  };

  componentDidMount() {
    setModelValidation(this);
    if (
      _.get(this.props, 'props.data-events') &&
      _.get(this.props, 'props.data-valores_formulario')
    ) {
      dispatchEvents(this);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const currentModel = prevProps.model;
    const nextModel = this.props.model;
    const modelChanged = !_.isEqual(currentModel, nextModel);
    if (
      modelChanged &&
      _.get(this.props, 'data-events') &&
      _.get(this.props, 'data-valores_formulario')
    ) {
      dispatchEvents(this);
    }
  }

  render() {
    if (this.state.show) {
      const hidden = this.hiddenRoles();
      if (hidden) return null;
      else {
        const formid = _.get(this.props, 'props.formid', null);
        const legend = getLegend(this.props.children);
        const content = {
          pantalla: {
            props: {
              type: 'container',
              formid,
            },
            children: this.props.children,
          },
        };

        const clases =
          this.props && this.props.props.class
            ? this.props.props.class.join(' ')
            : '';

        return (
          <fieldset className={clases || ''}>
            <legend>{legend}</legend>
            <MountComponent externalPantalla={content} formid={formid} />
          </fieldset>
        );
      }
    } else return null;
  }
}

const mapStateToProps = ({ logged }) => {
  if (_.get(logged, 'logged.roles')) return { roles: logged.logged.roles };
  else return { roles: [] };
};
export default connect(mapStateToProps)(FieldSet);
