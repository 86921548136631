import { v1 as uuid } from 'uuid'
import { FORM_ERROR, FORM_SUCCESS, GETROW_SUCCESS } from '../actions/FormActions';

const id = uuid();

const initialize = {
  init: false,
  success: false,
  error: false,
  form: {},
  getrow: {},
  [id]: { submit: false },
};

const FormReducers = (state = initialize, action) => {
  switch (action.type) {
    case FORM_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case FORM_ERROR:
      return {
        ...state,
        ...action.payload,
      };
    case GETROW_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export default FormReducers;
