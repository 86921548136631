import { DIVWIN_ERROR, DIVWIN_INIT, DIVWIN_SUCCESS } from '../actions/DivWinActions';

const initializeState = {
  init: false,
  success: false,
  error: false,
  divwin: {},
};

export default function DivWinReducers(state = initializeState, action) {
  switch (action.type) {
    case DIVWIN_INIT:
      return {
        ...state,
        ...action.payload,
      };
    case DIVWIN_SUCCESS:
      return {
        ...state,
        ...action.payload,
        divwin: { ...state.divwin, ...action.payload.divwin },
      };
    case DIVWIN_ERROR:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
