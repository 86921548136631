import { requestAxios } from '../../config/requestAxios';
import objectPath from 'object-path';

export const DASHBOARD_INIT = 'DASHBOARD_INIT';
export const DASHBOARD_SUCCESS = 'DASHBOARD_SUCCESS';
export const DASHBOARD_ERROR = 'DASHBOARD_ERROR';

const dashboardSuccess = (areas, home) => {
  const tabs = { HOME1: 'tab1', HOME2: 'tab2', HOME3: 'tab3', HOME4: 'tab4' };
  return {
    type: DASHBOARD_SUCCESS,
    payload: {
      init: false,
      success: true,
      error: false,
      [tabs[home]]: areas,
    },
  };
};

const dashboardError = (error) => {
  return {
    type: DASHBOARD_ERROR,
    payload: {
      init: false,
      success: false,
      error: error,
    },
  };
};

const getProps = (numeroArea, data, i) => {
  const array = {};
  if (
    objectPath.get(data.asignaciones, 'area' + numeroArea + '_formatoenvio') !==
    undefined
  ) {
    array['area' + numeroArea + '_titulo'] = objectPath.get(
      data.asignaciones,
      'area' + numeroArea + '_titulo',
    );
    array['area' + numeroArea + '_cod_query'] = objectPath.get(
      data.asignaciones,
      'area' + numeroArea + '_cod_query',
    );
    array['area' + numeroArea + '_formatoenvio'] = objectPath.get(
      data.asignaciones,
      'area' + numeroArea + '_formatoenvio',
    );
    array['area' + numeroArea + '_observaciones'] = objectPath.get(
      data.asignaciones,
      'area' + numeroArea + '_observaciones',
    );
    array.key = i;
    if (
      objectPath.get(data.asignaciones, 'area' + numeroArea + '_formatoenvio') ===
      'DATA'
    ) {
      array['area' + numeroArea + '_data'] = objectPath.get(
        data.asignaciones,
        'area' + numeroArea + '_data',
      );
    } else {
      array['area' + numeroArea + '_data__grilla'] = objectPath.get(
        data.asignaciones,
        'area' + numeroArea + '_data__grilla',
      );
    }
  }
  return array;
};

const getArea = (data) => {
  let arrayAreas = {};
  for (let i = 0; i < 8; i++) {
    const area = getProps(i + 1, data, i);
    if (area) {
      arrayAreas['area' + (i + 1)] = area;
    }
  }
  if (Object.keys(data.asignaciones).indexOf('nombre_dashboard') > -1) {
    arrayAreas = Object.assign(arrayAreas, {
      titulo: data.asignaciones.nombre_dashboard,
    });
  }
  return arrayAreas;
};

export const dashboardActions = (home) => {
  const body = {
    'loged-username': 'YUNIOR',
    COD_PAGINA: home,
    IBLE_RANDOM: 0.2065027320260875,
    IBLE_ID: 'EVENTOS_CAMPOS',
    IBLE_P_COD_ACCION: 'DASHBOARD',
    IBLE_P_CAMPO: 'DASHBOARD',
    IBLE_P_EVENTO: 'init',
    IBLE_P_TIPO_ASOCIACION: 'accion',
  };

  return function (dispatch) {
    requestAxios
      .post('/ible_con/EVENTOS_CAMPOS/DASHBOARD/DASHBOARD', body)
      .then((response) => {
        dispatch(dashboardSuccess(getArea(response.data), home));
      })
      .catch((err) => {
        dispatch(dashboardError(err));
      });
  };
};
