import { TableTypes } from '../actions/TableActions';
import _ from 'lodash';

const initializeState = {
  // init: false,
  // success: false,
  // error: false,
  TABLES: {},
  LOVS: [],
  UpdateError: [],
};

const TableReducers = (state = initializeState, action) => {
  switch (TableTypes[action.type]) {
    case 'ADD_TABLEID':
      // Por cada alias se asociaba un solo valor de FormId, eso no es funcional
      // para cuando abres varios detalles de un listado. Ahora se asocia un arreglo
      // de FormId a un alias.
      const alias = action.payload.alias;
      const oldValue = state.TABLES[alias] || [];
      const tables = _.keys(state.TABLES).includes(alias)
        ? { [alias]: oldValue.concat([action.payload.rowid]) }
        : { [alias]: [action.payload.rowid] };
      return { ...state, TABLES: { ...state.TABLES, ...tables } };
    case 'DEL_TABLEID':
      // Metodo para eliminar el formId del registro que se actualiza desde el detalle
      // de un listado
      const _alias = action.payload.alias;
      const _oldValue = state.TABLES[_alias] || [];
      let result = {};
      const newArrayFormId = _oldValue.filter((e) => e !== action.payload.rowid);
      _.keys(state.TABLES).forEach((item) => {
        if (item !== _alias) {
          result = { ...result, ...{ [_alias]: state.TABLES[_alias] } };
        } else if (item === _alias && _.size(newArrayFormId) > 0) {
          result = { ...result, ...{ [_alias]: newArrayFormId } };
        }
      });
      return { ...state, TABLES: result };
    default:
      return state;
  }
};

export default TableReducers;
