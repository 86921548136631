import { requestAxios } from '../../config/requestAxios';
import { logger } from '../../utils/helper';

export const GANTT_REQUEST_INIT = 'GANTT_REQUEST_INIT';
export const GANTT_REQUEST_SUCCESS = 'GANTT_REQUEST_SUCCESS';
export const GANTT_REQUEST_ERROR = 'GANTT_REQUEST_SUCCESS';

const ganttRequestInit = () => {
  return {
    type: GANTT_REQUEST_INIT,
    payload: {
      init: true,
    },
  };
};

const ganttRequestSuccess = (data) => {
  return {
    type: GANTT_REQUEST_SUCCESS,
    payload: {
      init: false,
      success: true,
      gantt: data,
    },
  };
};

const ganttRequestError = (error) => {
  return {
    type: GANTT_REQUEST_ERROR,
    payload: {
      init: false,
      success: false,
      error: error,
    },
  };
};

export const getGanttRequest = (params) => {
  logger('listado de parametros:', params);
  return function (dispatch) {
    dispatch(ganttRequestInit());
    requestAxios
      .post(
        '/ible_con/EVENTOS_CAMPOS/' +
          params.IBLE_P_COD_ACCION +
          '/' +
          params.IBLE_P_CAMPO,
        params,
      )
      .then((response) => {
        const result = response.data.process_response.result.gantt;
        dispatch(ganttRequestSuccess(result));
      })
      .catch((err) => {
        dispatch(ganttRequestError(err));
      });
  };
};
