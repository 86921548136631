import { requestAxios } from '../../config/requestAxios';
import objectPath from 'object-path';

export const TREE_REQUEST_INIT = 'TREE_REQUEST_INIT';
export const TREE_REQUEST_SUCCESS = 'TREE_REQUEST_SUCCESS';
export const TREE_REQUEST_ERROR = 'TREE_REQUEST_ERROR';

const treeInit = () => {
  return {
    type: 'TREE_REQUEST_INIT',
    payload: {
      init: true,
    },
  };
};

const treeSuccess = (data) => {
  return {
    type: 'TREE_REQUEST_SUCCESS',
    payload: {
      init: false,
      error: false,
      success: true,
      tree: data,
    },
  };
};

const treeError = (error) => {
  return {
    type: 'TREE_REQUEST_SUCCESS',
    payload: {
      init: false,
      error: true,
      success: false,
      tree: error,
    },
  };
};

export const TreeActions = () => {
  return function (dispatch) {
    dispatch(treeInit());
    requestAxios
      .post('TEMASUBTEMA')
      .then((response) => {
        if (objectPath.get(response.data, '0.text') !== undefined) {
          dispatch(treeSuccess(response.data));
        } else {
          dispatch(treeError(response.data));
        }
      })
      .catch((err) => {
        dispatch(treeError(err));
      });
  };
};
