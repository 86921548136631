import {
  REQUEST_ERROR,
  REQUEST_REQUESTING,
  REQUEST_SUCCESS,
} from '../actions/RequestsActions';

const initialState = {};

function mergeRequests(state, action) {
  const _state = { ...state };
  if (action.update && Object.keys(action.update).length) {
    Object.keys(action.update).forEach((key) => {
      _state[key] = { ..._state[key], ...action.update[key] };
    });
  }
  return Object.assign({}, { ..._state });
}

const requests = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_REQUESTING:
    case REQUEST_SUCCESS:
    case REQUEST_ERROR:
      return mergeRequests(state, action);
    default:
      return state;
  }
};

export default requests;
