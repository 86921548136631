import {
  MENU_ERROR,
  MENU_INIT,
  MENU_SUCCESS,
  PAGE_ERROR,
  PAGE_SUCCESS,
} from '../actions/MenuActions';

const initializeState = {
  init: false,
  success: false,
  error: false,
  menu: [],
  page: [],
};

const MenuReducers = (state = initializeState, action) => {
  switch (action.type) {
    case MENU_INIT:
      return {
        ...state,
        ...action.payload,
      };
    case MENU_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case PAGE_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case MENU_ERROR:
      return {
        ...state,
        ...action.payload,
      };
    case PAGE_ERROR:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export default MenuReducers;
