import { FILE_UPLOAD, FILE_UPLOAD_FAILURE, FILE_UPLOAD_PROGRESS, FILE_UPLOAD_SUCCESS } from '../actions/FileUpload';

const initialState = {};

function fileUploadRequest(state, action) {
  const _state = { ...state };
  if (action.update && Object.keys(action.update).length) {
    Object.keys(action.update).forEach((key) => {
      _state[key] = { ..._state[key], ...action.update[key] };
    });
  }
  return Object.assign({}, { ..._state });
}

const fileUpload = (state = initialState, action) => {
  switch (action.type) {
    case FILE_UPLOAD:
    case FILE_UPLOAD_SUCCESS:
    case FILE_UPLOAD_FAILURE:
    case FILE_UPLOAD_PROGRESS:
      return fileUploadRequest(state, action);
    default:
      return state;
  }
};

export default fileUpload;
