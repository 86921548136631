import { routerAxios } from '../../config/requestAxios';
import axios from 'axios';
import { encrypt } from '../../components/utils';
import { setPage } from '../../utils/cache';

export const MENU_INIT = 'MENU_INIT';
export const MENU_SUCCESS = 'MENU_SUCCESS';
export const MENU_ERROR = 'MENU_ERROR';
export const PAGE_SUCCESS = 'PAGE_SUCCESS';
export const PAGE_ERROR = 'PAGE_ERROR';

const REACT_APP_API_URL = process.env.REACT_APP_API_URL || '';

const menuSuccess = (menu, page) => {
  return {
    type: MENU_SUCCESS,
    payload: {
      init: false,
      success: true,
      error: false,
      menu: menu,
      page: page,
    },
  };
};

const menuError = (err) => {
  return {
    type: 'MENU_ERROR',
    payload: {
      init: false,
      success: false,
      error: err,
    },
  };
};

export const menuRequest = () => {
  if (process.env.NODE_ENV === 'development') {
    axios.create({
      baseURL: REACT_APP_API_URL + '/router',
      responseType: 'json',
    });
    axios.defaults.headers.post['Content-Type'] =
      'application/x-www-form-urlencoded';
    axios.defaults.headers.Authorization = localStorage.getItem('enp_id');

    return function (dispatch) {
      axios
        .all([axios.post(REACT_APP_API_URL + '/router/menu', { menu: 'menu' })])
        .then(
          axios.spread(function (menu) {
            if (menu.data.success === false) dispatch(menuSuccess([], []));
            else dispatch(menuSuccess(menu.data, []));
            axios
              .all([axios.post(REACT_APP_API_URL + '/router/menu', { menu: 'page' })])
              .then(
                axios.spread(function (page) {
                  if (page.data.success === false) {
                    setPage([]);
                  } else {
                    setPage(page.data);
                  }
                }),
              )
              .catch((error) => {
                dispatch(menuError(error));
              });
          }),
        )
        .catch((error) => {
          dispatch(menuError(error));
        });
    };
  } else {
    return function (dispatch) {
      const dataPage = { data: encrypt(JSON.stringify({ menu: 'page' })) };
      const dataMenu = { data: encrypt(JSON.stringify({ menu: 'menu' })) };
      routerAxios
        .post('/menu', dataMenu)
        .then((menu) => {
          if (menu.data.success === false) dispatch(menuSuccess([], []));
          else dispatch(menuSuccess(menu.data, []));
          routerAxios
            .post('/menu', dataPage)
            .then((page) => {
              if (page.data.success === false) {
                setPage([]);
              } else {
                setPage(page.data);
              }
            })
            .catch((error) => {
              dispatch(menuError(error));
            });
        })
        .catch((error) => {
          dispatch(menuError(error));
        });
    };
  }
};
