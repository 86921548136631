import { requestAxios } from '../../config/requestAxios';
import { AppDesconnect } from './AppDesconnect';
import objectPath from 'object-path';
import postal from 'postal';
import _ from 'lodash';

export const FORM_SUCCESS = 'FORM_SUCCESS';
export const FORM_ERROR = 'FORM_ERROR';
export const GETROW_SUCCESS = 'GETROW_SUCCESS';
const channel = postal.channel();

const formSuccess = (id, data) => {
  return {
    type: FORM_SUCCESS,
    payload: {
      init: false,
      success: true,
      error: false,
      form: data,
      [id]: { submit: true, ...data },
    },
  };
};

const formError = (id, err) => {
  return {
    type: FORM_ERROR,
    payload: {
      init: false,
      success: false,
      error: err,
      [id]: { submit: true },
    },
  };
};

export const requestSubmit = (formid, data, params, href, stepSubmit) => {
  return function (dispatch) {
    channel.publish('FORM:INIT');
    requestAxios
      .post(
        '/ible_con/EVENTOS_CAMPOS/' +
          params.IBLE_P_COD_ACCION +
          '/' +
          params.IBLE_P_CAMPO,
        data,
      )
      .then((response) => {
        channel.publish('REQUEST:END')
        if (objectPath.get(response.data, 'login_type') !== undefined) {
          dispatch(AppDesconnect(true));
        } else {
          // channel.publish("submitted", true);
          if (response.data.acciones) {
            channel.publish(
              'notification',
              !stepSubmit
                ? response.data.acciones.acciones_alert.map((e) => ({
                  submit: true,
                  ...e,
                }))
                : response.data.acciones.acciones_alert.map((e) => ({
                  ...e,
                  UUID: _.get(data, 'TAB_KEY'),
                })),
            );
          }
          dispatch(formSuccess(formid, response.data));
        }
      })
      .catch((error) => {
        dispatch(formError(formid, error));
        const msg = [
          {
            tipo: 'error',
            titulo: 'Error',
            data: 'Ocurrio un error interno en el servidor',
          },
        ];
        channel.publish(
          'notification',
          !stepSubmit
            ? msg
            : msg.map((e) => ({ ...e, UUID: _.get(data, 'TAB_KEY') })),
        );
      });
  };
};
