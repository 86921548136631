import { requestAxios } from '../../config/requestAxios';
import objectPath from 'object-path';
import postal from 'postal';

export const RECOVER_PASS_INIT = 'RECOVER_PASS_INIT';
export const RECOVER_PASS_SUCCESS = 'RECOVER_PASS_SUCCESS';
export const RECOVER_PASS_ERROR = 'RECOVER_PASS_ERROR';
const channel = postal.channel();

const recoverPassInit = () => {
  return {
    type: RECOVER_PASS_INIT,
    payload: {
      init: true,
    },
  };
};

const recoverPassSuccess = (data) => {
  return {
    type: RECOVER_PASS_SUCCESS,
    payload: {
      init: false,
      success: true,
      error: false,
      recoverpass: data,
    },
  };
};

const recoverPassError = (err) => {
  return {
    type: RECOVER_PASS_ERROR,
    payload: {
      init: false,
      success: false,
      error: err,
    },
  };
};

export const recoverPassword = (data) => {
  return function (dispatch) {
    dispatch(recoverPassInit());
    channel.publish('FORM:INIT');
    requestAxios
      .post('/users/recupera_password_post', data)
      .then((response) => {
        channel.publish('REQUEST:END');
        if (objectPath.get(response.data.type) === 'success') {
          const result = response.data;
          dispatch(recoverPassSuccess(result.data));
        } else dispatch(recoverPassError(response.data.message));
      })
      .catch((err) => {
        dispatch(recoverPassError(err));
      });
  };
};

export const changePassword = (data) => {
  return function (dispatch) {
    dispatch(recoverPassInit());
    channel.publish('FORM:INIT');
    requestAxios
      .post('/users/cambio_pass_post', data)
      .then((response) => {
        channel.publish('REQUEST:END');
        if (objectPath.get(response.data.type) === 'success') {
          const result = response.data;
          dispatch(recoverPassSuccess(result.data));
        } else dispatch(recoverPassError(response.data.message));
      })
      .catch((err) => {
        dispatch(recoverPassError(err));
      });
  };
};
