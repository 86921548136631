import { AppDesconnect } from './AppDesconnect';
import { fetchDataCache } from '../../utils/cache';
import _ from 'lodash';
export const DIVWIN_INIT = 'DIVWIN_INIT';
export const DIVWIN_SUCCESS = 'DIVWIN_SUCCESS';
export const DIVWIN_ERROR = 'DIVWIN_ERROR';

const divwinSuccess = (alias, formid, repeater, data) => {
  const key =
    repeater !== null && repeater !== undefined
      ? formid + '_' + alias + '_' + repeater
      : formid + '_' + alias;
  return {
    type: DIVWIN_SUCCESS,
    payload: {
      init: false,
      success: true,
      error: false,
      divwin: {
        [key]: data,
      },
    },
  };
};

const divwinError = (error) => {
  return {
    type: DIVWIN_ERROR,
    payload: {
      init: false,
      success: false,
      error: error,
    },
  };
};

export const divwinActions = (alias, formid, repeater, jsonParams) => {
  return async function (dispatch) {
    const response = await fetchDataCache('/lists/' + alias, jsonParams);
    if (!_.get(response, 'error')) {
      if (_.get(response, 'data.login_type', false)) {
        dispatch(AppDesconnect(true));
      } else if (_.get(response, 'data.process_response.app_data', false)) {
        const _data = response.data.process_response.app_data;
        dispatch(divwinSuccess(alias, formid, repeater, _data));
      } else {
        try {
          dispatch(divwinError(formid, response.errorMsg || 'Ocurrio un error inesperado'));
        } catch (error) {
          console.error(error);
        }
      }
    } else {
      try {
        dispatch(divwinError(formid, response.errorMsg || 'Ocurrio un error inesperado'));
      } catch (error) {
        console.error(error);
      }
    }
  };
};
