import { combineReducers } from 'redux';
import { routerReducer as routing } from 'react-router-redux';
import DivWinReducers from './DivWinReducers';
import PortadaCamposReducers from './PortadaCamposReducers';
import UserReducers from './UserReducers';
import MenuReducers from './MenuReducers';
import TreeReducers from './TreeReducers';
import TableReducers from './TableReducers';
import UserPreferencesReducers from './UserPreferencesReducers';
import LogoutReducers from './LogoutReducers';
import FormReducers from './FormReducers';
import GetRowReducers from './GetRowReducers';
import EventFieldReducers from './EventFieldReducers';
import DashboardReducers from './DashboardReducers';
import LoggedReducers from './LoggedReducers';
import GanttReducers from './GanttReducers';
import ModelReducers from './ModelReducers';
import FileUpload from './FileUpload';
import FileManager from './FileManager';
import historyFilemanagerReducers from './HistoryFileManagerReducers';
import FilemanagerCreateFolder from './FilemanagerCreateFolder';
import ExtraFieldsReducers from './ExtraFieldsReducers';
import requests from './RequestsReducers';
import recoverPasswordReducers from './RecoverPasswordReducers';
import ChartReducers from './ChartReducers';
import AppDesconnectReducers from './AppDesconnectReducers';

const rootReducer = combineReducers({
  routing,
  divwin: DivWinReducers,
  portadacampos: PortadaCamposReducers,
  user: UserReducers,
  menu: MenuReducers,
  tree: TreeReducers,
  table: TableReducers,
  userPreferences: UserPreferencesReducers,
  logout: LogoutReducers,
  logged: LoggedReducers,
  form: FormReducers,
  getrow: GetRowReducers,
  eventField: EventFieldReducers,
  dashboard: DashboardReducers,
  gantt: GanttReducers,
  model: ModelReducers,
  fileManager: FileManager,
  fileUpload: FileUpload,
  requests,
  history: historyFilemanagerReducers,
  folder: FilemanagerCreateFolder,
  eFields: ExtraFieldsReducers,
  chart: ChartReducers,
  recoverpass: recoverPasswordReducers,
  AppDesconnec: AppDesconnectReducers,
});

export default rootReducer;
