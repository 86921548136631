import React, { Component } from 'react';
import objectPath from 'object-path';

export default class Label extends Component {
  constructor(props) {
    super(props);
    this.state = {
      text: this.props.children[0].props.html,
    };
  }

  render() {
    if (objectPath.get(this.props, 'props.show') === false) return null;
    else {
      return (
        <label>
          {this.state.text}{' '}
          {objectPath.get(this.props.props, 'required') === 'required' && '*'}
        </label>
      );
    }
  }
}
