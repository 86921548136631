import { requestAxios, updateAxios } from '../../config/requestAxios';
import objectPath from 'object-path';
import { getQueryParams } from '../../utils/utils';

export const LOGGED_REQUEST_INIT = 'LOGGED_REQUEST_INIT';
export const LOGGED_REQUEST_SUCCESS = 'LOGGED_REQUEST_SUCCESS';
export const LOGGED_REQUEST_ERROR = 'LOGGED_REQUEST_ERROR';

const userRequestInit = () => {
  return {
    type: LOGGED_REQUEST_INIT,
    payload: {
      init: true,
      error: false,
    },
  };
};

const userRequestSuccess = (data) => {
  return {
    type: LOGGED_REQUEST_SUCCESS,
    payload: {
      init: false,
      success: true,
      error: false,
      logged: data || {},
    },
  };
};

const userRequestError = ({ error, logged }) => {
  return {
    type: LOGGED_REQUEST_ERROR,
    payload: {
      init: false,
      success: false,
      error: error,
      logged: logged,
    },
  };
};

export const getUserLogged = () => {
  return function (dispatch) {
    dispatch(userRequestInit());
    updateAxios(function () {
      requestAxios
        .get('/ible/logged')
        .then((response) => {
          const result = response.data;
          if (result.login_type === 'error') {
            dispatch(userRequestError({ error: true, logged: result }));
            if (result.data._id) localStorage.setItem('id', result.data._id);
            return;
          }
          if (result.data._id) localStorage.setItem('id', result.data._id);
          objectPath.get(result.data.user, 'id') !== undefined
            ? localStorage.setItem('U_ID', result.data.user.id)
            : localStorage.setItem('U_ID', '');
          const queryParams = getQueryParams();
          if (queryParams.redirecto) {
            result.data.user.redirecto = queryParams.redirecto;
          }
          dispatch(userRequestSuccess(result.data.user));
        })
        .catch((err) => {
          dispatch(userRequestError({ error: true, logged: err }));
        });
    });
  };
};
